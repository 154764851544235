.bd-step {
    &__body {
        padding: 20px 40px;
        @include flexbox;
        flex-wrap: wrap;
    }

    &__container {
        min-height: calc(100vh - 172px);
        background-color: $white;
    }

    &__content {
        flex: 1;
        min-height: calc(100vh - 172px);
    }

    &__btn {
        height: 100px;
        @include flexbox;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba($light-gray, 0.2);

        .bd-btn {
            margin: 0 50px;
        }
    }

    &__nav {
        @include flexbox;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 25px 10%;
        color: $light-gray;

        i {
            font-size: 24px;
            position: relative;
            @include flexbox;
        }

        span {
            margin-top: 5px;
        }

        div {
            width: 25%;
            position: relative;
            @include flexbox;
            flex-direction: column;
            text-align: center;
            align-items: center;



            &:before {
                content: "";
                position: absolute;
                right: -50%;
                top: 11px;
                width: 100%;
                height: 1px;
                background-color: $light-gray;
            }

            &:last-child {
                &:before {
                    display: none;
                }
            }

            &.active {
                color: $client-primary-color;

                i {
                    &:after {
                        content: $bludtickmark;
                        font-family: $icomoon-font-family;
                        font-size: 8px;
                        color: $white;
                        position: absolute;
                        left: 7px;
                        top: 8px;

                    }
                }

                &:before {
                    background-color: $client-primary-color;

                }

            }
        }
    }

}




.bd-step__body--lft {
    width: 50%;
    padding-right: 100px;

}

.bd-step__body--rgt {
    width: 50%;
    padding-left: 5%;
    position: relative;

    .bd-add__labs--btn {
        margin-top: 15px;
        text-align: right;
        color: $client-primary-color;
        cursor: pointer;
        font-family: $medium_font-weight;

        i {
            font-size: 10px;
            margin-right: 5px;

        }
    }

    .bd-add__labs {
        &--userpass {
            @include flexbox;

            .bd-form__row:first-child {
                margin-right: 20px;
                width: 50%;
            }
        }

    }

    .bd-add-cancel__labs--btn {
        @include flexbox;
        justify-content: center;
        align-items: center;

        .bd-btn {
            min-width: 120px;
            margin: 0 5px;
        }
    }

}

.bd-step__body--bot {
    width: 100%;
    // height: 300px;
    margin-top: 50px;
}

.bd-inventory__tbl {

    .bd-tbl {
        text-align: center;
    }

    .bd-tbl__body {
        .bd-tbl__cell {
            &:first-child {
                color: $client-primary-color;
                font-weight: $medium_font-weight;
            }
        }
    }

    input {
        width: 70px;
        border: 0;
        text-align: center;
    }
}

.bd-rgt__lab-equipments {
    margin-top: 150px;
    position: relative;
    min-height: 100vh;

    &:before {
        content: "";
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: rgba($light-gray, 0.2)
    }
}

ul.bd-list__decimal {
    font-size: 15px;
    list-style-type: decimal;

}

ul.bd-list__lower-alpha {
    font-size: 15px;
    list-style-type: lower-alpha;

}

ul.bd-list {

    &__primary {
        margin-left: 15px;

        li {
            padding-bottom: 10px;
            position: relative;
        }
    }

    &__secondary {
        padding-left: 30px;
        padding-top: 10px;
    }

}

.bd-list {
    &__content {
        @include flexbox;

        &--txt {
            width: 100%;
            flex-shrink: 0;
        }
    }

    &__radio {
        white-space: nowrap;

        &.bd-solution-used {
            input[type="text"] {
                width: 240px;
                border: 0;
                border-bottom: 1px solid $light-gray;
                padding: 5px;


            }

        }

        &.bd-list__rgt--row {
            input[type="text"] {
                width: 130px;
                padding: 5px 10px;
            }
        }

        div {
            margin-right: 5px;
            display: inline-block;
        }

        label {
            margin-left: 5px;
        }

        .bd-radio {
            top: 3px;
        }
    }

    &__rgt {
        position: absolute;
        top: 0;
        right: -130%;
        @include flexbox;

        &--row {
            @include flexbox;

            input[type="text"] {
                width: 100px;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-bottom: 1px solid $light-gray;
            }

            div {
                margin-right: 10px;
            }

            label {
                margin-right: 10px;
            }
        }
    }
}

.bd-step__head {
    font-weight: $medium_font-weight;
    font-size: 15px;
    margin-bottom: 20px;
}

// Associated Labs
.bd-associated__labs {
    .bd-tbl__cell {

        &:first-child,
        &:last-child {
            width: 40px;
            text-align: center;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            width: 30%;

            span {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 120px;
            }

        }

        .bluddelete {
            cursor: pointer;
        }
    }
}

// Add Labs
.bd-add__labs {
    border-radius: 8px;
    border: 1px solid $client-primary-color;
    min-height: 200px;
    margin-top: 30px;
    padding: 24px;
}

.bd-loh {
    width: 80%;
    margin: 0 0 0 auto;

    .bd-step__head {
        font-size: 21px;
        color: $client-primary-color;
        text-align: center;
    }

    .bd-tbl {
        border: 0;
        box-shadow: none;

        &__cell {
            border: 0;

            &:first-child {
                width: 70px;

            }
        }
    }

    &__add {
        @include flexbox;

        input {
            padding: 5px;
            border: 0;
            border-bottom: 1px solid $light-gray;
            width: 100%;
        }
    }

    &__date {
        width: 80px;
        margin-right: 80px;

        &--desc {
            flex: 1;
        }
    }
}

.bd-timing-tbl {
    width: calc(100% + 100px);
    @include flexbox;
    min-height: 200px;
    margin-top: 15px;
    flex-direction: column;

    &__row,
    &__day {
        @include flexbox;
    }

    &__row {
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        label {
            margin-right: 10px;
        }

        input[type="text"] {
            border: 0;
            border-bottom: 1px solid $light-gray;
            padding: 5px 0;
        }
    }

    &__day {
        justify-content: flex-end;
        align-items: center;

        div {
            width: 30px;
            height: 30px;
            border-radius: 2px;
            margin: 0 7px;
            color: $white;
            border: 1px solid #3B4559;
            line-height: 25px;
            text-align: center;
            color: $black;
            font-weight: $medium_font-weight;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background-color: #E14942;
                border-color: #E14942;
                color: $white;

            }
        }
    }

    &__add {
        text-align: right;
        font-size: 15px;
        cursor: pointer;
        margin: 10px 0 50px 0;

        i {
            font-size: 10px;
            margin-right: 5px;
        }
    }
}