@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');
.bd-selectcomponents__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.si-create .si-plyr__wrap:nth-child(1) {
  animation-delay: 0.1s;
}

.si-create .si-plyr__wrap:nth-child(2) {
  animation-delay: 0.2s;
}

.si-create .si-plyr__wrap:nth-child(3) {
  animation-delay: 0.3s;
}

.si-create .si-plyr__wrap:nth-child(4) {
  animation-delay: 0.4s;
}

.si-create .si-plyr__wrap:nth-child(5) {
  animation-delay: 0.5s;
}

.si-plyrSel__tabs ul li:nth-child(1) {
  animation-delay: 0.1s;
}

.si-plyrSel__tabs ul li:nth-child(2) {
  animation-delay: 0.2s;
}

.si-plyrSel__tabs ul li:nth-child(3) {
  animation-delay: 0.3s;
}

.si-plyrSel__tabs ul li:nth-child(4) {
  animation-delay: 0.4s;
}

.si-menu ul li:nth-child(1) {
  animation-delay: 0.1s;
}

.si-menu ul li:nth-child(2) {
  animation-delay: 0.2s;
}

.si-menu ul li:nth-child(3) {
  animation-delay: 0.3s;
}

.si-menu ul li:nth-child(4) {
  animation-delay: 0.4s;
}

.si-menu ul li:nth-child(5) {
  animation-delay: 0.5s;
}

.si-menu ul li:nth-child(6) {
  animation-delay: 0.6s;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

body {
  background: #e5e5e5;
  min-height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f2f0f0;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #af0c0c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #af0c0c;
  border-radius: 5px;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.bd-root {
  transform: scale(1);
  -webkit-transform: scale(1);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.bd-signin .bd-form__row input::placeholder {
  color: #222326;
  opacity: 1;
}

.bd-signin .bd-form__row input:-ms-input-placeholder {
  color: #222326;
}

.bd-signin .bd-form__row input::-ms-input-placeholder {
  color: #222326;
}

.bd-form__row input::placeholder {
  color: #979797;
  opacity: 1;
}

.bd-form__row input:-ms-input-placeholder {
  color: #979797;
}

.bd-form__row input::-ms-input-placeholder {
  color: #979797;
}

.bd-container-fluid {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.bd-main {
  min-height: 100%;
  font-size: 15px;
  line-height: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #222326;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  background-color: #e5e5e5;
  position: relative;
  z-index: 1;
}
.bd-main::before,
.bd-main::after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.bd-main * {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.bd-main__container {
  flex: 1;
  position: relative;
}
.bd-main .bd-article {
  min-height: 600px;
  background-color: #fff;
}
.bd-main .bd-menu {
  width: 200px;
  padding: 32px;
  min-height: 100vh;
  background-color: #fff;
}
.bd-main ul,
.bd-main ol {
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.bd-main em {
  font-style: normal;
}
.bd-main a {
  color: #af0c0c;
  text-decoration: none;
}
.bd-main b,
.bd-main strong {
  font-weight: 700;
}
.bd-main :focus {
  outline: none;
}
.bd-main *,
.bd-main *::before,
.bd-main *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: 0;
  -webkit-tap-highlight-color: transparent;
}

.bd-tbl {
  display: table;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  border-collapse: collapse;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.bd-tbl__head,
.bd-tbl__body,
.bd-tbl__foot {
  display: table-header-group;
}
.bd-tbl__head {
  background-color: #af0c0c;
  color: #fff;
}
.bd-tbl__row {
  display: table-row;
}
.bd-tbl__row.bd-10plus .bd-tbl__cell--filterpp {
  top: auto;
  bottom: 40px;
}
.bd-tbl__cell {
  display: table-cell;
  vertical-align: middle;
  border: 1px solid rgba(151, 151, 151, 0.2);
  padding: 15px 10px;
}
.bd-tbl__cell3 {
  display: table-cell;
  vertical-align: middle;
  border: 1px solid rgba(151, 151, 151, 0.2);
  padding: 10px;
}
.bd-tbl__cell_people {
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  padding: 5px 10px;
}
.bd-tbl__cell_people_span {
  display: table-cell;
  vertical-align: middle;
  /* border-bottom: 1px solid rgba(151, 151, 151, 0.2); */
  padding: 5px 10px;
}
.bd-tbl__cell input[type='text'] {
  width: 100%;
  text-align: center;
  border: 0;
}
.bd-tbl__cell--center {
  text-align: center;
}
.bd-tbl__cell--filter {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}
.bd-tbl__cell--filter .bd-red__color {
  color: #fc2526;
}
.bd-tbl__cell--filter .bd-green__color {
  color: #008948;
}
.bd-tbl__cell--filter i {
  font-size: 10px;
  margin-left: 10px;
  font-weight: 600;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}
.bd-tbl__cell--filter.active i {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.bd-tbl__cell--filter.active .bd-tbl__cell--filterpp {
  visibility: visible;
}
.bd-tbl__cell--filterpp {
  visibility: hidden;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 40px;
  right: 0;
  width: 110px;
  text-align: left;
  z-index: 1;
}
.bd-tbl__cell--filterpp div {
  padding-bottom: 10px;
  position: relative;
}
.bd-tbl__cell--filterpp div:last-child {
  padding-bottom: 0;
}
.bd-tbl__theme1 {
  box-shadow: none;
  border-radius: 0;
}
.bd-tbl__theme1 .bd-tbl__head {
  background-color: transparent;
  color: #222326;
}
.bd-tbl__theme1 .bd-tbl__cell {
  border-left: 0;
  border-right: 0;
}
.bd-tbl__theme1 .bd-tbl__head {
  font-weight: 600;
}
.bd-tbl__theme1 .bd-tbl__head .bd-tbl__row .bd-tbl__cell {
  border-top: 0;
  color: #3b4559;
}
.bd-tbl__theme1 .bd-tbl__body .bd-tbl__row:last-child .bd-tbl__cell {
  border-bottom: 0;
}
.bd-tbl__theme1 .bd-tbl__body .bd-tbl__row:first-child .bd-tbl__cell {
  border-top: 0;
}

.bd-tbl__body .bd-nodata {
  width: 100%;
  min-height: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}
.bd-tbl__body .bd-nodata:before {
  content: '';
  width: 60px;
  height: 60px;
  background: url(../images/no-data-found.svg) no-repeat center center;
  background-size: cover;
}

.bd-tbl__body .bd-tbl__cell--filter:last-child .bd-filterpp__sub {
  right: auto;
  left: calc(-100% - 42px);
}
.bd-tbl__body .bd-tbl__cell--filterpp {
  padding: 0;
}
.bd-tbl__body .bd-tbl__cell--filterpp div {
  padding: 5px 5px;
  margin: 0 5px;
  border-bottom: 1px solid #f2f0f0;
}
.bd-tbl__body .bd-filterpp__sub {
  visibility: hidden;
  padding: 0 !important;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: calc(-100% - 39px);
  top: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 132px;
}
.bd-tbl__body .bd-filterpp__list.active:after {
  content: '';
  font-family: 'BLUD';
  position: absolute;
  right: 0px;
  top: 5px;
  font-size: 10px;
  margin: 0px 2px;
}
.bd-tbl__body .bd-filterpp__list.active:hover .bd-filterpp__sub {
  visibility: visible;
}

.bd-btn {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  min-width: 200px;
  padding: 6px 20px;
  user-select: none;
  text-align: center;
  border-radius: 4px;
  color: #fff;
}
.bd-btn__wrap {
  justify-content: space-around;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
}
@media screen and (min-width: 992px) {
  .bd-btn {
    font-size: 14px;
    padding: 8px 30px;
  }
}
.bd-btn--primary {
  background-color: #af0c0c;
}
.bd-btn--deactive {
  background: #787679;
  border-color: #787679;
  color: #fff;
  cursor: not-allowed;
  opacity: 0.5;
}
.bd-btn--outline {
  border: 1px solid #af0c0c;
  color: #af0c0c;
}
.bd-btn.bd-disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.bd-btn--share {
  background-color: #2a6bf4;
}
.bd-btn--issue {
  background-color: #0d9823;
}
.bd-btn--accept {
  border-color: #0d9823;
  color: #0d9823;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
}
.bd-btn--accept.active {
  background-color: #0d9823;
  color: #fff;
  box-shadow: none;
}

.bd-checkbox,
.bd-radio {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
}
.bd-checkbox b,
.bd-radio b {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 100%;
  border: 1px solid #c4c4c4;
  line-height: 13px;
  text-align: center;
  font-size: 7px;
}
.bd-checkbox input,
.bd-radio input {
  width: 15px;
  height: 15px;
  opacity: 0;
  z-index: 2;
  position: relative;
  cursor: pointer;
}
.bd-checkbox input:checked + b,
.bd-radio input:checked + b {
  border-color: #af0c0c;
  color: #af0c0c;
}
.bd-checkbox input:checked + b:before,
.bd-radio input:checked + b:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  left: 2px;
  top: 2px;
  background-color: #af0c0c;
  border-radius: 2px;
}

.bd-radio b:before {
  border-radius: 100% !important;
}

.bd-checkbox b {
  border-radius: 4px;
}

.bd-file-upload {
  position: relative;
  overflow: hidden;
  width: 150px;
}
.bd-file-upload button {
  min-width: 100%;
  padding: 8px;
}
.bd-file-upload input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.bd-download-temp {
  color: #af0c0c;
  font-weight: 600;
  cursor: pointer;
}
.bd-download-temp i {
  margin-right: 5px;
}

.bd-list__lower-disc {
  list-style-type: disc;
}

.bd-dorpdown {
  position: relative;
  color: #979797;
}
.bd-dorpdown__selected {
  position: relative;
  padding: 3px 20px 0 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  cursor: pointer;
  min-width: 52px;
}
.bd-dorpdown__selected i {
  position: absolute;
  right: 7px;
  top: 50%;
  font-size: 10px;
  color: #979797;
  -webkit-transform: translateY(-50%) rotate(90deg);
  -moz-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.bd-dorpdown__option {
  background-color: #fff;
  border-radius: 3px;
  padding: 3px 5px;
  position: absolute;
  top: 29px;
  left: 0;
  width: 100%;
  cursor: pointer;
  visibility: hidden;
  z-index: 9;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border: 0;
}
.bd-dorpdown__list {
  border-bottom: 1px solid rgba(34, 35, 38, 0.1);
  padding: 5px 0;
}
.bd-dorpdown__list:last-child {
  border: 0;
}
.bd-dorpdown.active .bd-dorpdown__option {
  visibility: visible;
}

.bd-calender {
  position: relative;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}
.bd-calender input[type='text'] {
  border-bottom: 0 !important;
  padding-right: 30px !important;
}
.bd-calender:after {
  content: '';
  font-family: 'BLUD';
  position: absolute;
  right: 0;
  top: 30px;
  font-size: 15px;
  cursor: pointer;
}

.db-bg__green {
  background-color: #0d9823;
}

.db-bg__red {
  background-color: #af0c0c;
}

.db-bg__blue {
  background-color: #2a6bf4;
}

@font-face {
  font-family: 'BLUD';
  src: url('../fonts/BLUD.eot?1');
  src: url('../fonts/BLUD.eot?1') format('embedded-opentype'),
    url('../fonts/BLUD.ttf?1') format('truetype'),
    url('../fonts/BLUD.woff?1') format('woff'),
    url('../fonts/BLUD.svg?1#BLUD') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^='blud'],
[class*=' blud'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BLUD' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bludissued:before {
  content: '';
}

.bluddonation:before {
  content: '';
}

.bludstart-fill:before {
  content: '';
}

.bludstar:before {
  content: '';
}

.bluddots__menu:before {
  content: '';
}

.bludrefresh:before {
  content: '';
}

.bludquestionmark:before {
  content: '';
}

.bludpin:before {
  content: '';
}

.bludfilter:before {
  content: '';
}

.bludprint:before {
  content: '';
}

.bludcalender:before {
  content: '';
}

.bludsort-arrow:before {
  content: '';
}

.bludpower-off:before {
  content: '';
}

.bluduser:before {
  content: '';
}

.bluddelete:before {
  content: '';
}

.bludlabresults:before {
  content: '';
}

.bludedit:before {
  content: '';
}

.bludclose:before {
  content: '';
}

.bludcamps:before {
  content: '';
}

.bludageing:before {
  content: '';
}

.bludtransfer:before {
  content: '';
}

.bluddonar:before {
  content: '';
}

.bludaccept:before {
  content: '';
}

.bludshare:before {
  content: '';
}

.bludplus:before {
  content: '';
}

.bluddownload:before {
  content: '';
}

.bludbig-arrow:before {
  content: '';
}

.bludnotification:before {
  content: '';
}

.bludsearch:before {
  content: '';
}

.bludtickmark:before {
  content: '';
}

.bludsetting:before {
  content: '';
}

.bludchat:before {
  content: '';
}

.bludblood-bag:before {
  content: '';
}

.bludpeople:before {
  content: '';
}

.bluddashboard:before {
  content: '';
}

.bludclose-eye:before {
  content: '';
}

.bluddot:before {
  content: '';
}

.bludeye:before {
  content: '';
}
.blud-institution:before {
  content: '';
}

ul.bd-primary__menu {
  font-size: 15px;
}
ul.bd-primary__menu li {
  padding-bottom: 30px;
  padding-left: 25px;
  position: relative;
  cursor: pointer;
}
ul.bd-primary__menu li.active {
  color: #af0c0c;
  cursor: default;
}
ul.bd-primary__menu li i {
  position: absolute;
  left: 0;
  top: 2px;
}
ul.bd-secondary__menu {
  font-size: 15px;
  padding-top: 20px;
}
ul.bd-secondary__menu li {
  padding-bottom: 30px;
  padding-left: 25px;
  cursor: pointer;
}
ul.bd-secondary__menu li:last-child {
  padding-bottom: 0;
}
ul.bd-secondary__menu li i {
  font-size: 14px;
  top: 4px;
  font-size: 13px;
}
ul.bd-secondary__menu li i.bludageing {
  font-size: 22px;
  top: 1px;
}

.bd-menu {
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.24);
  position: relative;
  z-index: 3;
}
.bd-menu .bd-logo {
  width: 100%;
  height: 40px;
}

.bd-adddonar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  color: #af0c0c;
  border-radius: 100%;
  border: 1px solid #af0c0c;
  margin: 30px auto 30px 20px;
  cursor: pointer;
}
.bd-adddonar i {
  font-size: 20px;
}
.bd-adddonar span {
  font-size: 10px;
  margin-top: -2px;
}

.bd-hader {
  /* height: 72px; */
  height: 8vh;
  background-color: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.24);
  /* z-index: 1; */
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
}
.bd-hader .bd-add {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: #fff;
  background-color: #af0c0c;
  text-align: center;
  line-height: 33px;
  cursor: pointer;
}
.bd-hader .bd-notification {
  padding: 10px;
  margin: 0 10px;
}
.bd-hader .bd-notification i {
  font-size: 21px;
  color: #979797;
}
.bd-hader .bd-search {
  padding: 10px;
  margin: 0 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bd-hader .bd-search.active input {
  width: 300px;
  visibility: visible;
  opacity: 1;
}
.bd-hader .bd-search input {
  visibility: hidden;
  width: 0;
  height: 25px;
  padding: 5px 40px 5px 5px;
  margin-right: -30px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.bd-hader .bd-acimg {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100%;
  margin: 0 10px;
  position: relative;
}
.bd-hader .bd-acimg__ac {
  z-index: 1;
  position: absolute;
  width: 150px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  top: 35px;
  right: 0;
  padding: 16px;
  visibility: hidden;
}
.bd-hader .bd-acimg__ac div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bd-hader .bd-acimg__ac--profile {
  padding-bottom: 16px;
}
.bd-hader .bd-acimg__ac i {
  margin-right: 5px;
}
.bd-hader .bd-acimg.bd-active .bd-acimg__ac {
  visibility: visible;
}

.bd-step__body {
  padding: 20px 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.bd-step__container {
  min-height: calc(100vh - 172px);
  background-color: #fff;
}
.bd-step__content {
  flex: 1;
  min-height: calc(100vh - 172px);
}
.bd-step__btn {
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(151, 151, 151, 0.2);
}
.bd-step__btn .bd-btn {
  margin: 0 50px;
}
.bd-step__nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 10%;
  color: #979797;
}
.bd-step__nav i {
  font-size: 24px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-step__nav span {
  margin-top: 5px;
}
.bd-step__nav div {
  width: 25%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.bd-step__nav div:before {
  content: '';
  position: absolute;
  right: -50%;
  top: 11px;
  width: 100%;
  height: 1px;
  background-color: #979797;
}
.bd-step__nav div:last-child:before {
  display: none;
}
.bd-step__nav div.active {
  color: #af0c0c;
}
.bd-step__nav div.active i:after {
  content: '';
  font-family: 'BLUD';
  font-size: 8px;
  color: #fff;
  position: absolute;
  left: 7px;
  top: 8px;
}
.bd-step__nav div.active:before {
  background-color: #af0c0c;
}

.bd-step__body--lft {
  width: 50%;
  padding-right: 100px;
}

.bd-step__body--rgt {
  width: 50%;
  padding-left: 5%;
  position: relative;
}
.bd-step__body--rgt .bd-add__labs--btn {
  margin-top: 15px;
  text-align: right;
  color: #af0c0c;
  cursor: pointer;
  font-family: 600;
}
.bd-step__body--rgt .bd-add__labs--btn i {
  font-size: 10px;
  margin-right: 5px;
}
.bd-step__body--rgt .bd-add__labs--userpass {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-step__body--rgt .bd-add__labs--userpass .bd-form__row:first-child {
  margin-right: 20px;
  width: 50%;
}
.bd-step__body--rgt .bd-add-cancel__labs--btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bd-step__body--rgt .bd-add-cancel__labs--btn .bd-btn {
  min-width: 120px;
  margin: 0 5px;
}

.bd-step__body--bot {
  width: 100%;
  margin-top: 50px;
}

.bd-inventory__tbl .bd-tbl {
  text-align: center;
}
.bd-inventory__tbl .bd-tbl__body .bd-tbl__cell:first-child {
  color: #af0c0c;
  font-weight: 600;
}
.bd-inventory__tbl input {
  width: 70px;
  border: 0;
  text-align: center;
}

.bd-rgt__lab-equipments {
  margin-top: 150px;
  position: relative;
  min-height: 100vh;
}
.bd-rgt__lab-equipments:before {
  content: '';
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(151, 151, 151, 0.2);
}

ul.bd-list__decimal {
  font-size: 15px;
  list-style-type: decimal;
}

ul.bd-list__lower-alpha {
  font-size: 15px;
  list-style-type: lower-alpha;
}

ul.bd-list__primary {
  margin-left: 15px;
}
ul.bd-list__primary li {
  padding-bottom: 10px;
  position: relative;
}
ul.bd-list__secondary {
  padding-left: 30px;
  padding-top: 10px;
}

.bd-list__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-list__content--txt {
  width: 100%;
  flex-shrink: 0;
}
.bd-list__radio {
  white-space: nowrap;
}
.bd-list__radio.bd-solution-used input[type='text'] {
  width: 240px;
  border: 0;
  border-bottom: 1px solid #979797;
  padding: 5px;
}
.bd-list__radio.bd-list__rgt--row input[type='text'] {
  width: 130px;
  padding: 5px 10px;
}
.bd-list__radio div {
  margin-right: 5px;
  display: inline-block;
}
.bd-list__radio label {
  margin-left: 5px;
}
.bd-list__radio .bd-radio {
  top: 3px;
}
.bd-list__rgt {
  position: absolute;
  top: 0;
  right: -130%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-list__rgt--row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-list__rgt--row input[type='text'] {
  width: 100px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #979797;
}
.bd-list__rgt--row div {
  margin-right: 10px;
}
.bd-list__rgt--row label {
  margin-right: 10px;
}

.bd-step__head {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 20px;
}

.bd-associated__labs .bd-tbl__cell:first-child,
.bd-associated__labs .bd-tbl__cell:last-child {
  width: 40px;
  text-align: center;
}
.bd-associated__labs .bd-tbl__cell:nth-child(2),
.bd-associated__labs .bd-tbl__cell:nth-child(3),
.bd-associated__labs .bd-tbl__cell:nth-child(4) {
  width: 30%;
}
.bd-associated__labs .bd-tbl__cell:nth-child(2) span,
.bd-associated__labs .bd-tbl__cell:nth-child(3) span,
.bd-associated__labs .bd-tbl__cell:nth-child(4) span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
}
.bd-associated__labs .bd-tbl__cell .bluddelete {
  cursor: pointer;
}

.bd-add__labs {
  border-radius: 8px;
  border: 1px solid #af0c0c;
  min-height: 200px;
  margin-top: 30px;
  padding: 24px;
}

.bd-loh {
  width: 80%;
  margin: 0 0 0 auto;
}
.bd-loh .bd-step__head {
  font-size: 21px;
  color: #af0c0c;
  text-align: center;
}
.bd-loh .bd-tbl {
  border: 0;
  box-shadow: none;
}
.bd-loh .bd-tbl__cell {
  border: 0;
}
.bd-loh .bd-tbl__cell:first-child {
  width: 70px;
}
.bd-loh__add {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-loh__add input {
  padding: 5px;
  border: 0;
  border-bottom: 1px solid #979797;
  width: 100%;
}
.bd-loh__date {
  width: 80px;
  margin-right: 80px;
}
.bd-loh__date--desc {
  flex: 1;
}

.bd-timing-tbl {
  width: calc(100% + 100px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 200px;
  margin-top: 15px;
  flex-direction: column;
}
.bd-timing-tbl__row,
.bd-timing-tbl__day {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-timing-tbl__row {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.bd-timing-tbl__row label {
  margin-right: 10px;
}
.bd-timing-tbl__row input[type='text'] {
  border: 0;
  border-bottom: 1px solid #979797;
  padding: 5px 0;
}
.bd-timing-tbl__day {
  justify-content: flex-end;
  align-items: center;
}
.bd-timing-tbl__day div {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  margin: 0 7px;
  color: #fff;
  border: 1px solid #3b4559;
  line-height: 25px;
  text-align: center;
  color: #222326;
  font-weight: 600;
  cursor: pointer;
}
.bd-timing-tbl__day div:last-child {
  margin-right: 0;
}
.bd-timing-tbl__day div.active {
  background-color: #e14942;
  border-color: #e14942;
  color: #fff;
}
.bd-timing-tbl__add {
  text-align: right;
  font-size: 15px;
  cursor: pointer;
  margin: 10px 0 50px 0;
}
.bd-timing-tbl__add i {
  font-size: 10px;
  margin-right: 5px;
}

.bd-registration {
  max-width: 423px;
  background-color: #fff;
  padding: 40px;
  min-height: 493px;
  border-radius: 8px;
  margin: 50px auto 50px auto;
}
.bd-registration__header {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}
.bd-registration__content {
  text-align: center;
  margin: 20px 0;
}
.bd-registration__content p {
  margin-bottom: 15px;
  color: #3b4559;
}

.bd-logo {
  width: 190px;
  height: 56px;
  margin: auto auto 30px auto;
  background-color: #f2f0f0;
  background-image: url(../images/default-logo.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.bd-reg-insititution ul {
  font-size: 17px;
  padding-left: 20px;
  padding-top: 15px;
}
.bd-reg-insititution ul li {
  padding-bottom: 10px;
  position: relative;
  padding-right: 30px;
}
.bd-reg-insititution ul li .bd-checkbox {
  position: absolute;
  right: 0;
  top: 5px;
}

.bd-setpassword {
  max-width: 1086px;
  padding: 80px;
}
.bd-setpassword .bd-registration__header {
  margin-bottom: 30px;
}

.bd-setpass {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-setpass__lft {
  flex: 1;
  padding-right: 120px;
  position: relative;
}
.bd-setpass__lft:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: rgba(151, 151, 151, 0.2);
}
.bd-setpass__rgt {
  flex: 1;
  padding-left: 120px;
}
.bd-setpass__rgt ul {
  font-size: 15px;
}
.bd-setpass__rgt ul li {
  padding-bottom: 20px;
}

.bd-forgot-pass .bd-forgot-pass__cont {
  font-size: 15px;
  color: #979797;
  padding-left: 30px;
}
.bd-forgot-pass .bd-forgot-pass__cont li {
  padding-bottom: 20px;
  position: relative;
}
.bd-forgot-pass .bd-forgot-pass__cont li:before {
  content: '';
  font-family: 'BLUD';
  font-size: 5px;
  position: absolute;
  left: -15px;
  top: 0;
  color: #222326;
}
.bd-forgot-pass .bd-invalid__email input[type='text'] {
  border-bottom-color: #af0c0c;
}

.bd-otp p {
  margin-bottom: 20px;
  color: #979797;
}
.bd-otp__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-otp__box input {
  width: 16.66%;
  margin: 0 5px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}
.bd-otp__box input:first-child {
  margin-left: 0;
}
.bd-otp__box input:lastt-child {
  margin-right: 0;
}
.bd-otp__box input[type='text'].active {
  border-bottom-color: #af0c0c;
}

.bd-form .bd-registration__header {
  padding-top: 15px;
}
.bd-form__row {
  margin-bottom: 20px;
  position: relative;
}
.bd-form__row--resendotp {
  text-align: center;
}
.bd-form__row--pass input {
  padding-right: 20px !important;
}
.bd-form__row--pass .bd-eyeIcon {
  position: absolute;
  right: 0;
  bottom: 11px;
  cursor: pointer;
}
.bd-form__row--pass .bd-eyeIcon:before {
  content: '';
  font-family: 'BLUD';
}
.bd-form__row--pass .bd-eyeIcon.bd-show:before {
  content: '';
}
.bd-form__row--forgotpass {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.bd-form__row--forgotpass .bd-incorrect__msg {
  flex: 1;
}
.bd-form__row--setpass {
  text-align: center;
  margin: 40px 0;
}
.bd-form__row--setpass .bd-btn {
  min-width: 240px;
}
.bd-form__row label {
  color: #979797;
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.bd-form__row--signup {
  text-align: center;
  font-size: 15px;
}
.bd-form__row--signin {
  margin: 40px 0;
  text-align: center;
}
.bd-form__row--signin .bd-btn {
  min-width: 280px;
}
.bd-form__row--register {
  text-align: center;
}
.bd-form__row--register .bd-btn {
  min-width: 280px;
  margin-top: 20px;
}
.bd-form__row input[type='password'] {
  flex: 1;
}
.bd-form__row input[type='text'],
.bd-form__row input[type='password'] {
  width: 100%;
  padding: 10px 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  font-size: 15px;
}
.bd-form__row .bd-incorrect__msg {
  font-size: 15px;
  color: #af0c0c;
}
.bd-form__row .bd-forgot-pass {
  text-align: right;
}
.bd-form__row .bd-forgot-pass a {
  font-size: 13px;
  color: #3b4559;
  text-decoration: none;
}

.bd-tab {
  background-color: rgba(151, 151, 151, 0.1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  color: #979797;
  border-radius: 8px;
}
.bd-tab__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.bd-tab__header > span:first-child {
  font-size: 16px;
  font-weight: 600;
}
.bd-tab__header .bd-viewall {
  cursor: pointer;
  color: #af0c0c;
}
.bd-tab__main {
  box-shadow: 0px 0px 4px rgba(34, 35, 38, 0.24);
  border-radius: 8px;
}
.bd-tab div {
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 30px;
  white-space: nowrap;
}
.bd-tab div.active {
  background-color: #fff;
  color: #af0c0c;
  cursor: default;
}

.bd-inventory {
  padding: 20px 40px;
}
.bd-inventory__header {
  font-weight: 700;
  font-size: 24px;
  /* margin-bottom: 20px; */
}
.bd-inventory__step {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
}
.bd-inventory__step--remove {
  color: #af0c0c;
  cursor: pointer;
}
.bd-inventory__step--primary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.bd-inventory__step--secondary {
  color: #979797;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.bd-inventory__step--row {
  position: relative;
  margin-right: 60px;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 24px;
}
.bd-inventory__step--row:after {
  content: '';
  font-family: 'BLUD';
  position: absolute;
  top: 0;
  right: -60px;
  width: 60px;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #979797;
}
.bd-inventory__step--row:last-child {
  margin-right: 0;
}
.bd-inventory__step--row:last-child:after {
  display: none;
}
.bd-inventory__step--guide {
  color: #979797;
}
.bd-inventory__step--guide ul {
  font-size: 15px;
  list-style-type: disc;
  padding-left: 50px;
}
.bd-inventory__step--guide ul li {
  margin-bottom: 10px;
}
.bd-inventory__step--guide ul li .bd-file-upload {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 10px;
  width: 130px;
  padding: 8px;
}

.bd-excel__tbl {
  text-align: center;
}
.bd-excel__tbl--container {
  height: 80vh;
  overflow: auto;
}
.bd-excel__tbl .bd-emailid,
.bd-excel__tbl .bd-address {
  max-width: 150px;
}
.bd-excel__tbl .bd-emailid span,
.bd-excel__tbl .bd-address span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.bd-excel__tbl .bd-tbl {
  box-shadow: none;
  border-radius: 0;
  border: 0;
}
.bd-excel__tbl .bd-tbl__head {
  background-color: transparent;
  color: #979797;
}
.bd-excel__tbl .bd-tbl__cell:first-child {
  border-left: 0;
}
.bd-excel__tbl .bd-tbl__cell:last-child {
  border-left: 0;
}
.bd-excel__tbl .bd-tbl__head .bd-tbl__cell {
  border-top: 0;
}
.bd-excel__tbl .bd-tbl__body .bd-lab-result {
  color: #0d9823;
}
.bd-excel__tbl .bd-tbl__body .bd-tbl__row:last-child .bd-tbl__cell {
  border-bottom: 0;
}

.bd-tbl__addinvent2 {
  text-align: center;
}
.bd-tbl__addinvent2 .bd-blood__group--col {
  margin: auto;
}

.bd-dashboard {
  padding: 20px 30px;
}
.bd-dashboard__lft {
  flex: 1;
  padding: 0 5px;
  margin: 0 -5px;
  overflow: hidden;
}
.bd-dashboard__lft .bd-tab {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-dashboard__lft .bd-dashboard__main {
  font-size: 24px;
}
.bd-dashboard__lft .bd-dashboard__date {
  font-size: 18px;
  margin-top: 10px;
}
.bd-dashboard__lft .bd-tab__content {
  padding: 16px;
}
.bd-dashboard__rgt {
  flex: 0 0 445px;
  padding-left: 40px;
}
.bd-dashboard__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.bd-dashboard__header {
  font-weight: 700;
  margin-bottom: 20px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.bd-dashboard__header .bd-tab {
  margin-left: 15px;
  border-radius: 4px;
}
.bd-dashboard__header .bd-addlab__result {
  color: #af0c0c;
  margin-left: 15px;
  cursor: pointer;
}
.bd-dashboard__header .bd-addlab__result i {
  margin-right: 5px;
  font-size: 10px;
}
.bd-dashboard__header span {
  display: block;
}

.bd-request {
  border-bottom: 1px solid #af0c0c;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.bd-request__tbl.bd-donation__tbl .bd-tbl__cell:nth-child(3) {
  text-align: left;
}
.bd-request__tbl .bd-btn {
  min-width: 96px;
}
.bd-request__tbl .bd-btn i {
  font-size: 10px;
  margin-right: 5px;
}
.bd-request__tbl .bd-tbl__cell:nth-child(1) {
  max-width: 150px;
}
.bd-request__tbl .bd-tbl__cell:nth-child(2),
.bd-request__tbl .bd-tbl__cell:nth-child(3) {
  text-align: center;
}
.bd-request__tbl .bd-tbl__cell:nth-child(6) span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.bd-request__tbl .bd-tbl__body .bd-tbl__cell:nth-child(2) {
  font-weight: 700;
}
.bd-request__tbl .bd-tbl__row.bd-selected {
  color: #af0c0c;
}
.bd-request__wrap {
  position: relative;
  padding-left: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.bd-request:last-child {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.bd-request__share {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  justify-content: space-around;
}
.bd-request__share .bd-btn i {
  margin-right: 5px;
  font-size: 10px;
}

.bd-blood__group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 56.22px;
  position: absolute;
  left: 0;
  top: 0;
}
.bd-blood__group--main {
  padding-left: 16px;
}
.bd-blood__group--main .bd-attachment__date label {
  color: #979797;
  font-weight: 700;
}
.bd-blood__group span {
  width: 64px;
  height: 64px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  text-transform: uppercase;
  border-radius: 100%;
  font-weight: 700;
  color: #fff;
}
.bd-blood__group span b {
  font-size: 22px;
  margin-left: 2px;
}
.bd-blood__requested {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.bd-blood__requested .bd-blood__type {
  font-size: 15px;
  font-weight: 600;
}
.bd-blood__requested .bd-blood__stage {
  color: #af0c0c;
  font-weight: 600;
}
.bd-blood__requested .bd-blood__units {
  display: block;
  padding-top: 5px;
}
.bd-blood__requested .bd-blood__num {
  display: block;
  margin: 5px 0;
}
.bd-blood__requested .bd-blood__status {
  display: block;
}
.bd-blood__requested .bd-blood__status label {
  color: #979797;
  margin-right: 5px;
}
.bd-blood__requested .bd-blood__graph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 5px;
}
.bd-blood__requested .bd-blood__graph div {
  flex: 0 0 30px;
  height: 5px;
  border-radius: 10px;
  background-color: #ffc700;
  margin: 0 2px;
}
.bd-blood__requested .bd-blood__graph div.bd-good {
  background-color: #008948;
}
.bd-blood__details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.bd-blood__details .bd-hosp-address span {
  display: block;
}
.bd-blood__details .bd-hosp-address span:first-child {
  color: #979797;
  margin-bottom: 10px;
}
.bd-blood__details .bd-hosp-distance {
  color: #979797;
}
.bd-blood__normal .bd-blood__group span {
  background-color: #af0c0c;
}
.bd-blood__normal .bd-blood__units b {
  color: #af0c0c;
}
.bd-blood__plasma .bd-blood__group span {
  background-color: #ffc700;
}
.bd-blood__plasma .bd-blood__units b {
  color: #ffc700;
}

.bd-tbl__donation {
  overflow: auto;
  width: calc(100% + 32px);
  height: calc(100vh - 150px);
  margin: -16px;
}
.bd-tbl__donation .bd-tbl {
  table-layout: fixed;
  border-radius: 0;
  box-shadow: none;
}
.bd-tbl__donation .bd-tbl__cell {
  width: 45px;
  text-align: center;
}
.bd-tbl__donation .bd-tbl__cell:first-child {
  width: 250px;
  text-align: left;
}

.bd-labrequest__tbl {
  text-align: center;
}

.bd-tbl__cell.bd-red__color {
  color: #fc2526;
  font-weight: 600;
}
.bd-tbl__cell.bd-green__color {
  color: #008948;
  font-weight: 600;
}

.bd-samp__recived {
  min-height: 400px;
}
.bd-samp__recived .bd-btn {
  min-width: 96px;
  padding: 8px 10px;
}
.bd-samp__recived .bd-tbl__cell:nth-last-child(2) {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bd-header__search .bd-hader {
  margin-left: auto;
  margin-right: 0;
  box-shadow: none;
}
.bd-header__search .bd-filter {
  margin-left: 15px;
  position: relative;
}
.bd-header__search .bludfilter {
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.bd-filter.active .bd-filter__pp {
  visibility: visible;
}
.bd-filter__pp {
  visibility: hidden;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  position: absolute;
  top: 30px;
  right: 0;
}
.bd-filter__pp .bd-filter__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  margin-bottom: 5px;
  padding-bottom: 0;
}
.bd-filter__pp .bd-filter__row label {
  width: 80px;
  color: #3b4559;
  font-weight: 600;
  margin-right: 10px;
}
.bd-filter__pp .bd-filter__row input[type='text'] {
  width: 120px;
  border: 0;
  border-bottom: 1px solid #f2f0f0;
  padding-bottom: 5px;
}
.bd-filter__radio {
  justify-content: flex-start;
  padding-top: 15px;
}
.bd-filter__radio .bd-radio {
  margin-right: 5px;
  top: 2px;
}
.bd-filter__radio div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-people {
  min-height: 400px;
}

.bd-inventory__scr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-inventory__scr .bd-blood__group span {
  width: 40px;
  height: 40px;
  font-size: 15px;
}
.bd-inventory__scr .bd-blood__group span b {
  font-size: 15px;
}

.bd-blood__group--list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-top: 45px;
}
.bd-blood__group--col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 56.22px;
  margin-bottom: 18px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 1px solid #af0c0c;
  font-size: 15px;
  color: #af0c0c;
  text-transform: uppercase;
  cursor: pointer;
}
.bd-blood__group--col.active {
  background-color: #af0c0c;
  color: #fff;
  cursor: default;
}
.bd-blood__group--col span {
  font-size: 17px;
  font-weight: 700;
}
.bd-blood__group--col span b {
  font-size: 15px;
}

.bd-transfer__btn {
  width: 96px;
}
.bd-transfer__btn .bd-tbl__cell--filterpp {
  top: 10px;
  right: 130px;
}

.bd-pagination {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.bd-pagination label {
  margin-right: 20px;
}
.bd-pagination__nav {
  font-size: 13px;
}
.bd-pagination__nav span {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  text-align: center;
  line-height: 18px;
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #979797;
  cursor: pointer;
}
.bd-pagination__nav span.active {
  border-color: #af0c0c;
}
.bd-pagination__dot {
  font-size: 8px;
  border: 0 !important;
  margin: 0 2px !important;
  color: #979797;
  width: auto !important;
}

.bd-scroll__horz {
  width: 100%;
  height: calc(100vh - 200px);
  overflow: auto;
}
.bd-scroll__horizontal {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
}

.bd-mod {
  margin-bottom: 20px;
  box-shadow: 0px 0px 4px rgba(34, 35, 38, 0.24);
  border-radius: 4px;
}
.bd-mod__head {
  padding: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ebeff2;
  justify-content: space-between;
}
.bd-mod__head > span:first-child {
  font-size: 16px;
  font-weight: 700;
}
.bd-mod__sort {
  position: relative;
  color: #af0c0c;
}
.bd-mod__sort--selected {
  padding-right: 20px;
  position: relative;
}
.bd-mod__sort--selected:after {
  content: '';
  font-family: 'BLUD';
  position: absolute;
  right: 0;
  top: 2px;
  font-size: 5px;
  color: #aeaeae;
}
.bd-mod__sort--wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-mod__sort--wrap label {
  color: #979797;
}
.bd-mod__sort--wrap label {
  margin-right: 10px;
}
.bd-mod__sort--option {
  position: absolute;
  top: 25px;
  left: 0;
  visibility: hidden;
  padding: 5px;
  box-shadow: 0px 0px 4px rgba(34, 35, 38, 0.24);
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
  color: #222326;
  width: calc(100% + 10px);
}
.bd-mod__sort--option span {
  border-bottom: 1px solid rgba(34, 35, 38, 0.1);
  padding-bottom: 3px;
  margin-bottom: 2px;
  display: block;
  cursor: pointer;
}
.bd-mod__sort--option span.active {
  color: #af0c0c;
  cursor: default;
}
.bd-mod__sort--option span:last-child {
  border: 0;
}
.bd-mod__sort.active .bd-mod__sort--option {
  visibility: visible;
}
.bd-mod__container {
  padding: 24px;
  min-height: 200px;
  overflow: hidden;
}
.bd-mod__total {
  box-shadow: none;
}
.bd-mod__total .bd-mod__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: 0;
  min-height: auto;
}
.bd-mod__total--num {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.bd-mod__total--num span {
  font-size: 32px;
  font-weight: 700;
}
.bd-mod__total--num i {
  font-size: 18px;
  opacity: 0.7;
}
.bd-mod__total--wrap {
  width: 120px;
  height: 63px;
  background-color: #af0c0c;
  color: #fff;
  padding: 12px 12px 5px;
  border-radius: 10px;
}
.bd-modal-wrap {
  background: rgba(196, 196, 196, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  padding: 20px;
  z-index: 99;
  overflow: auto;
}
.bd-modal-wrap .bd-popup {
  padding: 24px;
}
.bd-modal-wrap .bd-popup__head {
  border: 0;
}
.bd-modal-wrap .bd-popup__head > span:first-child {
  font-size: 18px;
}
.bd-modal-wrap .bd-popup__head .bludclose {
  font-size: 13px;
  padding: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  top: -9px;
  position: relative;
}
.bd-modal-wrap.bd-modal-right {
  position: fixed;
}
.bd-modal-wrap.bd-modal-right .bd-popup {
  position: fixed;
  right: 0;
  bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-modal-wrap.bd-modal-right .bludclose {
  font-size: 10px;
  line-height: 12px;
}

.bd-popup {
  position: relative;
  max-width: 540px;
  width: 100%;
  min-height: 300px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  background-color: #fff;
  z-index: 2;
  margin: 10% auto 0 auto;
}
.bd-popup__absolute {
  overflow: hidden;
}
.bd-popup__fix {
  overflow: hidden;
}
.bd-popup__head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  margin-bottom: 5px;
  padding-bottom: 15px;
}
.bd-popup__head > span:first-child {
  font-size: 24px;
  font-weight: 700;
}
.bd-popup__head--rgt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bd-popup__body {
  background-color: #fff;
}
.bd-popup__lightbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bd-accept-issue .bd-volume {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.bd-accept-issue .bd-volume span {
  color: #979797;
}
.bd-accept-issue .bd-volume div:first-child span {
  color: #af0c0c;
}
.bd-accept-issue .bd-volume label {
  font-weight: 700;
  margin-right: 5px;
}
.bd-accept-issue__bot {
  margin-top: 20px;
  text-align: left;
}
.bd-accept-issue__bot label {
  color: #979797;
  margin-bottom: 5px;
  display: block;
}
.bd-accept-issue__bot .bd-tbl__theme1 .bd-tbl__cell {
  width: 100px !important;
}
.bd-accept-issue__bot .bd-tbl__body .bd-tbl__cell:not(:first-child) {
  font-size: 13px;
  color: #979797;
}
.bd-accept-issue__comment {
  color: #979797;
}
.bd-accept-issue__comment span {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
}
.bd-accept-issue__radio {
  margin: 30px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
}
.bd-accept-issue__radio label {
  margin-left: 10px;
}
.bd-accept-issue__radio .bd-radio {
  top: 3px;
}
.bd-accept-issue .bd-btn__wrap {
  margin: 16px 0;
  text-align: center;
}
.bd-accept-issue .bd-tbl__body--scroll {
  height: 200px;
  overflow: auto;
}
.bd-accept-issue .bd-btn {
  min-width: 144px;
  margin: 0 20px;
}
.bd-accept-issue__tbl {
  display: table;
  width: 100%;
}
.bd-accept-issue .bd-tbl__head,
.bd-accept-issue .bd-tbl__body {
  display: table;
  width: 100%;
}
.bd-accept-issue .bd-tbl__cell .bd-checkbox {
  top: 3px;
}
.bd-accept-issue .bd-tbl__cell:nth-child(1) {
  width: 35px;
}
.bd-accept-issue .bd-tbl__cell:nth-child(2) {
  width: 115px;
}
.bd-accept-issue .bd-tbl__cell:nth-child(2) span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.bd-accept-issue .bd-tbl__cell:nth-child(3) {
  width: 100px;
}
.bd-accept-issue .bd-tbl__cell:nth-child(4) {
  width: 90px;
}
.bd-accept-issue .bd-tbl__cell:nth-child(5) {
  width: 90px;
}
.bd-accept-issue .bd-tbl__cell:nth-child(6) {
  width: 90px;
}

.bd-blood-qauantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-blood-qauantity label {
  margin-right: 10px;
  padding-top: 4px;
}

.bd-blood-type {
  margin-right: 30px;
}
.bd-blood-type span {
  margin-right: 5px;
  vertical-align: middle;
}
.bd-blood-type span:first-child {
  font-size: 24px;
  color: #af0c0c;
  font-weight: 700;
}
.bd-blood-type span:last-child {
  font-size: 15px;
}

.bd-twocol {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.bd-twocol .bd-dorpdown {
  width: 45%;
}

.bd-request-blood .bd-popup__body {
  padding-top: 30px;
}
.bd-request-blood .bd-popup {
  max-width: 485px;
}

.bd-profile.bd-modal-right .bd-btn__wrap {
  padding: 30px 10% 0 10%;
}
.bd-profile.bd-modal-right .bludclose {
  background-color: #af0c0c;
  color: #fff;
}
.bd-profile__tbl .bd-pin {
  width: 50px;
  text-align: center;
}
.bd-profile__tbl .bd-pin .bludpin {
  padding: 10px 20px;
  cursor: pointer;
}
.bd-profile__tbl .bd-tab__content {
  margin-top: 20px;
  height: 255px;
  overflow: auto;
}
.bd-profile__tbl .bd-graph__wrap {
  flex: 1;
  position: relative;
}
.bd-profile__tbl .bd-graph__head {
  position: absolute;
  left: -135px;
  color: #af0c0c;
  font-weight: 600;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.bd-profile.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-profile.bd-modal-right .bd-popup {
  position: relative;
  min-width: auto;
  max-width: 1200px;
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-profile__otp {
  margin-top: 30px;
}
.bd-profile .bd-popup {
  min-width: 485px;
}
.bd-profile__detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.bd-profile__detail--tbl {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-profile__img {
  width: 96px;
  height: 96px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  margin-right: 12px;
}
.bd-profile__group_1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #161212;
  font-size: 19px;
}
.bd-profile__group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #af0c0c;
  font-size: 40px;
}
.bd-profile__group span {
  display: inline-block;
  vertical-align: middle;
}
.bd-profile__group b {
  font-size: 35px;
}

.bd-tbl__theme2 {
  width: auto;
  box-shadow: none;
}
.bd-tbl__theme2 .bd-btn__wrap .bd-btn {
  min-width: 160px;
}
.bd-tbl__theme2 .bd-tbl {
  box-shadow: none;
  width: auto;
  margin-top: 15px;
}
.bd-tbl__theme2 .bd-tbl__cell {
  border: 0;
  padding: 4px 10px 4px 0;
}
.bd-tbl__theme2 .bd-tbl__cell:first-child {
  text-align: right;
  color: #979797;
}
.bd-tbl__theme2 .bd-tbl__cell b {
  font-weight: 600;
}

.bd-donation__form {
  border-top: 1px solid rgba(151, 151, 151, 0.2);
  margin-top: 20px;
  padding-top: 20px;
}
.bd-donation__form .bd-twocol .bd-form__row {
  width: 45%;
}

.bd-select-reason .bd-popup__head {
  padding-bottom: 5px;
}
.bd-select-reason .bd-select-reason__row {
  margin-bottom: 10px;
}
.bd-select-reason .bd-select-reason__row .bd-radio {
  vertical-align: middle;
}
.bd-select-reason .bd-select-reason__row label {
  margin-left: 5px;
  vertical-align: middle;
}

.bd-addinventory {
  max-width: 311px;
  padding: 0 !important;
}
.bd-addinventory .bd-red__color {
  color: #af0c0c;
}

.bd-addinvent__top {
  height: 197px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}
.bd-addinvent__top i {
  width: 83px;
  height: 83px;
  border-radius: 100%;
  border: 5px solid #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 35px;
  font-weight: 600;
}
.bd-addinvent__top span {
  font-weight: 600;
}
.bd-addinvent__cont {
  padding: 16px;
  color: #3b4559;
  font-size: 17px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
}
.bd-addinvent__cont .bd-btn {
  flex: 1;
  min-width: auto;
  margin: 0 10px;
}
.bd-addinvent__ind {
  font-size: 22px;
  line-height: 27px;
}

.bd-result-icon {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 6px;
  border-radius: 100%;
  background-color: #008948;
  display: block;
  color: #fff;
  margin: auto;
  font-style: normal;
}
.bd-result-icon:before {
  content: '';
  font-family: 'BLUD';
}
.bd-result-icon.bd-red__color {
  background-color: #fc2526;
  color: #fff;
}
.bd-result-icon.bd-red__color:before {
  content: '';
}

.bd-tbl__head--main {
  display: block;
}

.bd-tbl__head--sub {
  display: block;
  font-size: 11px;
}

.bd-addinvent2.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-addinvent2.bd-modal-right .bd-popup {
  position: relative;
  max-width: 485px;
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-addinvent2 .bd-admoreunit {
  cursor: pointer;
  color: #af0c0c;
  font-weight: 600;
}
.bd-addinvent2 input[type='text'] {
  padding: 3px 0;
}

.bd-lab-criteria .bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-lab-criteria .bd-modal-right .bd-popup {
  position: relative;
  max-width: 30vw;
  /* min-width: 5vw; */
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-createcamp.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-createcamp.bd-modal-right .bd-popup {
  position: relative;
  max-width: 55vw;
  /* min-width: 5vw; */
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-createcamp input[type='text'] {
  padding: 3px 0;
}
.bd-CampDonorForm.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-CampDonorForm.bd-modal-right .bd-popup {
  position: relative;
  max-width: 32vw;
  /* min-width: 5vw; */
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-CampDonorForm input[type='text'] {
  padding: 3px 0;
}

.bd-addimages {
  overflow: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  padding: 5px;
}
.bd-addimages .bd-add {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: pointer;
  flex-shrink: 0;
  flex-direction: column;
  position: relative;
}
.bd-addimages .bd-add span {
  margin-top: 5px;
}
.bd-addimages .bd-add:first-child {
  margin-left: 0;
}
.bd-addimages .bd-add .bludclose {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  background-color: #af0c0c;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font-size: 9px;
  line-height: 13px;
  width: 22px;
  height: 21px;
  cursor: pointer;
  display: none;
}
.bd-addimages .bd-add.active {
  cursor: default;
}
.bd-addimages .bd-add.active .bludclose {
  display: block;
}
.bd-addimages .bd-add.active .bludplus {
  display: none;
}

.bd-lbresult .bd-popup__head .bd-form__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
}
.bd-lbresult .bd-popup__head .bd-form__row input {
  width: 100px;
  font-weight: 600;
  font-size: 18px;
}

.bd-lbresult .bd-popup__body > .bd-form:first-child {
  border-bottom: 1px solid #af0c0c;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.bd-lbresult .bd-tbl__cell {
  text-align: center;
}
.bd-lbresult .bd-tbl__cell input[type='text'] {
  width: 100px;
  border: 0;
}
.bd-lbresult .bd-tbl__cell:first-child {
  text-align: left;
}
.bd-lbresult .bd-form__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-lbresult .bd-form__row input {
  padding: 0 5px 5px 5px;
}
.bd-lbresult .bd-form__row .bd-calender:after {
  top: 1px;
}
.bd-lbresult .bd-form__row--1 div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 15px;
  white-space: nowrap;
}
.bd-lbresult .bd-form__row--1 div:first-child {
  margin-left: 0;
}
.bd-lbresult .bd-form__row--1 div:nth-child(2) {
  width: 150px;
}
.bd-lbresult .bd-form__row--1 div:nth-child(3) {
  width: 100px;
}
.bd-lbresult .bd-form__row--2 > div:first-child {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  margin-right: 15px;
}
.bd-lbresult .bd-form__row--2 .bd-dorpdown {
  flex: 1;
}
.bd-lbresult .bd-form__row--3 div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 15px;
  white-space: nowrap;
}
.bd-lbresult .bd-form__row--3 div:first-child {
  margin-left: 0;
}
.bd-lbresult .bd-form__row--3 div:nth-child(2) {
  width: 150px;
}
.bd-lbresult .bd-form__row--3 div:nth-child(3) {
  width: 100px;
}
.bd-lbresult .bd-form__row--4 div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 15px;
  white-space: nowrap;
}
.bd-lbresult .bd-form__row--4 div:first-child {
  margin-left: 0;
  flex: 1;
}
.bd-lbresult .bd-form__row--4 .bd-calender {
  width: 150px;
}
.bd-lbresult.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-lbresult.bd-modal-right .bd-popup {
  position: relative;
  min-width: auto;
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-lbresult .bd-print__result {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border-top: 1px solid #f2f0f0;
  border-bottom: 1px solid #f2f0f0;
}
.bd-lbresult .bd-print__result--btn {
  margin: 0 5%;
  cursor: pointer;
}
.bd-lbresult .bd-print__result--btn span {
  font-weight: 700;
  margin-right: 10px;
}
.bd-lbresult .bd-print__result .bd-dorpdown__selected {
  font-weight: 600;
}
.bd-lbresult .bd-print__result .bd-dorpdown {
  margin: 0 5%;
}
.bd-lbresult .bd-print__result .bd-dorpdown__option {
  width: 172px;
}
.bd-lbresult .bd-print__result .bd-dorpdown .bd-red__color {
  color: #fc2526;
}
.bd-lbresult .bd-print__result .bd-dorpdown .bd-green__color {
  color: #008948;
}
.bd-lbresult .bd-comment {
  padding-top: 10px;
}
.bd-lbresult .bd-comment input {
  padding: 5px 0;
  border: 0;
  border-bottom: 1px solid #f2f0f0;
  width: 100%;
}

.bd-labresults .bd-tbl__cell:last-child {
  text-align: center;
}
.bd-labresults .bd-remark {
  border: 0;
  width: 130px !important;
}
.bd-labresults .bd-txtby {
  margin-right: 15px;
}
.bd-labresults .bd-txtby span {
  margin-right: 5px;
}
.bd-labresults .bd-txtby__wrp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-labresults .bd-txtby__wrp div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
}
.bd-labresults .bd-txtby__wrp input {
  padding: 0 0 5px 0;
}
.bd-labresults .bd-txtby__wrp .bd-calender:after {
  top: 2px;
}
.bd-labresults.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-labresults.bd-modal-right .bd-popup {
  position: relative;
  min-width: auto;
  max-width: 1200px;
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.bd-self-assessment.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-self-assessment.bd-modal-right .bd-popup {
  position: relative;
  min-width: auto;
  max-width: 1200px;
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 50px;
}

ul.bd-assessment__list {
  list-style-type: decimal;
  margin-left: 15px;
  font-size: 15px;
}
ul.bd-assessment__list span {
  display: inline-block;
}
ul.bd-assessment__list span:first-child {
  width: 60%;
}
ul.bd-assessment__list li {
  margin-bottom: 15px;
  position: relative;
}
ul.bd-assessment__list li .bd-answer {
  margin-right: 0;
  margin-left: auto;
  padding-right: 15px;
  font-weight: 700;
  width: 39%;
}
ul.bd-assessment__list--sub {
  list-style-type: lower-alpha;
  margin-left: 15px;
  margin-top: 15px;
  font-size: 15px;
}

.bd-assessment__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.bd-assessment__top--lft {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.bd-assessment__top--lft div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  font-weight: 600;
}
.bd-assessment__top--lft div span:first-child {
  color: #979797;
  margin-right: 10px;
}
.bd-assessment__top--mid {
  font-size: 40px;
  font-weight: 700;
  color: #af0c0c;
  width: 100px;
}
.bd-assessment__top--mid b {
  margin-left: 2px;
  font-size: 30px;
  vertical-align: top;
}
.bd-assessment__top--rgt {
  font-size: 40px;
  flex: 2;
  color: #3b4559;
  text-align: right;
}
.bd-assessment__mid {
  height: calc(100vh - 200px);
  overflow: auto;
}
.bd-assessment__bot {
  text-align: center;
}
.bd-assessment__bot .bd-btn__wrap {
  justify-content: space-evenly;
}
.bd-assessment__bot .bd-confirm__input {
  padding: 15px 0;
}
.bd-assessment__bot .bd-confirm__input .bd-checkbox {
  top: 3px;
  margin-right: 10px;
}

.bd-searchfeature .bd-popup,
.bd-searchfeature .bd-popup__body,
.bd-searchfeature input {
  background-color: #fffaca;
}
.bd-searchfeature__detail {
  padding-bottom: 15px;
}
.bd-searchfeature .bd-form__row {
  flex-wrap: wrap;
}
.bd-searchfeature .bd-form__row--hd {
  width: 100%;
}
.bd-searchfeature .bd-btn {
  min-width: 130px;
  margin: 0 10px;
}
.bd-searchfeature .bd-btn__wrap {
  justify-content: center;
}
.bd-searchfeature .bd-btn i {
  margin-right: 5px;
  font-size: 9px;
}

.bd-requirement .bd-popup {
  padding: 24px 50px;
}
.bd-requirement .bd-popup__head > span:first-child {
  width: 100%;
  text-align: center;
  padding-left: 30px;
}
.bd-requirement__hd {
  padding-bottom: 30px;
  font-weight: 700;
  text-align: center;
}
.bd-requirement p {
  margin-bottom: 15px;
}
.bd-requirement__bot {
  margin: 70px 0 10px 0;
}
.bd-requirement__bot span {
  display: block;
}
.bd-requirement .bd-btn {
  min-width: 100px;
}
.bd-requirement .bd-btn__wrap {
  justify-content: space-between;
}

.bd-accept-issue.bd-modal-right {
  position: absolute;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 0;
}
.bd-accept-issue.bd-modal-right .bd-popup {
  position: relative;
  min-width: auto;
  margin: auto 0 0 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bd-accept-issue.bd-modal-right .bd-tbl__body--scroll {
  height: 350px;
}
.bd-accept-issue.bd-modal-right .bd-form {
  padding-top: 15px;
}
.bd-accept-issue.bd-modal-right .bd-form__row div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-end;
}
.bd-accept-issue.bd-modal-right .bd-form__row div input {
  padding: 3px;
  margin-left: 10px;
}
.bd-accept-issue .bd-components {
  width: 70% !important;
}

.bd-components__drp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.bd-components__drp label {
  color: #979797;
}
.bd-components__drp .bd-dorpdown {
  padding-left: 15px;
  color: #3b4559;
}
.bd-components__drp .bd-dorpdown__selected i {
  color: #3b4559;
}
.bd-components__drp .bd-dorpdown__option {
  width: 325px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: 0;
  padding: 10px;
}
.bd-components__drp .bd-dorpdown__list {
  padding: 8px;
  border: 0;
}
.bd-components__drp .bd-dorpdown__list.active {
  color: #af0c0c;
  cursor: default;
}

.bd-dbd__form .bd-radio {
  top: 2px;
}
.bd-dbd__form input[type='text'] {
  border: 0;
  border-bottom: 1px solid #f2f0f0;
  padding: 5px 5px 1px 5px;
}
.bd-dbd__form div[class^='bd-sec__'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-dbd__form section[class^='bd-sec__'] {
  border-bottom: 1px solid #f2f0f0;
  padding: 15px 0;
}

.bd-sec__1--1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.bd-sec__1--1 > span:first-child {
  flex: 1;
}
.bd-sec__1--1 span:not(:first-child) {
  margin-left: 30px;
}
.bd-sec__1--1 b {
  margin-left: 5px;
  color: #0d9823;
}
.bd-sec__2--2 div {
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
}
.bd-sec__2--2 div:last-child {
  padding-right: 0;
}
.bd-sec__2--2 div span {
  white-space: nowrap;
  margin-right: 5px;
}
.bd-sec__2--2 div input {
  width: 100%;
  margin-right: 5px;
}
.bd-sec__2 > .bd-sec__2--2:first-child {
  justify-content: center;
  margin-bottom: 15px;
}
.bd-sec__2 > .bd-sec__2--2:first-child div {
  flex: none;
  font-weight: 600;
}
.bd-sec__2 div[class^='bd-sec__2--'] {
  margin-bottom: 10px;
}
.bd-sec__3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.bd-sec__3--1 {
  flex: 1;
}
.bd-sec__3--2 div {
  margin-left: 15px;
}
.bd-sec__4--1 div {
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
}
.bd-sec__4--1 div:last-child {
  padding-right: 0;
}
.bd-sec__4--1 div span {
  white-space: nowrap;
  margin-right: 5px;
}
.bd-sec__4--1 div input {
  width: 100%;
}
.bd-sec__4--2 {
  flex-wrap: wrap;
}
.bd-sec__4--2 div:not(.bd-sec__4--head) {
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
}
.bd-sec__4--2 div:not(.bd-sec__4--head):last-child {
  padding-right: 0;
}
.bd-sec__4--2 div:not(.bd-sec__4--head) span {
  white-space: nowrap;
  margin-right: 5px;
}
.bd-sec__4--2 div:not(.bd-sec__4--head) input {
  width: 100%;
}
.bd-sec__4--head {
  width: 100%;
  font-weight: 600;
  margin-top: 15px;
}
.bd-sec__4--head + div {
  margin-right: 15px;
}
.bd-sec__4 div[class^='bd-sec__4--'] {
  margin-bottom: 10px;
}
.bd-sec__7 div[class^='bd-sec__7--'] {
  margin-bottom: 10px;
}
.bd-sec__7 div[class^='bd-sec__7--'] .bd-dorpdown {
  flex: 1;
  margin-left: 15px;
}
.bd-sec__7 div[class^='bd-sec__7--'] .bd-at {
  margin: 0 10px;
}
.bd-sec__7--2-1 {
  flex: 2;
}
.bd-sec__7--2-2 {
  flex: 0.5;
}
.bd-sec__7--2-2 input {
  width: 100%;
}
.bd-sec__7--3 input {
  flex: 1;
  margin-left: 10px;
}
.bd-sec__8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  border: none !important;
}
.bd-sec__8 div[class^='bd-sec__8--'] {
  margin-bottom: 15px;
}
.bd-sec__8 div[class^='bd-sec__8--'] div {
  flex: 1;
}
.bd-sec__9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-sec__9 div {
  flex: 1;
}
.bd-sec__6 div[class^='bd-sec__6--'] {
  margin-bottom: 10px;
}
.bd-sec__6 div[class^='bd-sec__6--'] div {
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
}
.bd-sec__6 div[class^='bd-sec__6--'] div:last-child {
  padding-right: 0;
}
.bd-sec__6 div[class^='bd-sec__6--'] div span {
  white-space: nowrap;
  margin-right: 5px;
}
.bd-sec__6 div[class^='bd-sec__6--'] div input {
  width: 100%;
}
.bd-sec__6--2 input {
  margin-right: 5px;
}
.bd-sec__6--head {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px !important;
}
.bd-sec__10 {
  border: none !important;
}
.bd-sec__10 ul {
  font-size: 15px;
  padding-left: 30px;
}
.bd-sec__10 ul .bd-checkbox {
  position: absolute;
  left: -25px;
  top: 2px;
}
.bd-sec__10 ul li {
  position: relative;
  margin-bottom: 10px;
}
.bd-sec__11 {
  border: none !important;
}
.bd-sec__11 ul {
  font-size: 15px;
  margin-left: 20px;
}
.bd-sec__11 li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}
.bd-sec__11 label {
  flex: 1;
}
.bd-sec__11 .bd-radio__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-sec__11 .bd-radio__wrap div {
  margin-left: 15px;
}
.bd-sec__11 textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border-color: #f2f0f0;
}
.bd-sec__12 {
  border: none !important;
}
.bd-sec__12--1 {
  padding-left: 30px;
  position: relative;
}
.bd-sec__12--1 .bd-checkbox {
  position: absolute;
  top: 2px;
  left: 0;
}
.bd-sec__12--2 {
  width: 100%;
}
.bd-sec__12 .bd-btn__wrap {
  width: 100%;
}

.bd-equalcolumn {
  width: 50%;
  flex: 1;
}

.bd-adddonor-row {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-adddonor-row input[type='text'] {
  padding: 10px 0;
  width: 100%;
}
.bd-adddonor__3 {
  justify-content: flex-start;
}
.bd-adddonor__3 input:first-child {
  max-width: 150px;
  width: auto;
  margin-right: 50px;
}
.bd-adddonor__3 input:nth-child(2) {
  max-width: 150px;
  width: auto;
  margin-right: 50px;
}
.bd-adddonor__3 input:nth-child(3) {
  max-width: 50px;
  margin-right: 10%;
}
.bd-adddonor__4 {
  justify-content: flex-start;
}
.bd-adddonor__4 input:first-child {
  max-width: 150px;
  width: auto;
  margin-right: 50px;
}
.bd-adddonor__4 input:nth-child(2) {
  max-width: 150px;
  width: auto;
  margin-right: 50px;
}
.bd-adddonor__4 input:nth-child(3) {
  max-width: 80px;
  margin-right: 10%;
}
.bd-adddonor__5 input:first-child {
  width: 60%;
  margin-right: 20%;
}
.bd-adddonor__5 input:nth-child(2) {
  width: 40%;
}
.bd-adddonor__save {
  width: 100%;
}
.bd-adddonor__save .bd-btn {
  min-width: 160px;
  margin: 0 5%;
}
.bd-adddonor__save .bd-btn__wrap {
  justify-content: center;
}

.bd-chat {
  background-color: #fff;
}
.bd-chat__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.bd-chat__head {
  padding: 24px;
  font-weight: 700;
  font-size: 24px;
}
.bd-chat__side {
  width: 360px;
  height: calc(100vh - 70px);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.bd-chat__main {
  flex: 1;
  height: calc(100vh - 80px);
  overflow: hidden;
}

.bd-side__container {
  height: calc(100vh - 140px);
  overflow: auto;
}
.bd-side__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  position: relative;
  cursor: pointer;
}
.bd-side__row:first-child:after {
  display: none;
}
.bd-side__row:after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: #f2f0f0;
  bottom: 0;
  left: 15px;
  right: 15px;
}
.bd-side__row:not(.bd-active) .bd-user__short,
.bd-side__row:not(.bd-active) .bd-user__tm {
  color: #979797;
}
.bd-side__row--rgt {
  margin-left: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bd-side__row--rgt .bd-user__total {
  background-color: #af0c0c;
  margin-top: 10px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
}
.bd-side__row--cont {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
}
.bd-side__row--cont div {
  overflow: hidden;
}
.bd-side__row--cont div span {
  display: block;
}
.bd-side__row--cont .bd-user__short {
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.bd-side__row.bd-active {
  background-color: rgba(175, 12, 12, 0.5);
}

.bd-user__img {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
  flex-shrink: 0;
}
.bd-user__name {
  font-weight: 600;
  margin-left: 10px;
}
.bd-user__conversation {
  padding: 8px 16px;
  color: #fff;
  position: relative;
}
.bd-user__conversation .bd__conversation:after {
  content: '';
  display: inline-block;
  width: 50px;
  height: 20px;
  float: right;
}
.bd-user__conversation .bd-user__time {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
}
.bd-user__conversation span {
  display: block;
}
.bd-user__conversation.bd-disabled {
  background-color: rgba(175, 12, 12, 0.5) !important;
}
.bd-user__conversation.bd-user__me {
  background-color: #af0c0c;
  border-radius: 6px 0px 6px 6px;
  margin-right: 0;
  max-width: 80%;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 10px;
  margin-left: auto;
}
.bd-user__conversation.bd-user__me.bd-disabled {
  margin-right: 50px;
}
.bd-user__conversation.bd-user__me i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -40px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  text-align: center;
  line-height: 29px;
  color: #af0c0c;
}
.bd-user__conversation.bd-user__inverse {
  background-color: #3b4559;
  border-radius: 0px 6px 6px 6px;
  max-width: 80%;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: auto;
  margin-left: 0;
}
.bd-user__day {
  text-align: center;
  padding: 5px 0;
}
.bd-user__head {
  height: 60px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.24);
  padding: 0 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.bd-user__head--rgt {
  margin-right: 0;
  margin-left: auto;
}
.bd-user__head--rgt i {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  cursor: pointer;
}
.bd-user__chat {
  padding: 0 30px 40px 40px;
  height: calc(100vh - 132px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.bd-user__chat--container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
}
.bd-user__chat--type {
  margin-bottom: 0;
  margin-top: 10px;
  padding-right: 60px;
  position: relative;
}
.bd-user__chat--type input {
  height: 40px;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #cecece;
  border-radius: 5px;
}
.bd-user__chat--type i {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #d3cece;
  text-align: center;
  border-radius: 5px;
  padding-top: 10px;
  cursor: pointer;
}
.bd-user__status {
  height: 30px;
  position: relative;
  padding: 5px 0;
  text-align: center;
}
.bd-user__status span {
  display: inline-block;
  background-color: #fff;
  z-index: 1;
  position: relative;
  padding: 2px 10px;
  top: -1px;
}
.bd-user__status:before {
  content: '';
  height: 1px;
  background-color: #979797;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
}

.bd__conversation.attachment {
  color: white;
  text-decoration: underline;
}
.bd__conversation.attachment :hover {
  cursor: pointer;
}
.file-select {
  visibility: hidden;
}

.bd-setting__header {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
}
.bd-setting .bd-tab {
  margin-bottom: 20px;
}

.bd-selectcomponents {
  margin-top: 30px;
}
.bd-selectcomponents .bd-list__primary {
  padding-right: 100px;
  width: 50%;
}
.bd-selectcomponents__hd {
  padding-bottom: 15px;
  color: #af0c0c;
}
.bd-selectcomponents__list {
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -5px;
}
.bd-selectcomponents__list .bd-btn {
  margin: 0 5px 10px 5px;
  border-radius: 30px;
}

.bd-camps__img {
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/camps/camps.jpg);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.bd-camps__img--lft span {
  display: inline-block;
  background-color: #fff;
  padding: 5px 10px;
  font-weight: 600;
  color: #af0c0c;
  border-radius: 5px;
  cursor: default;
}
.bd-camps__img--rgt {
  position: relative;
}
.bd-camps__img--rgt .bluddots__menu {
  padding: 10px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
  vertical-align: middle;
}
.bd-camps__img--rgt .bluddots__menu.active + .bd-camps__option {
  visibility: visible;
}
.bd-camps__img--rgt .bd-camps__option {
  visibility: hidden;
  position: absolute;
  right: 30px;
  top: 30px;
  background-color: #fff;
  padding: 16px;
  border-radius: 5px;
}
.bd-camps__img--rgt .bd-camps__option span {
  display: block;
  padding-bottom: 8px;
}
.bd-camps__img--rgt .bd-camps__option span:last-child {
  padding-bottom: 0;
}
.bd-camps__box {
  width: 46%;
  margin: 0 2% 24px 2%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}

.bd-camps__box:hover {
  background-color: rgba(151, 151, 151, 0.1);
}

.bd-camps_padding {
  /* padding:0 16px; */
  margin-top: 10px;
}

.bd-camps_padding:first-child {
  /* padding: 16px; */
  margin-top: unset;
}
.bd-camps__cont {
  padding: 16px;
}
.bd-camps__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -3%;
}
.bd-camps__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bd-camps__top--lft {
  font-weight: 600;
}
.bd-camps__top--lft span {
  display: block;
}
.bd-camps__top--lft span:last-child {
  font-size: 13px;
  margin-top: 5px;
}
.bd-camps__top--rgt {
  font-weight: 600;
  text-align: right;
}
.bd-camps__top--rgt span {
  display: block;
}
.bd-camps__top--rgt span i {
  cursor: pointer;
}
.bd-camps__top--rgt span:last-child {
  font-size: 13px;
  margin-top: 5px;
}
.bd-camps__top .bd-sponsored {
  width: 100%;
  margin: 10px 0;
  font-size: 13px;
}
.bd-camps__top .bd-sponsored b {
  color: #979797;
}
.bd-camps__bot {
  font-size: 13px;
  line-height: 16px;
}

.bd-camps__request {
  padding: 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.bd-camps__request--hd {
  font-weight: 700;
  font-size: 18px;
}
.bd-camps__request--wrap {
  border-bottom: 1px solid #f2f0f0;
  padding: 10px 0;
}
.bd-camps__request--wrap:first-child {
  padding-top: 0;
}
.bd-camps__request--wrap .bd-btn {
  min-width: 120px;
}
.bd-camps__request--wrap .bd-btn i {
  font-size: 10px;
  margin-right: 10px;
}
.bd-camps__details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 15px 0;
}
.bd-camps__details-com span:first-child {
  width: 48px;
  color: #979797;
}
.bd-camps__details-com span:last-child {
  padding-left: 15px;
}
.bd-camps__user span {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.bd-camps__user--info {
  padding-left: 15px;
  width: 100%;
}
.bd-camps__user--info div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.bd-camps__user--info > span {
  display: block;
}
.bd-camps__user--info > span:first-child {
  font-weight: 700;
  margin-bottom: 5px;
}

.bd-profile .bd-dashboard__header .bludedit {
  padding: 10px;
  font-size: 22px;
  cursor: pointer;
}
.bd-profile__address {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  padding-top: 20px;
}
.bd-profile__add {
  flex: 1;
  padding-right: 10%;
}
.bd-profile__add--hd {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
}
.bd-profile__add--id {
  padding-top: 20px;
}
.bd-profile__add--mob {
  padding-top: 5px;
}
.bd-profile__add--tm {
  padding-top: 5px;
}
.bd-profile__add--beds {
  padding-top: 5px;
}
.bd-profile__sign {
  width: 150px;
}
.bd-profile__sign--hd {
  font-weight: 600;
  font-size: 16px;
}
.bd-profile__review {
  width: 100%;
  color: #af0c0c;
  padding-top: 15px;
  cursor: pointer;
}
.bd-profile__reviews {
  margin-top: 20px;
}

.bd-profile .bd-addimages--hd {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.bd-profile__form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}
.bd-profile__form input[type='text'] {
  padding: 5px 0;
}
.bd-profile__form .bd-addmore {
  font-weight: 600;
  color: #af0c0c;
  text-align: right;
  margin-top: 10px;
  cursor: pointer;
}
.bd-profile__form--lft {
  width: 70%;
  padding-right: 30px;
  border-right: 1px solid #f2f0f0;
}
.bd-profile__form--rgt {
  width: 400px;
  padding-left: 30px;
}
.bd-profile__form .bd-contact div:not(class) {
  margin-bottom: 20px;
}
.bd-profile__form .bd-contact input {
  padding-left: 40px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 30px auto;
}
.bd-profile__form .bd-timing div {
  position: relative;
}
.bd-profile__form .bd-timing i {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 5px;
  font-size: 9px;
  color: #979797;
  cursor: pointer;
}
.bd-profile__form .bd-timing input {
  padding-right: 30px;
}
.bd-profile .bd-form__security {
  margin-top: 20px;
}
.bd-profile .bd-form__security .bd-form__hd {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
}

.bd-authorised-sign .bd-form__hd {
  margin-bottom: 15px;
}
.bd-authorised-sign .bd-form__hd b {
  font-weight: 600;
  font-size: 16px;
}
.bd-authorised-sign .bd-form__hd span {
  color: #979797;
}
.bd-authorised-sign .bd-replace {
  color: #af0c0c;
  font-weight: 600;
  margin-top: 15px;
  cursor: pointer;
}
.bd-authorised-sign .bd-pdf__img {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../images/pdf.png) no-repeat;
  background-size: cover;
  margin-right: 5px;
}
.bd-authorised-sign .bd-pdf__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  background-color: #f9f9f9;
  position: relative;
  padding: 5px;
  width: 120px;
}
.bd-authorised-sign .bd-pdf__wrap i {
  font-size: 8px;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  color: #979797;
}
.bd-authorised-sign .bd-pdf__wrap div span {
  display: block;
  line-height: 12px;
}
.bd-authorised-sign .bd-pdf__wrap div span:first-child {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 12px;
}
.bd-authorised-sign .bd-pdf__wrap div span:last-child {
  font-size: 10px;
  color: #979797;
}

.bd-reviews__hd {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}
.bd-reviews__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f0f0;
}
.bd-reviews__row--lft span {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-right: 15px;
  background-size: cover;
  background-repeat: no-repeat;
}
.bd-reviews__row--rgt {
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.bd-reviews__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.bd-reviews__bot {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
}
.bd-reviews__comment {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.bd-reviews__readmore {
  color: #af0c0c;
  font-weight: 600;
  cursor: pointer;
}
.bd-reviews__name {
  flex: 1;
}
.bd-reviews__star {
  margin-right: 15px;
  line-height: 8px;
}
.bd-reviews__star span {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  margin-left: 3px;
  line-height: 16px;
  cursor: pointer;
}
.bd-reviews__star span:before {
  content: '';
  font-family: 'BLUD';
  position: absolute;
  left: 0;
  top: 0;
}
.bd-reviews__star span.active:before {
  content: '';
  color: #ffc700;
}
.bd-reviews__date {
  color: #979797;
}
/*# sourceMappingURL=style.css.map */

.blud-user:before {
  content: '\e91a';
}
.bludlabs-icon:before {
  content: '\e92a';
}
.bludpeople-icon:before {
  content: '\e92b';
}
.bludeye:before {
  content: '\e900';
}
.bluddot:before {
  content: '\e901';
}
.bludclose-eye:before {
  content: '\e902';
}
.bludedit:before {
  content: '\e903';
}
.bludclose:before {
  content: '\e904';
}
.bludcamps:before {
  content: '\e905';
}
.bludageing:before {
  content: '\e906';
}
.bludtransfer:before {
  content: '\e907';
}
.bluddonar:before {
  content: '\e908';
}
.bludaccept:before {
  content: '\e909';
}
.bludshare:before {
  content: '\e90a';
}
.bludplus:before {
  content: '\e90b';
}
.bluddownload:before {
  content: '\e90c';
}
.bludbig-arrow:before {
  content: '\e90d';
}
.bludnotification:before {
  content: '\e90e';
}
.bludsearch:before {
  content: '\e90f';
}
.bludtickmark:before {
  content: '\e910';
}
.bludsetting:before {
  content: '\e911';
}
.bludchat:before {
  content: '\e912';
}
.bludblood-bag:before {
  content: '\e913';
}
.bludpeople:before {
  content: '\e914';
}
.bluddashboard:before {
  content: '\e915';
}
.bludlabresults:before {
  content: '\e916';
}
.bluddelete:before {
  content: '\e917';
}
.bludsort-arrow:before {
  content: '\e918';
}
.bludpower-off:before {
  content: '\e919';
}
.bluduser:before {
  content: '\e91a';
}
.bludcalender:before {
  content: '\e91b';
}
.bludprint:before {
  content: '\e91c';
}
.bludfilter:before {
  content: '\e91d';
}
.bludpin:before {
  content: '\e91e';
}
.bludquestionmark:before {
  content: '\e91f';
}
.bludrefresh:before {
  content: '\e920';
}
.bluddots__menu:before {
  content: '\e921';
}
.bludstart-fill:before {
  content: '\e922';
}
.bludstar:before {
  content: '\e923';
}
.bludissued:before {
  content: '\e924';
}
.bluddonation:before {
  content: '\e925';
}
.bludblud-icon:before {
  content: '\e926';
}
.bludchat-icon:before {
  content: '\e927';
}
.bludlab-results:before {
  content: '\e928';
}
.bludlab-samples:before {
  content: '\e929';
}
