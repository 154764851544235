.bd-camps {

    &__img {
        height: 180px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../images/camps/camps.jpg);
        @include flexbox;
        justify-content: space-between;
        padding: 15px;

        &--lft {
            span {
                display: inline-block;
                background-color: $white;
                padding: 5px 10px;
                font-weight: $medium_font-weight;
                color: $client-primary-color;
                border-radius: 5px;
                cursor: default;
            }

        }

        &--rgt {
            position: relative;

            .bluddots__menu {
                padding: 10px;
                width: 32px;
                height: 32px;
                background-color: $white;
                border-radius: 100%;
                cursor: pointer;
                vertical-align: middle;

                &.active {
                    +.bd-camps__option {
                        visibility: visible;
                    }
                }
            }

            .bd-camps__option {
                visibility: hidden;
                position: absolute;
                right: 30px;
                top: 30px;
                background-color: $white;
                padding: 16px;
                border-radius: 5px;

                span {
                    display: block;
                    padding-bottom: 8px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    &__box {
        width: 46%;
        margin: 0 2% 24px 2%;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &__cont {
        padding: 16px;


    }

    &__container {
        @include flexbox;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 -3%;
    }

    &__top {
        @include flexbox;
        justify-content: space-between;
        flex-wrap: wrap;

        &--lft {
            font-weight: $medium_font-weight;

            span {
                display: block;

                &:last-child {
                    font-size: 13px;
                    margin-top: 5px;

                }
            }
        }

        &--rgt {
            font-weight: $medium_font-weight;
            text-align: right;

            span {
                display: block;

                i {
                    cursor: pointer;

                }

                &:last-child {
                    font-size: 13px;
                    margin-top: 5px;
                }
            }
        }

        .bd-sponsored {
            width: 100%;
            margin: 10px 0;
            font-size: 13px;

            b {
                color: $light-gray;
            }
        }
    }

    &__bot {
        font-size: 13px;
        line-height: 16px;
    }
}


.bd-camps {
    &__request {
        padding: 16px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        &--hd {
            font-weight: $bold_font-weight;
            font-size: 18px;
           
        }
        &--wrap{
            border-bottom: 1px solid $gray;
            padding: 10px 0;
            &:first-child{
                padding-top: 0;
            }
            .bd-btn{
                min-width: 120px;
                i{
                    font-size: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
    &__details{
        @include flexbox;
        width: 100%;
        margin: 15px 0;
        &-com{
            span{
                &:first-child{
                    width: 48px; 
                    color: $light-gray;
                }
                &:last-child{
                    padding-left: 15px;
                }
            }
        }

    }
    &__user{
        span{
            display: inline-block;
            width: 48px;
            height: 48px;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &--info{
            padding-left: 15px;
            width: 100%;
            div{
                 @include flexbox;
                 width: 100%;
                 justify-content: space-between;
            }
            & > span{
                display: block;
                &:first-child{
                    font-weight: $bold_font-weight;
                    margin-bottom: 5px;
                }
            }
        }
    }

}