.bd-setting {
    &__header {
        font-weight: $bold_font-weight;
        font-size: 24px;
        margin-bottom: 20px;
            width: 100%;
    } 
    .bd-tab{
        margin-bottom: 20px;
    }
}

.bd-selectcomponents{
	margin-top: 30px;
	.bd-list__primary{
		padding-right: 100px;
		width: 50%;
	}
	&__hd{
		padding-bottom: 15px;
	}
	&__list{
		@extend %flexbox;
		flex-wrap: wrap;
		width: 100%;
		margin: 0 -5px;
		.bd-btn{
			margin: 0 5px 10px 5px;
			border-radius: 30px;
		}
	}
}