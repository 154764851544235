.bd-mod {
    margin-bottom: 20px;
    box-shadow: 0px 0px 4px rgba($black, 0.24);
    border-radius: 4px;

    &__head {
        padding: 24px;
        @include flexbox;
        border-bottom: 1px solid #EBEFF2;
        justify-content: space-between;
        > span:first-child{
            font-size: 16px;
            font-weight: $bold_font-weight;
        }
    }

    &__sort {
        position: relative;
        color: $client-primary-color;

        &--selected {
            padding-right: 20px;
            position: relative;

            &:after {
                content: $bludsort-arrow;
                font-family: $icomoon-font-family;
                position: absolute;
                right: 0;
                top: 2px;
                font-size: 5px;
                color: #AEAEAE;

            }
        }

        &--wrap {
            @include flexbox;
            label{
                color: $light-gray;
            }
            
            label {
                margin-right: 10px;
            }
        }

        &--option {
            position: absolute;
            top: 25px;
            left: 0;
            visibility: hidden;
            padding: 5px;
            box-shadow: 0px 0px 4px rgba($black, 0.24);
            border-radius: 4px;
            background-color: $white;
            z-index: 1;
            color: $black;
            width: calc(100% + 10px);
            span{
                border-bottom: 1px solid rgba($black, 0.1);
                padding-bottom: 3px;
                margin-bottom: 2px;
                display: block;
                cursor: pointer;
                &.active{
                    color: $client-primary-color;
                    cursor: default;
                }
                &:last-child{
                    border: 0;
                }            }
        }

        &.active {
            .bd-mod__sort--option {
                visibility: visible;
            }
        }
    }

    &__container {
        padding: 24px;
        min-height: 200px;
        overflow: hidden;
    }

    &__total {
        box-shadow: none;

        .bd-mod__container {
            @include flexbox;
            justify-content: space-between;
            padding: 0;
            min-height: auto;

        }
        &--num{
            @include flexbox;
            justify-content: space-between;
            padding-bottom: 10px;
            span{
                font-size: 32px;
                font-weight: $bold_font-weight;
            }
            i{
                font-size: 18px;
                opacity: 0.7;
            }

        }
        &--wrap {
            width: 120px;
            height: 63px;
            background-color: $client-primary-color;
            color: $white;
            padding: 12px 12px 5px;
            border-radius: 10px;

            label{
                // color: rgba($white, 0.6);
            }
        }


    }
}