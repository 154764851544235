html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;

}

body {

    background: #E5E5E5;
    min-height: 100%;

}


/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $gray;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $client-primary-color;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $client-primary-color;
    border-radius: 5px;
}

* {
    margin: 0;
    padding: 0;
    @include box-sizing(border-box);
}

.bd-root {
    transform: scale(1);
    -webkit-transform: scale(1);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}



.bd-signin .bd-form__row input::placeholder {
    color: $black;
    opacity: 1;
}

.bd-signin .bd-form__row input:-ms-input-placeholder {
    color: $black;
}

.bd-signin .bd-form__row input::-ms-input-placeholder {
    color: $black;
}


.bd-form__row input::placeholder {
    color: $light-gray;
    opacity: 1;
}

.bd-form__row input:-ms-input-placeholder {
    color: $light-gray;
}

.bd-form__row input::-ms-input-placeholder {
    color: $light-gray;
}

.bd-container-fluid {
    width: 100%;
    @include flexbox;
}

.bd-main {
    min-height: 100%;
    font-size: 15px;
    line-height: 18px;
    font-family: $regular_font-family;
    color: $black;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    background-color: #e5e5e5;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: 0;
        -webkit-tap-highlight-color: transparent;
        @include box-sizing(border-box);
    }

    * {
        margin: 0;
        padding: 0;
        @include box-sizing(border-box);
    }



    &__container {
        flex: 1;
        position: relative;

    }

    .bd-article {
        min-height: 600px;
        background-color: $white;

    }

    .bd-menu {
        width: 200px;
        padding: 32px;
        min-height: 100vh;
        background-color: $white;
    }

    ul,
    ol {
        margin: 0;
        padding: 0;
        font-size: 0;
        list-style: none;
    }

    em {
        font-style: normal;
    }

    a {
        color: $client-primary-color;
        text-decoration: none;

        &:hover {}

        &:active {}
    }

    b,
    strong {

        font-weight: $bold_font-weight;
    }




    :focus {
        outline: none;
    }

    *,
    *::before,
    *::after {
        @include box-sizing(border-box);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: 0;
        -webkit-tap-highlight-color: transparent;
    }
}






//table style start here

.bd-tbl {
    display: table;
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    border-collapse: collapse;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    &__head,
    &__body,
    &__foot {
        display: table-header-group;
    }

    &__head {
        background-color: $client-primary-color;
        color: $white;
    }

    &__row {
        display: table-row;
        &.bd-10plus{
            .bd-tbl__cell--filterpp{
                top: auto;
                bottom: 40px;
            }
        }
    }

    &__cell {
        display: table-cell;
        vertical-align: middle;
        border: 1px solid rgba(151, 151, 151, 0.2);
        padding: 10px;
        input[type="text"]{
            width: 100%;
            text-align: center;
            border: 0;
        }
        &--center {
            text-align: center;
        }

        &--filter {
            cursor: pointer;
            white-space: nowrap;
            position: relative;
            .bd-red__color{
                color: $red;
            }
            .bd-green__color{
                color: $green;
            }
            i {
                font-size: 10px;
                margin-left: 10px;
                font-weight: $medium_font-weight;
                @include transform(rotate(90deg));
                display: inline-block;
            }

            &.active {
                i {
                    @include transform(rotate(-90deg));
                }

                .bd-tbl__cell--filterpp {
                    visibility: visible;
                }
            }

            &pp {
                visibility: hidden;
                padding: 10px;
                background-color: $white;
                border-radius: 5px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                position: absolute;
                top: 40px;
                right: 0;
                width: 110px;
                text-align: left;
                z-index: 1;

                div {
                    padding-bottom: 10px;
                    position: relative;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

            }
        }
    }

    &__theme1 {
        box-shadow: none;
        border-radius: 0;

        .bd-tbl__head {
            background-color: transparent;
            color: #222326;
        }

        .bd-tbl__cell {

            border-left: 0;
            border-right: 0;

        }

        .bd-tbl__head {
            font-weight: $medium_font-weight;

            .bd-tbl__row {
                .bd-tbl__cell {
                    border-top: 0;
                    color: #3B4559;

                }

            }
        }

        .bd-tbl__body {
            .bd-tbl__row {
                &:last-child {
                    .bd-tbl__cell {
                        border-bottom: 0;
                    }

                }

                &:first-child {
                    .bd-tbl__cell {
                        border-top: 0;
                    }
                }

            }
        }
    }
}


.bd-tbl__body {
    .bd-nodata{
        width: 100%;
        min-height: 200px;
        @include flexbox;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        font-weight: $bold_font-weight;
        position: relative;

        &:before{
            content: "";
            width: 60px;
            height: 60px;

            background: url(../images/no-data-found.svg) no-repeat center center;
            background-size: cover; 
        }

      
    }
    .bd-tbl__cell--filter{
        &:last-child{
            .bd-filterpp__sub{
                right: auto;
                left: calc(-100% - -10px);
            }
        }
    }
    .bd-tbl__cell--filterpp {
        padding: 0;
        div{
            padding: 5px 5px;
            margin:0 5px;
            border-bottom:1px solid $gray;
        }
    }

    .bd-filterpp__sub {
        visibility: hidden;
        padding: 0!important;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
        right: calc(-100% - -10px);
        top: 0; 
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .bd-filterpp__list{
        &.active{
            &:after{
                content: $bludbig-arrow;
                font-family: $icomoon-font-family;
                position: absolute;
                right: 5px;
                top: 5px;
                font-size: 10px;
            } 
            &:hover{
                .bd-filterpp__sub{
                    visibility: visible;
                }
            }
        }

    }
}

//Buttons
.bd-btn {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: $medium_font-weight;
    line-height: 1;
    min-width: 200px;
    padding: 6px 20px;
    user-select: none;
    text-align: center;
    border-radius: 8px;
    color: $white;

    &__wrap {
        justify-content: space-around;
        @include flexbox;

        padding: 20px 0;
    }

    @media screen and (min-width: $media-tablet-max) {
        font-size: 14px;
        padding: 8px 30px;
    }

    &--primary {
        background-color: $client-primary-color;
    }

    &--secondary {}

    &--deactive {
        background: #787679;
        border-color: #787679;
        color: $white;
        cursor: not-allowed;
        opacity: .5;
    }

    &--outline {
        border: 1px solid $client-primary-color;
        color: $client-primary-color;
    }

    &.bd-disabled {
        opacity: 0.5;
        cursor: default;
    }

    &--share {
        background-color: $share;
    }

    &--issue {
        background-color: $accept;
    }

    &--accept {
        border-color: $accept;
        color: $accept;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);

        &.active {
            background-color: $accept;
            color: $white;
            box-shadow: none;
        }
    }
}


.bd-checkbox,
.bd-radio {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;

    b {
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        border-radius: 100%;
        border: 1px solid #c4c4c4;
        line-height: 13px;
        text-align: center;
        font-size: 7px;
    }

    input {
        width: 15px;
        height: 15px;
        opacity: 0;
        z-index: 2;
        position: relative;
    }

    input:checked {
        +b {
            border-color: $client-primary-color;
            color: $client-primary-color;

            &:before {
                // content: $bludtickmark;
                // font-family: $icomoon-font-family;
                content:"";
                position: absolute;
                width: 9px;
                height: 9px;
                left: 2px;
                top:2px;
                background-color: $client-primary-color;
                border-radius: 2px;
            }
        }
    }
}

.bd-radio{
    b{
        &:before{
            border-radius: 100%!important;
        }
    }
}
.bd-checkbox {
    b {
        border-radius: 4px;
    }
}

// file upload button
.bd-file-upload {
    position: relative;
    overflow: hidden;
    width: 135px;

    button {
        min-width: 100%;
        padding: 8px;
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}

// download
.bd-download-temp {
    color: $client-primary-color;
    font-weight: $medium_font-weight;
    cursor: pointer;

    i {
        margin-right: 5px;
    }
}


// list style
.bd-list__lower-disc {
    list-style-type: disc;
}


// custom dropdown
.bd-dorpdown {
    position: relative;
    color: $light-gray;

    &__selected {
        position: relative;
        padding: 3px 20px 3px 0;
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        cursor: pointer;
        min-width: 52px;

        i {
            position: absolute;
            right: 7px;
            top: 50%;
            font-size: 10px;

            color: $light-gray;
            @include transform(translateY(-50%) rotate(90deg));

        }
    }

    &__option {
        background-color: $white;
        // border: 1px solid $light-gray;
        border-radius: 3px;
        padding: 3px 5px;
        position: absolute;
        top: 29px;
        left: 0;
        width: 100%;
        cursor: pointer;
        visibility: hidden;
        z-index: 9;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
                border: 0;
    }

    &__list {
        border-bottom: 1px solid rgba($black, 0.1);
        padding: 5px 0;

        &:last-child {
            border: 0;
            // padding-bottom: 0;
        }

    }

    &.active {
        .bd-dorpdown__option {
            visibility: visible;
        }
    }
}

//calender icon css
.bd-calender {
    position: relative;

    border-bottom: 1px solid rgba(151, 151, 151, 0.2);

    input[type=text] {
        border-bottom: 0 !important;
        padding-right: 30px !important;
    }

    &:after {
        content: $bludcalender;
        font-family: $icomoon-font-family;
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 15px;
        cursor: pointer;

    }
}
// button bg colors
.db-bg__green {
    background-color: $accept;
}
.db-bg__red {
    background-color: $client-primary-color;
}
.db-bg__blue {
    background-color: $share; 

}
