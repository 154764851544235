.bd-dashboard {
    padding: 20px 40px;

    &__lft {
        flex: 1;
        padding: 0 5px; // hack for table scroll under donation
        margin: 0 -5px; // hack for table scroll under donation
        overflow: hidden; // hack for table scroll under donation

        .bd-tab {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }


        .bd-dashboard__main {
            font-size: 24px;
        }

        .bd-dashboard__date {
            font-size: 18px;
            margin-top: 10px;
        }

        .bd-tab__content {
            padding: 16px;
        }
    }

    &__rgt {
        flex: 0 0 445px;
        padding-left: 40px;

    }

    &__container {
        @include flexbox;
        flex-wrap: wrap;


    }

    &__header {
        font-weight: $bold_font-weight;
        margin-bottom: 20px;
        width: 100%;
        @include flexbox;
        align-items: center;

        .bd-tab {
            // max-width: 250px;
            // width: 100%;
            margin-left: 15px;
            border-radius: 8px;
        }

        .bd-addlab__result {
            color: $client-primary-color;
            margin-left: 15px;
            cursor: pointer;

            i {
                margin-right: 5px;
                font-size: 10px;
            }
        }

        span {
            display: block;
        }

    }


}

.bd-request {

    border-bottom: 1px solid $client-primary-color;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &__tbl {
        &.bd-donation__tbl {
            .bd-tbl__cell {
                &:nth-child(3) {
                    text-align: left;
                }
            }
        }

        .bd-btn {
            min-width: 96px;

            i {
                font-size: 10px;
                margin-right: 5px;
            }
        }

        .bd-tbl__cell {
            &:nth-child(1) {
                max-width: 150px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                text-align: center;
            }

            &:nth-child(6) {
                span {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 150px;
                }
            }
        }

        .bd-tbl__body {
            .bd-tbl__cell {
                &:nth-child(2) {
                    font-weight: $bold_font-weight;
                }
            }
        }

        .bd-tbl__row {
            &.bd-selected {
                color: $client-primary-color;
            }
        }

    }

    &__wrap {
        position: relative;
        padding-left: 70px;
        @include flexbox;
        flex-direction: column;
    }

    &:last-child {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &__share {
        @include flexbox;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        justify-content: space-around;

        .bd-btn {
            i {
                margin-right: 5px;
                font-size: 10px;
            }

        }
    }
}


.bd-blood {
    &__group {
        @include flexbox;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        line-height: 56.22px;
        position: absolute;
        left: 0;
        top: 0;

        &--main {
            padding-left: 16px;

            .bd-attachment {
                &__date {
                    label {
                        color: $light-gray;
                        font-weight: $bold_font-weight;
                    }
                }
            }
        }

        span {
            width: 64px;
            height: 64px;
            @include flexbox;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            text-transform: uppercase;
            border-radius: 100%;
            font-weight: $bold_font-weight;
            color: $white;

            b {
                font-size: 22px;
                margin-left: 2px;
            }
        }


    }

    &__requested {
        @include flexbox;
        justify-content: space-between;
        margin-bottom: 5px;

        .bd-blood__type {
            font-size: 15px;
            font-weight: $medium_font-weight;

        }

        .bd-blood__stage {
            color: $client-primary-color;
            font-weight: $medium_font-weight;
        }

        .bd-blood__units {

            display: block;
            padding-top: 5px;

        }

        .bd-blood__num {
            display: block;
            margin: 5px 0;
        }

        .bd-blood__status {
            display: block;

            label {
                color: $light-gray;
                margin-right: 5px;
            }
        }

        .bd-blood__graph {
            @include flexbox;
            flex: 1;
            justify-content: center;
            margin-top: 5px;

            div {
                flex: 0 0 30px;
                height: 5px;
                border-radius: 10px;
                background-color: $yellow;
                margin: 0 2px;

                &.bd-good {
                    background-color: $green;
                }
            }
        }

    }

    &__details {
        @include flexbox;
        justify-content: space-between;
        margin: 10px 0;

        .bd-hosp-address {
            span {
                display: block;

                &:first-child {
                    color: $light-gray;
                    margin-bottom: 10px;
                }

            }
        }

        .bd-hosp-distance {
            color: $light-gray;
        }
    }

    &__normal {
        .bd-blood__group span {
            background-color: $client-primary-color;

        }

        .bd-blood__units {
            b {
                color: $client-primary-color;
            }
        }
    }

    &__plasma {
        .bd-blood__group span {
            background-color: $yellow;
        }

        .bd-blood__units {
            b {
                color: $yellow;
            }
        }
    }
}

// Donation Tabel
.bd-tbl__donation {
    overflow: auto;
    width: calc(100% + 32px);
    height: calc(100vh - 150px);
    margin: -16px;

    .bd-tbl {
        table-layout: fixed;
        border-radius: 0;
        box-shadow: none;
    }

    .bd-tbl__cell {
        width: 45px;
        text-align: center;

        &:first-child {
            width: 250px;
            text-align: left;
        }
    }

}

.bd-labrequest__tbl {
    text-align: center;
}

.bd-tbl__cell {
    &.bd-red__color {
        color: $red;
        font-weight: $medium_font-weight;
    }

    &.bd-green__color {
        color: $green;
        font-weight: $medium_font-weight;
    }
}



// Samples  recived

.bd-samp__recived {
    min-height: 400px;

    .bd-btn {
        min-width: 96px;
        padding: 8px 10px;
    }

    .bd-tbl__cell {
        &:nth-last-child(2) {
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.bd-header__search {
    .bd-hader {
        margin-left: auto;
        margin-right: 0;
        box-shadow: none;
    }

    .bd-filter {
        margin-left: 15px;
        position: relative;
    }

    .bludfilter {
        padding: 10px;
        font-size: 18px;
        cursor: pointer;
    }
}

.bd-filter {

    &.active {
        .bd-filter__pp {
            visibility: visible;
        }
    }

    &__pp {
        visibility: hidden;
        background-color: $white;
        padding: 15px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        position: absolute;
        top: 30px;
        right: 0;

        .bd-filter__row {
            @include flexbox;
            white-space: nowrap;
            margin-bottom: 5px;
            padding-bottom: 0;

            label {
                width: 80px;
                color: #3B4559;
                font-weight: $medium_font-weight;
                margin-right: 10px;
            }

            input[type="text"] {
                width: 120px;
                border: 0;
                border-bottom: 1px solid $gray;
                padding-bottom: 5px;
            }
        }
    }

    &__radio {
        justify-content: flex-start;
        padding-top: 15px;

        .bd-radio {
            margin-right: 5px;
            top: 2px;
        }

        div {
            @include flexbox;

            label {}
        }
    }
}

.bd-people {
    min-height: 400px;
}

// inventory 
.bd-inventory__scr {
    @include flexbox;

    .bd-blood__group span {
        width: 40px;
        height: 40px;
        font-size: 15px;

        b {
            font-size: 15px;
        }

    }
}

.bd-blood__group {
    &--list {
        @include flexbox;
        flex-direction: column;
        padding-right: 15px;
        padding-top: 45px;
    }

    &--col {
        @include flexbox;
        position: relative;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        line-height: 56.22px;
        margin-bottom: 18px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        border:1px solid $client-primary-color;
        font-size: 15px;
        color: $client-primary-color;
        text-transform: uppercase;
        cursor: pointer;
        &.active{
            background-color: $client-primary-color;
            color: $white;
            cursor: default;
        }
        span{
            font-size: 17px;
            font-weight: $bold_font-weight;
            b{
                font-size: 15px;
            }
        }
    }
}

.bd-transfer__btn {
    width: 96px;

    .bd-tbl__cell--filterpp {
        top: 10px;
        right: 130px;
    }
}

.bd-pagination {
    width: 100%;
    @include flexbox;
    justify-content: flex-end;

    label {
        margin-right: 20px;
    }

    &__nav {
        font-size: 13px;

        span {
            width: 20px;
            height: 20px;
            border-radius: 3px;
            text-align: center;
            line-height: 18px;
            margin: 0 5px;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid $light-gray;
            cursor: pointer;

            &.active {
                border-color: $client-primary-color;

            }
        }

    }

    &__dot {
        font-size: 8px;
        border: 0 !important;
        margin: 0 2px !important;
        color: $light-gray;
        width: auto !important;

    }

}

.bd-scroll__horz{
    width: 100%;
    height: calc(100vh - 200px);
    overflow: auto;
}