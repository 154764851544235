.bd {
    &-registration {
        max-width: 423px;
        background-color: $white;
        padding: 40px;
        min-height: 493px;
        border-radius: 8px;
        margin: 50px auto 50px auto;

        // @include transform( translateY(-50%) translateX(-50%));
        &__header {
            font-weight: $medium_font-weight;
            font-size: 20px;
            margin-bottom: 15px;
        }


        &__content {
            text-align: center;
            margin: 20px 0;

            p {
                margin-bottom: 15px;
                color: #3B4559;

            }
        }

    }

}

.bd-logo {
    width: 190px;
    height: 56px;
    margin: auto auto 30px auto;
    background-color: $gray;
    background-image: url(../images/default-logo.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
}

.bd-reg-insititution {
    ul {
        font-size: 17px;
        padding-left: 20px;
        padding-top: 15px;

        li {
            padding-bottom: 10px;
            position: relative;
            padding-right: 30px;

            .bd-checkbox{
                position: absolute;
                right: 0;
                top: 5px;
            }
        }
    }
}



// set password
.bd-setpassword {
    max-width: 1086px;
    padding: 80px;

    .bd-registration__header {
        margin-bottom: 30px;
    }
}

.bd-setpass {
    @include flexbox;

    &__lft {
        flex: 1;
        padding-right: 120px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: rgba(151, 151, 151, 0.2);

        }
    }

    &__rgt {
        flex: 1;
        padding-left: 120px;

        ul {
            font-size: 15px;

            li {
                padding-bottom: 20px;
            }
        }
    }
}

// forgot password
.bd-forgot-pass {
    .bd-forgot-pass__cont {
        font-size: 15px;
        color: $light-gray;
        padding-left: 30px;

        li {
            padding-bottom: 20px;
            position: relative;

            &:before {
                content: $bluddot;
                font-family: $icomoon-font-family;
                font-size: 5px;
                position: absolute;
                left: -15px;
                top: 0;
                color: #222326;
            }
        }
    }

    .bd-invalid__email {
        input[type="text"] {
            border-bottom-color: $client-primary-color;
        }
    }
}


// OTP
.bd-otp {
    p {
        margin-bottom: 20px;
        color: $light-gray;
    }

    &__box {
        @include flexbox;

        input {
            width: 16.66%;
            margin: 0 5px;
            text-align: center;
            font-size: 17px;
            font-weight: $medium_font-weight;

            &:first-child {
                margin-left: 0;
            }

            &:lastt-child {
                margin-right: 0;
            }

            &[type=text].active {
                border-bottom-color: $client-primary-color;
            }
        }
    }
}

// form common
.bd-form {
    .bd-registration__header {
        padding-top: 15px;
    }

    &__row {
        margin-bottom: 20px;
        position: relative;

        &--resendotp {
            text-align: center;
        }

        &--pass {
            input {
                padding-right: 20px !important;
            }

            .bd-eyeIcon {
                position: absolute;
                right: 0;
                bottom: 11px;
                cursor: pointer;

                &:before {
                    content: $bludclose-eye;
                    font-family: $icomoon-font-family;
                }

                &.bd-show {
                    &:before {
                        content: $bludeye;
                    }
                }
            }
        }

        &--forgotpass {
            @include flexbox;
            justify-content: flex-end;

            .bd-incorrect__msg {
                flex: 1;
            }
        }

        &--setpass {
            text-align: center;
            margin: 40px 0;

            .bd-btn {
                min-width: 240px;

            }
        }

        label {
            color: $light-gray;
            display: block;
            font-size: 13px;
            font-weight: $medium_font-weight;
            // margin-bottom: 5px;
        }

        &--signup {
            text-align: center;
            font-size: 15px;
        }

        &--signin {
            margin: 40px 0;
            text-align: center;

            .bd-btn {
                min-width: 280px;
            }
        }

        &--register {
            text-align: center;

            p {
                // text-align: center;
            }

            .bd-btn {
                min-width: 280px;
                margin-top: 20px;
            }
        }

        input[type="password"] {
            flex: 1;
        }

        input[type="text"],
        input[type="password"] {
            width: 100%;
            padding: 10px 0;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            border-bottom: 1px solid rgba($light-gray, 20%);
            font-size: 15px;
        }

        .bd-incorrect__msg {
            font-size: 15px;
            color: $client-primary-color;
        }

        .bd-forgot-pass {
            text-align: right;

            a {
                font-size: 13px;
                color: #3B4559;
                text-decoration: none;
            }

        }

    }

}


.bd-tab {
    background-color: rgba($light-gray, 0.1);
    @include flexbox;
    padding: 5px;
    color: $light-gray;
    border-radius: 8px;
    &__header{
            @include flexbox;
            justify-content: space-between;
            margin-bottom: 15px;
            > span{
                &:first-child{
                                font-size: 16px;
            font-weight: $medium_font-weight;

                }
            }
            .bd-viewall{
                cursor: pointer;
                color: $client-primary-color;

            }
    }
    &__main{
            box-shadow: 0px 0px 4px rgba($black, 0.24);
            border-radius: 8px;
    }
    div {
        flex: 1;
        @include flexbox;
        justify-content: center;
        align-items: center;
        height: 40px;
        cursor: pointer;
        border-radius: 8px;
        padding: 0 30px;
        white-space: nowrap;
        &.active {
            background-color: $white;
            color: $client-primary-color;
            cursor: default;

        }
    }
}