//Colors
$transparent: transparent;
$default: #393939;
$gray: #F2F0F0; 
$light-gray: #979797;
$white: #fff;
$black: #222326;
$green: #008948;
$red:#fc2526;
$yellow: #FFC700;
$default-hp: #262626;


//client colors

$client-primary-color:#AF0C0C;  
$client-secondary-color:#ff5d2b;		//orange
$share: #2A6BF4;
$accept: #0D9823;




// social icons
$googlePlus:#dd4b39;
$facebook:#3b5998;
$twitter:#1da1f2;
$whatsApp:#7ad06d;
$instagram:#125688;



//Media Breakpoints
$media-mobile-min:320px;
$media-mobile-small: 360px;
$media-mobile-med: 375px;
$media-mobile-sm-max:480px;
$media-mobile-max: 767px; //Mobiles (Landscape)
$media-tablet-min: 768px;
$media-tablet-max: 992px; //Tablets, Ipads
$media-desktop-min: 1025px;
$media-desktop-max: 1280px; //Laptops, Desktops
$media-lg-desktop-min: 1281px;



$icomoon-font-family: "BLUD" !default;
$icomoon-font-path: "../fonts" !default;

$bludissued: "\e924";
$bluddonation: "\e925";
$bludstart-fill: "\e922";
$bludstar: "\e923";
$bluddots__menu: "\e921";
$bludrefresh: "\e920";
$bludquestionmark: "\e91f";
$bludpin: "\e91e";
$bludfilter: "\e91d";
$bludprint: "\e91c";
$bludcalender: "\e91b";
$bludsort-arrow: "\e918";
$bludpower-off: "\e919";
$bluduser: "\e91a";
$bluddelete: "\e917";
$bludlabresults: "\e916";
$bludedit: "\e903";
$bludclose: "\e904";
$bludcamps: "\e905";
$bludageing: "\e906";
$bludtransfer: "\e907";
$bluddonar: "\e908";
$bludaccept: "\e909";
$bludshare: "\e90a";
$bludplus: "\e90b";
$bluddownload: "\e90c";
$bludbig-arrow: "\e90d";
$bludnotification: "\e90e";
$bludsearch: "\e90f";
$bludtickmark: "\e910";
$bludsetting: "\e911";
$bludchat: "\e912";
$bludblood-bag: "\e913";
$bludpeople: "\e914";
$bluddashboard: "\e915";
$bludclose-eye: "\e902";
$bluddot: "\e901";
$bludeye: "\e900";

