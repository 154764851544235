.bd-main {
  background-color: #ffffff !important;
  /* height: calc(100vh - 8vh); */
}

.hide {
  display: none;
  /* background: crimson; */
}
.text-right {
  text-align: right;
  color: #af0c0c;
  font-size: 17px;
  cursor: pointer;
}

.bd-modal-wrap {
  font-family: 'Source Sans Pro', sans-serif;
  /* color:#73AF00 ; */
}
.mt-4 {
  margin-top: 4 !important;
}

.loader {
  border: 3px solid #ffffff;
  /* Light grey */
  border-top: 3px solid #af0c0c;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

input,
textarea {
  font-family: 'Source Sans Pro', sans-serif;
}

.bd-logo {
  background-image: url(./images/logo.png) !important;
  background-color: transparent !important;
  background-size: 100% !important;
}
.bd-admin {
  background-image: url(./images/Ainsti.png) !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  height: 40px;
  width: 40px;
}
.bd-sub {
  background-image: url(./images/subcription.png) !important;
  /* background-color: transparent !important; */
  background-repeat: no-repeat !important;
  height: 40px;
  width: 40px;
}
.bd-trans {
  background-image: url(./images/trans.png) !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  height: 40px;
  width: 40px;
}
.bd-price {
  background-image: url(./images/price.png) !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  height: 40px;
  width: 40px;
}

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icons/icons.eot?7sqtr2');
  src: url('fonts/icons/icons.eot?7sqtr2#iefix') format('embedded-opentype'),
    url('fonts/icons/icons.ttf?7sqtr2') format('truetype'),
    url('fonts/icons/icons.woff?7sqtr2') format('woff'),
    url('fonts/icons/icons.svg?7sqtr2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chart-wrapper {
  background-color: #ffffff;
}

.custom-tooltip {
  background-color: #7f7f7f;
  border-radius: 40px;
  padding: 5px;
  color: #ffffff;
}

.inventoryChart {
  /* position: absolute; */
  width: 445px;
  height: 253px;
  float: right;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.donationChart {
  width: 445px;
  height: 253px;
  float: right;
  float: right;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}

.divTable {
  display: table;
  width: auto;
}

.divRow {
  display: table-row;
  width: auto;
  height: 25px;
}
.bold {
  font-weight: bold;
}
.donationIndexSpacing {
  padding: 13px 0px 0px 18px;
}

.inventoryIndexSpacing {
  /* padding: 50px 0px 0px 0px; */
  margin: 0 auto;
}

.divCell {
  float: left;
  display: table-column;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.divRow .divCell:nth-child(even) {
  width: 130px;
}

.bullet {
  width: 9px;
  height: 9px;
  border: 2px solid;
  border-radius: 31px;
  border-color: #af0c0c;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.plasma {
  border-color: #ffc130;
}

.blood {
  border-color: #2ed47a;
}

.platelets {
  border-color: #f7685b;
}

.redcell {
  border-color: #44aff1;
}

.recharts-wrapper {
  position: none;
  float: left;
  left: -11px;
  top: 5px;
}

.bd-mod__container {
  padding: 0 !important;
}

.lab_table_header {
  text-align: center;
}

.component-lab {
  font-size: 11px;
}

.bludclose {
  background-color: #af0c0c;
  color: #ffffff;
  width: 25px !important;
  height: 25px !important;
  padding: 7px !important;
  font-size: 11px !important;
  line-height: 11px !important;
}

.bd_addCamp_close {
  color: #000000;
  background-color: #fff;
}

.bd-popup {
  margin: 2% auto 0 auto;
}

.bd-profile.bd-modal-right {
  height: 100%;
}

.bd-profile.bd-modal-right .bd-popup {
  margin: 0 0 0 0;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.pagination {
  cursor: pointer;
  float: right;
  font-size: 14px !important;
  padding-left: 0;
  border-radius: 4px;
  margin: 14px 0px 14px 0px;
}

.pagination > li {
  display: inline;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.cursor_notAllow {
  cursor: not-allowed !important;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.radioLabel {
  padding: 10px;
}

.blockPopupRadioWrapper {
  display: inline-flex;
  margin: auto;
}

.block-reason-dropdown {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  margin-left: 30px;
}

.table-dropdown {
  border: 1px solid rgba(0, 0, 0, 0) !important;
}

.bd-mod__total--wrap label {
  color: rgb(255, 255, 255);
}

.bd-requestBlood {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  color: rgb(42, 107, 244);
  border-radius: 100%;
  border: 1px solid rgb(42, 107, 244);
  margin: 30px auto 30px 20px;
  cursor: pointer;
}

.bd-requestBlood i {
  font-size: 20px;
}

.bd-requestBlood div {
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
}

.line-chart-tooltip {
  background-color: #ffffff;
  visibility: visible !important;
  box-shadow: 0px 1px 4px 1px gray;
  border-radius: 3px;
}

.line-chart-data {
  flex: 1 1;
  padding: 4px 8px 8px 10px;
  font-size: 12px;
}

.data-key {
  width: 50px;
  display: inline-block;
}

.data-value {
  font-weight: 700;
}

.bd-tab__content {
  margin-top: 0 !important;
  height: auto !important;
}

.bd-btn--primary[disabled] {
  cursor: no-drop;
  opacity: 0.7;
}

.db-bg__red {
  background-color: #af0c0c;
}

.db-bg__red .bludclose {
  width: 84px;
  height: 84px;
}

.input-error {
  border: 1px solid #af0c0c;
}

.bd-checkbox {
  cursor: pointer;
}

.bd-radio {
  cursor: pointer;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader-img {
  width: 13%;
  display: block;
  margin: auto;
}

.dashboard-request-popup {
  max-width: 720px;
}

.table-l-cell {
  cursor: pointer;
}
.cell-bold {
  font-weight: bold;
}

.bd-clr-green {
  color: #0d9823 !important;
  font-weight: 600;
}

.bd-clr-red {
  color: #af0c0c !important;
  font-weight: 600;
}
.width-10 {
  width: 10%;
  table-layout: fixed;
}
.width-15 {
  width: 16%;
  table-layout: fixed;
}

.width-20 {
  width: 20%;
  table-layout: fixed;
}
.padding-0 {
  padding: 10px 0 10px 0;
}
.margin-Top-10 {
  margin-top: 10px !important;
}
.bd-attachment__file {
  margin: 15px 0px 0px -5px;
  cursor: pointer;
  min-width: 350px;
}

.attachment-name {
  padding: 2px 5px;
}

.download-img {
  float: left;
}

.download-a {
  display: flex;
}

.bd-inventory-header-custom {
  margin-bottom: 0px;
  margin-top: -20px;
}

.bd-radio input:checked + b:before,
.bd-radio input:checked + b:before {
  border-radius: 6px;
}

.bd-questionnaire {
  position: relative;
  cursor: pointer;
  top: 72px;
  color: #af0c0c;
  font-weight: 600;
  min-width: 129px;
  height: 21px;
}

.recharts-default-legend {
  font-size: 11px !important;
}

.bd-edit {
  color: #af0c0c;
  cursor: pointer;
  float: right;
}

.verifyPoup {
  max-width: 520px !important;
}

.verifyPoup-addinvent2 {
  height: 100% !important;
  position: fixed !important;
}

.autoNameList {
  width: '100%';
  height: '200px';
  border: '1px solid #eee';
  z-index: '100';
  overflow: 'auto';
}

.bd-accept-issue__comment {
  color: #000000 !important;
}

.bd-modal-wrap {
  height: 100% !important;
}

/* inventory screen */
.bd-tbl__body .bd-filterpp__list .bd-filterpp__sub span:hover {
  color: #008948 !important;
}

.react-datepicker__day--selected {
  background-color: #af0c0c !important;
}

.inventory-dd {
  width: 60px;
  z-index: 999;
  margin-left: 10px;
}

.bd-profile__detail--lft {
  min-width: 240px;
}

.bd-tbl__cell--filterpp
  .bd-filterpp__list
  div:last-child
  div:first-child
  .bd-filterpp__sub {
  right: calc(-100% - 21px);
}

.add-patient {
  z-index: 999;
}

.detail-dd {
  /* z-index: 999; */
  min-width: 140px;
}

.modal-inventory {
  width: 100%;
}

.add-patient .bd-popup {
  max-width: 585px;
}

.inventory-transferView {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.inventory-comp {
  width: 200px;
  min-width: 180px;
}

.pd-left {
  padding-left: 10px;
}

.bd_dropdown_select {
  position: relative;
  padding: 3px 20px 3px 0;
  cursor: pointer;
  min-width: 52px;
}

/* Transfer View Dropdown */
.inventory-transferView {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin-left: 30px;
  padding: 10px;
}

.dashboard_tiles_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.inventory-transferView label {
  font-size: 14px;
  color: #979797;
  font-weight: bold;
  padding: 10px;
}

.bd-dorpdown.inventory-comp {
  width: 100%;
}

.bd-profile__detail--lft {
  min-width: 240px;
}

.bd-patient-3 div:first-child {
  margin-right: unset;
  margin-right: 10%;
}

.bd-adddonor-row.bd-patient-3 > div:nth-child(2) {
  width: 60px;
  margin-right: 10%;
}

.bd-patient-3 input:first-child {
  margin-right: 10%;
  width: 100%;
}

.bd_registration_grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: #fff;
  height: auto;
}

.bd_registration_sub_grid {
  display: grid;
  grid-template-columns: 40% 60%;
}

.vertical-bar {
  background-color: #af0c0c;
  height: 100px;
  width: 4px;
  border-radius: 8px;
  margin: 10px auto 10px auto;
}

.donation-process ul {
  font-size: 16px;
}

.donation-process .img-div {
  width: 100px;
  text-align: center;
}

.donation-process .title {
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}

.donation-process ul li {
  line-height: 1.8;
}

.donation-process {
  /* display: flex; */
  align-items: center;
  display: grid;
  grid-template-columns: 100px auto;
}

.bd_color_gray {
  color: #979797;
}

.space-around {
  justify-content: space-around;
}

.bd-registration_main_grid {
  display: grid;
  grid-template-columns: 65% 35%;
  width: 100%;
  background-color: #fff;
  height: calc(100vh - 72px);
}

.bd_login {
  height: unset;
}

.bd_forgetpassword {
  height: 400px !important;
}

.borderRight {
  border-right: 1px solid rgba(151, 151, 151, 0.2);
}

.bd-registration {
  width: 100%;
  max-width: 490px;
  background-color: #fff;
  padding: 40px;
  /* min-height: 493px; */
  border-radius: 8px;
  margin: 30px auto 30px auto;
  border: 1px solid #f2f4f5;
}

.bd_register_checkbox {
  margin: 3px 5px 5px 0;
}

.checkbox-label {
  font-size: 16px;
}

.bd-registration__header {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.bd-registration__content {
  text-align: center;
  margin: 20px 0;
}

.bd-registration__content p {
  margin-bottom: 15px;
  color: #3b4559;
  font-size: 16px !important;
  line-height: 1.5;
  font-weight: 400;
}

.bd-reg-insititution ul {
  font-size: 17px;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bd-reg-insititution ul li {
  padding-bottom: 10px;
  position: relative;
  padding-right: unset !important;
}

.bd-reg-insititution ul li .bd-checkbox {
  /* position: absolute; */
  left: 0;
  top: 2px;
  margin-right: 10px;
}

.bd_hospital_checkbox .bd-checkbox {
  right: 0 !important;
  left: unset !important;
}

.bd-checkbox b,
.bd-radio b {
  border-color: #af0c0c;
}

.bd-setpassword {
  max-width: 1086px;
  padding: 35px;
}

.bd-setpassword .bd-registration__header {
  margin-bottom: 30px;
}

.bd-setpass {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.bd-setpass__lft {
  flex: 1;
  padding-right: unset;
  position: relative;
}

.bd-setpass__lft:after {
  content: unset;
  position: unset;
  top: unset;
  right: unset;
  width: unset;
  height: unset;
  background-color: unset;
}

.bd-setpass__rgt {
  flex: 1;
  padding-left: unset;
}

.bd-setpass__rgt ul {
  font-size: 15px;
}

.bd-setpass__rgt ul li {
  padding-bottom: 20px;
}

.bd-forgot-pass .bd-forgot-pass__cont {
  font-size: 15px;
  color: #979797;
  padding-left: 30px;
}

.bd-forgot-pass .bd-forgot-pass__cont li {
  padding-bottom: 20px;
  position: relative;
}

.bd-forgot-pass .bd-forgot-pass__cont li:before {
  content: '';
  font-family: 'BLUD';
  font-size: 5px;
  position: absolute;
  left: -15px;
  top: 0;
  color: #222326;
}

.bd-forgot-pass .bd-invalid__email input[type='text'] {
  border-bottom-color: #af0c0c;
}

.bd-otp p {
  margin-bottom: 20px;
  color: #979797;
}

.bd-otp__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.bd-otp__box input {
  width: 16.66%;
  margin: 0 5px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}

.bd-otp__box input:first-child {
  margin-left: 0;
}

.bd-otp__box input:lastt-child {
  margin-right: 0;
}

.bd-otp__box input[type='text'].active {
  border-bottom-color: #af0c0c;
}

.bd-form .bd-registration__header {
  padding-top: 15px;
}

.bd-form__row {
  margin-bottom: 20px;
  position: relative;
}

.bd-form__row--resendotp {
  text-align: center;
}

.bd-form__row--pass input {
  padding-right: 20px !important;
}

.bd-form__row--pass .bd-eyeIcon {
  position: absolute;
  right: 0;
  bottom: 11px;
  cursor: pointer;
}

.bd-form__row--pass .bd-eyeIcon:before {
  content: '';
  font-family: 'BLUD';
}

.bd-form__row--pass .bd-eyeIcon.bd-show:before {
  content: '';
}

.bd-form__row--forgotpass {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

.bd-form__row--forgotpass .bd-incorrect__msg {
  flex: 1;
}

.bd-form__row--setpass {
  text-align: center;
  margin: 40px 0;
}

.bd-form__row--setpass .bd-btn {
  min-width: 240px;
}

.bd-form__row label {
  color: #979797;
  display: block;
  font-size: 13px;
  font-weight: 600;
}

.bd-form__row--signup {
  text-align: center;
  font-size: 15px;
}

.bd-form__row--signin {
  margin: 40px 0;
  text-align: center;
}

.bd-form__row--signin .bd-btn {
  min-width: 280px;
}

.bd-form__row--register {
  text-align: center;
}

.bd-form__row--register .bd-btn {
  min-width: 280px;
  margin-top: 20px;
}

.w-100 {
  width: 100% !important;
}

input[type='date']:not(:valid):before {
  content: attr(placeholder);
}

.bd_star {
  font-size: 18px;
  font-weight: bolder;
  margin-right: 5px;
}
.bd-pointer {
  cursor: pointer;
}
.bd_address {
  width: 100%;
  height: 200px;
  border: 1px solid #eee;
  z-index: 100;
  overflow: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.bd_address > div {
  padding: 10px;
}

.bd_address hr {
  border-color: rgba(151, 151, 151, 0.2);
  margin-top: 10px;
}

.bd-hospital .label {
  font-weight: 700;
}

.bd-registration {
  width: 100%;
  max-width: 490px;
}

.bg-white {
  background-color: #fff;
}

/* media query Login starts */
@media only screen and (max-width: 1700px) {
  .bd_main_img {
    width: 85% !important;
    height: 660px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .bd_main_img {
    height: auto !important;
    width: 100%;
  }

  .bd_login {
    height: calc(100vh - 72px);
  }
}

@media only screen and (max-width: 1200px) {
  .bd_login {
    grid-template-columns: 60% 40%;
  }

  .bd-main .center {
    top: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .bd_login {
    grid-template-columns: 55% 45%;
  }
}

@media only screen and (max-width: 900px) {
  .bd_login {
    height: auto;
  }

  .bd_login div:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .bd_login div:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    order: 1;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .bd-main {
    min-height: unset;
  }

  .bd-main .center {
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
  }
}

@media only screen and (max-width: 600px) {
  .bd-auth-img .label {
    font-size: 13px !important;
  }

  .bd-auth-img .counts {
    font-size: 18px !important;
  }

  .bd_count_images {
    height: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .bd-auth-img .label {
    font-size: 10px !important;
  }

  .bd-auth-img .counts {
    font-size: 14px !important;
  }

  .bd_count_images {
    height: 20px;
  }

  .bd-form__row--signin .bd-btn {
    min-width: 200px;
  }

  .bd-auth-header {
    justify-content: space-evenly;
  }
}

.bd-auth-header {
  justify-content: space-between;
  border-bottom: 1px solid #e5e9f2;
  box-shadow: unset !important;
}

.bd_login {
  display: grid;
  /* height: 100vh; */
  height: calc(100vh - 8vh);
  grid-template-columns: 68% 30%;
}

.bd_login,
.bd_reg_bg .bd-main {
  background-color: #fff !important;
}

.text-center {
  text-align: center;
}

.bd_header_div {
  padding: 12px 25px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.d-flex {
  display: -webkit-flex;
  display: flex;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.align-items-center {
  -webkit-align-items: center;
  align-items: center;
}

.bd-auth-img .counts {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.7;
}

.bd_count_images,
.bd_hospital_counts {
  margin-right: 7px;
}

.bd-auth-img .label {
  font-weight: 700;
  font-size: 17px;
  line-height: 1.6;
  text-align: left;
  margin-top: 5px;
  font-family: 'Source Sans Pro', sans-serif;
}

.bd_count_hospital {
  margin: 29px 10px 0px auto;
  max-width: 90%;
}

.bd-hospital .counts {
  font-size: 23px;
  font-weight: 700;
  color: #af0c0c;
  line-height: 1.5;
}

.bd-hospital .label {
  font-weight: 700;
}

.bd_counts_hr {
  margin: 10px 0;
  border: 1px solid hsla(0, 0%, 59.2%, 0.2);
}

.position-relative {
  position: relative;
}

.bd_steps {
  margin: 30px auto auto;
  width: 65%;
}

/* media query Login ends */

.file-upload-icon {
  font-size: 25px;
  margin-bottom: 5px;
}

.justify-content-between {
  justify-content: space-between;
}

.bd-changepass-header {
  text-align: left;
  font-size: 16px;
  margin-bottom: 15px !important;
  margin-top: 20px;
}

.bd_reg_bg {
  height: 100vh;
}

.bd-volume-input {
  width: auto !important;
  padding: 0px 43px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 1px solid rgba(151, 151, 151, 0) !important;
  font-size: 15px !important;
  text-align: end !important;
}

.volume-error {
  color: #af0c0c;
  margin: 15px 10px;
}

.bd-process {
  width: 114px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 1px solid rgba(151, 151, 151, 0) !important;
  font-size: 15px !important;
}

.process-popup-scroll {
  /* width: 674px; */
  width: 100%;
  height: 200px;
  overflow: auto;
}
.Tabs-scroll {
  width: 100%;
  height: 200px;
  overflow: auto;
}

.bd-issue-process {
  height: 350px !important;
}

.additives {
  margin-left: 44px;
  margin-top: 16px;
}

.bd-issue-bagId {
  max-width: 92px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bd-issue-location {
  width: 190px !important;
}

.volume-process {
  min-width: 90px;
}

.modal_scroll {
  min-height: 545px;
}

.additives {
  margin-left: 67px !important;
}

.preservatives {
  margin-left: 42px !important;
}

.approxVol {
  margin-left: 7px !important;
}

.bd-btn__wrap {
  padding: 0px !important;
}

.bd-accept-issue .bd-btn__wrap {
  margin: 0px !important;
}

.no-data-found-img {
  width: 140px;
}

.table-scroll {
  min-height: 566px;
  max-height: 760px;
  overflow: auto;
}

.bg-dd {
  margin-left: 30px;
}

.success-title {
  padding: 18px;
}

.fail-title {
  padding: 18px;
}

.onHoveeerr:hover {
  background-color: #e38e8e;
}

.bd-api-dropdown {
  z-index: 100;
  width: 98%;
  height: 170px;
  border: 1px solid #eee;
  overflow: auto;
}

.noData_center {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.form-error {
  border: 0;
  border-bottom: 1px solid #f1ee17 !important;
  padding: 5px 5px 1px 5px;
}

.padding-25 {
  padding: 25px;
}

.bd-camps__request--wrap-hover:hover {
  background-color: rgba(151, 151, 151, 0.1);
  cursor: pointer;
}

/* 
.bd-camps__box {
width:29.3%;
} */

.bd-camps__details {
  margin: unset;
  padding: 10px;
}

.bd-camps__request--wrap {
  border: unset;
  margin: unset;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: unset;
}

.bd-camps__request_hr {
  border-color: rgba(151, 151, 151, 0.1);
  /* margin: 10px 0 0 0; */
}

.bd-camps__request--hd {
  margin-bottom: 15px;
  padding: 0 15px 0 15px;
  margin-top: 15px;
}

.bd-camps__request {
  padding: unset;
}

.bd-camps__user {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li {
  font-size: 14px !important;
}
.mp_remove {
  padding: unset !important;
  margin: unset !important;
}

.ml-6 {
  margin-left: 6px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.bd-addimages {
  margin-bottom: 5px;
}

.bd-camps__request--wrap .bd-btn__wrap {
  margin-bottom: 15px;
  margin-top: 15px;
}

.bd-camps__details-com span:first-child {
  text-align: right;
}

.bd-adddonor__date {
  justify-content: flex-start;
  max-height: 37px;
  margin-bottom: 20px;
}

.bd-adddonor__date > div:first-child {
  margin-right: 5%;
}

.bd-adddonor__date > div:nth-child(2) {
  margin-right: 5%;
}

.bd-adddonor__10 div:first-child {
  max-width: 100%;
  margin-right: unset;
}

.bd-dbd__form select {
  padding: unset;
}

.bd-adddonor__save .bd-btn__wrap {
  margin: 30px 0;
}

.d-block {
  display: block;
}

.bd_createcamp_time {
  margin: unset;
  width: 40%;
  /* width: 54%; */
}

.bd-bg-color {
  background-color: #fff;
  /* min-height: 100vh; */
}

.bd_main_img {
  width: 95%;
  height: 730px;
}

.bd-auth-img .counts {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.7;
  color: #af0c0c;
  font-family: 'Source Sans Pro', sans-serif;
}

.align-items-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.justify-content-center {
  justify-content: center;
}

.space-around {
  justify-content: space-around;
}

.cursor-pointer {
  cursor: pointer;
}

.bd-camps__dots {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  padding: 7px;
  display: none;
  cursor: pointer;
}

.bd-camps__box:hover .bd-camps__dots {
  display: block;
}

.bd_d_flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}
.space-btw {
  justify-content: space-between !important;
}

.bd-form__row input[type='date'],
.bd-adddonor input[type='date'],
.bd-form__row input[type='time'],
.bd-adddonor input[type='time'] {
  width: 100%;
  padding: 8px 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  font-size: 15px;
  min-width: 192px;
  background-color: #fff;
}

.bd_add_history input[type='text'],
.bd_add_history input[type='date'] {
  border: 0;
}

.bd-adddonor input[type='date'] {
  min-width: unset;
}

.bd-adddonor-questions {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 30px;
}

.bd-adddonor-questions-set1 {
  margin: 15px 0px 0px 0;
}

.bd-register-questions-set {
  margin: 10px 0px 10px 0;
}

.bd-adddonor-questions .radio-btns {
  width: 100px;
}

.bd-adddonor-questions .radio-btns label {
  margin-left: 5px;
}

.bd_camps_list {
  height: calc(100vh - 200px);
  overflow: auto;
}

.bd-form__row input[type='text'],
.bd-form__row input[type='password'],
.bd-form__row input[type='number'],
.bd-adddonor input[type='text'],
.bd-adddonor input[type='number'],
input[type='number'] {
  width: 100%;
  padding: unset !important;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  font-size: 15px;
  height: 30px;
  text-transform: capitalize;
}

.bd-auth-header {
  justify-content: space-between;
  border-bottom: 1px solid #e5e9f2;
  box-shadow: unset !important;
}

.bd-createcamp .bd-fail .bd-popup {
  top: 50%;
  left: 50%;
  height: 330px;
  width: 350px;
  transform: translate(-50%, -50%);
}
.bd-campDonorForm .bd-fail .bd-popup {
  top: 50%;
  left: 50%;
  height: 330px;
  width: 350px;
  transform: translate(-50%, -50%);
}

.bd-addimages .bd-add-box {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
}

.bd-addimages .bd-add-other {
  position: relative;
  background-position: center;
  background-size: contain;
  height: 100%;
}

.bd-addimages .bd-add.active {
  cursor: default;
  width: 100%;
}

.bd-addimages .bd-add.active .bludclose {
  display: block;
}

.bd-addimages .bd-add.active .bludplus {
  display: none;
}

.bd-addimages .bd-add-first {
  margin-left: 0;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bd-add {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bd-dbd__form section[class^='bd-sec__'] {
  border-bottom: 1px solid #f2f0f0;
  padding: 10px 0;
}

.fw-700 {
  font-weight: 700;
}

.bd_createcamp_time input {
  width: 90px !important;
  min-width: 90px !important;
}

.bd_error {
  color: #af0c0c;
}

.bd_createcamp_time {
  /* margin-left: 13%; */
}

.bd-dbd__form select {
  border: 0;
  border-bottom: 1px solid #f2f0f0;
  background-color: #fff;
  height: 37px;
}

.bd-camps__img--rgt .bd-camps__option {
  visibility: visible;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type=number] {
  border: 0;
  border-bottom: 1px solid #F2F0F0;
  padding: 5px 5px 1px 5px;
} */
input[type='number']:focus {
  outline: none;
}

.form-inline-error {
  color: #af0c0c;
  margin: 18px 33px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.bd-adddonor__1 .bd-adddonor-div {
  width: 50%;
}

.bd-adddonor__1 .bd-adddonor-div:first-child,
.bd-adddonor__10 .bd-adddonor-div:first-child {
  margin-right: 10%;
}

.bd-add-images-title {
  margin-top: 10px;
  color: #979797;
}

.bd-addcamp-from {
  padding-right: 15px;
}

.bd-adddonor-row.bd-patient_3 input:nth-child(2) {
  width: 150px;
  margin-left: 5%;
  margin-right: 5%;
}

.bd-adddonor__5 div:first-child,
.bd-adddonor__5 div:nth-child(2) {
  width: 40%;
}

.bd-adddonor__5 div:first-child {
  width: 40%;
}

.bd-adddonor__5 div:nth-child(2) {
  margin-left: 10%;
}

.bd-adddonor__5 div:first-child input,
.bd-adddonor__5 div:nth-child(2) input {
  width: 100%;
}

.bd-adddonor .css-1262e1v-ValueContainer {
  width: 150px;
  height: 40px;
}

.css-174pkqr-placeholder {
  font-size: 15px !important;
}

.bd-sec__11 textarea {
  font-size: 16px;
}

.bd-adddonor .css-c8odyh-control {
  border-bottom: 1px solid #f2f0f0;
  border-radius: unset;
  height: 40px;
  font-size: 16px;
}

.bd-add-patient-gender .css-2b097c-container {
  width: 100%;
}

.bd-tbl__body .bd-tbl__cell--filter:last-child .sub_sub_list {
  left: calc(-100% - 19px);
}

.bd-tbl__body .bd-tbl__cell--filter:nth-last-child(2) .bd-filterpp__sub {
  left: calc(-100% - 42px);
  right: auto;
}

.bd-tbl__body .bd-tbl__cell--filter:nth-last-child(2) .sub_sub_list {
  left: calc(-100% - 19px);
}

.bd-tbl__body .bd-tbl__cell--filter:nth-last-child(3) .bd-filterpp__sub {
  left: calc(-100% - 42px);
  right: auto;
}

.bd-tbl__body .bd-tbl__cell--filter:nth-last-child(3) .sub_sub_list {
  left: calc(-100% - 19px);
}

.bd-tbl__body
  .bd-tbl__cell--filter:last-child
  .bd-tbl__cell--filterpp
  .bd-filterpp__list
  div:last-child
  div:first-child
  .bd-filterpp__sub {
  right: auto;
}

.bd-tbl__body
  .bd-tbl__cell--filter:nth-last-child(2)
  .bd-tbl__cell--filterpp
  .bd-filterpp__list
  div:last-child
  div:first-child
  .bd-filterpp__sub {
  right: auto;
}

.align-center-no-data {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.disabledRow {
  background-color: #eaeaea;
}

.verifyWrapper {
  box-shadow: 0px 0px 4px rgb(34 35 38 / 24%);
  border-radius: 8px;
  padding: 30px 20px;
  margin: 3px;
}

.mr-10 {
  margin-right: 10px;
}
.pd-lr {
  padding: 0 25px;
}

.bd-acimg {
  cursor: pointer;
}
.width-50 {
  width: 50%;
}
.width-100 {
  width: 100% !important;
}

.visibility-visible {
  visibility: visible !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.userProfile {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  text-align: center;
  border: 1px solid gray;
  display: flex;
}

.monthly-infra input[type='number'] {
  border: 0;
}

.bd-rgt__lab-equipments {
  min-height: 0 !important;
  margin-top: 5px !important;
}

.default-width {
  width: 90px;
}

.cursor-pointer {
  cursor: pointer;
}

.wrapper-404 {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-color: #ffffff;
}

.page-not-found {
  display: flex;
  width: 75%;
  height: 100%;
  background: url(./icon/404.svg) no-repeat center center;
  background-size: cover;
}

.bd-volume {
  margin: 30px 0px !important;
}

.login-overlay {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 250px;
  width: 250px;
  z-index: 1;
  /* display: flex; */
}

.login-overlay > div {
  padding: 20px;
  font-size: 14px;
}

.login-overlay p:nth-child(3) {
  margin-bottom: 10px;
}

.playstore-img {
  width: 100px;
  height: 30px;
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
  opacity: 0.5;
}

.bd-checkbox-label {
  padding-left: 25px;
  text-align: left;
}

.bd-btn {
  font-size: 15px;
}

.error-msg-font {
  color: #af0c0c !important;
  text-align: center !important;
  font-size: 18px !important;
}

.success-msg-font {
  color: #008753 !important;
  text-align: center !important;
  font-size: 18px !important;
}

.logo_sepration {
  display: flex;
  justify-content: space-between;
  /* margin: auto; */
  /* width: 95%; */
}

.bd_text_content {
  font-size: 16px;
  margin-bottom: 20px;
}

.opacitynull {
  opacity: 1 !important;
}

.bd_box {
  /* text-align: left; */
  /* margin-top: 20px; */
}

.create-new-btn {
  color: #af0c0c;
  cursor: pointer;
  text-align: end;
}

.field-wrapper {
  padding: 25px;
}

.field-wrapper input {
  outline: 0;
  border-bottom: 1px solid #f2f0f0;
}

.bd_align_center {
  text-align: center;
}

.lat-long {
  padding: 8px 0 8px 20px;
}

.icon-latlong-icon .path1:before {
  content: '\e903';
  color: rgb(175, 12, 12);
}

.icon-latlong-icon .path2:before {
  content: '\e904';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-latlong-icon .path3:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-clip-icon:before {
  content: '\e901';
  color: #af0c0c;
}

.icon-bludReceipt:before {
  content: '\e900';
  color: #59493b;
}

.icon-admin:before {
  content: '\e91f';
  color: #59493b;
}

.icon-donation-2-1:before {
  content: '\e902';
  color: #fff;
}

.bd-list__content--txt {
  width: unset;
  /* margin-right: 10%; */
}

/* .bd-list__content div:nth-child(1){
  margin-right: 30px;
} */

.bd-new-list__content {
  display: flex;
  /* justify-content: space-around */
}

.bd-new-list__content .bd-list__content--txt:nth-child(1) {
  margin-right: 30px;
}

.general-equipments {
  min-width: 425px;
  max-width: 440px;
}

.bd-new-secondary-list__content {
  display: flex !important;
  font-size: 16px;
}

.bd-new-secondary-list__content-general {
  justify-content: space-between;
  max-width: 550px;
}

.bd-list__rgt--row span:nth-child(1) {
  margin-right: 25px;
}

.bd-list__radio-settings div:nth-child(1) {
  margin-right: 25px;
}

.bd-list-rgt-settings {
  /* right:-50%; */
  top: -30px;
}

.bd-step__body {
  min-height: 500px;
  padding-top: unset;
}

.setting-div {
  min-width: 50%;
}

.lab-img {
  align-self: center;
  text-align: center;
}

.bd-rgt__lab-equipments:before {
  background-color: transparent;
}

.bd-step-padding {
  padding: 0 40px 0 40px;
}

.bd-pd-10 {
  padding: 5px 10px;
}

.bd-rgt__lab-equipments {
  width: 50%;
}

.bd-add__labs--btn {
  margin-top: 15px;
  text-align: right;
  color: #af0c0c;
  cursor: pointer;
  font-family: 600;
}

.bd-add-cancel__labs--btn {
  display: flex;
  justify-content: space-evenly;
}

.bd-add__labs--btn i {
  font-size: 10px;
  margin-right: 5px;
}

.setting-questions-grid {
  display: grid;
  grid-template-columns: 40% auto;
}

@media only screen and (max-width: 1860px) {
  .setting-questions-grid {
    grid-template-columns: 44% auto;
  }

  .setting-questions-grid-main {
    grid-template-columns: 45.3% auto !important;
  }
}

@media only screen and (max-width: 1640px) {
  .setting-questions-grid {
    grid-template-columns: 50% auto;
  }

  .setting-questions-grid-main {
    grid-template-columns: 51.1% auto !important;
  }
}

@media only screen and (max-width: 1400px) {
  .setting-questions-grid {
    grid-template-columns: 55% auto;
  }

  .setting-questions-grid-main {
    grid-template-columns: 56.3% auto !important;
  }
}

@media only screen and (max-width: 1300px) {
  .setting-questions-grid {
    grid-template-columns: 60% auto;
  }

  .setting-div input[type='text'] {
    width: 80px;
  }

  .setting-questions-grid-main {
    grid-template-columns: 61.3% auto !important;
  }
}

@media only screen and (max-width: 1225px) {
  .setting-questions-grid,
  .setting-questions-grid-main {
    display: block !important;
  }

  .bd-list__rgt--row {
    margin-top: 10px;
  }
}

.general-equipments-infra {
  width: 200px;
}

.infra-input-width input[type='text'] {
  width: max-content;
}

.margin-unset {
  margin: unset;
}

.bd-timing-tbl__row {
  margin: auto;
  max-width: 318px;
  justify-content: flex-end !important;
}

.bd-step__head {
  font-size: 20px;
  margin-bottom: 10px;
}
.font-weight {
  font-weight: 600;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 20px;
}
.mt-20 {
  margin-top: 1.5rem;
}
.mt-30 {
  margin-top: 2rem;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb-unset {
  margin-bottom: unset !important;
}
.pt-unset {
  padding-top: unset !important;
}
.pt-20 {
  padding-top: 20px;
}

.bd-loh__date {
  width: 65px;
  margin-right: 15px;
}

.bd-timing-tbl {
  width: unset;
}

.holiday-list {
  padding-left: 5%;
  max-width: 600px;
}

.setting-questions-grid-main {
  display: grid;
  grid-template-columns: 41.3% auto;
}

.setting-question-general-grid {
  display: flex;
  align-items: center;
}

.bd-list__rgt--row input[type='text'],
.bd-list__rgt--row input[type='time'] {
  max-width: max-content;
  font-size: 16px;
}

.bd-timing-tbl__row input[type='text'] {
  font-size: 16px;
  max-width: 100px;
}

.bd-addinvent__cont .bd-btn {
  justify-content: center;
}

ul.bd-list__primary li {
  padding: 10px 0;
}

/* 
@media only screen and (max-width: 1620px) {
  .holiday-list{
    grid-template-columns: 60% auto;
  }
  
 
} */
ul.bd-list__primary li {
  padding: 10px 0;
}

.paddingTop_3 {
  padding-top: 3px;
}

.dd-zindex {
  z-index: 9;
}

.donation-filter {
  bottom: 14px;
}

.drawer-active {
  color: #af0c0c;
  cursor: pointer;
}

.bd-dashboard__header.bd-header__search {
  margin-bottom: unset;
}

.bd-step-padding.bd-step__body
  > div
  > ul
  > li:nth-child(2)
  > div
  > div.bd-list__rgt--row.infra-input-width
  > span:nth-child(1)
  > input[type='text'],
.bd-step-padding.bd-step__body
  > div
  > ul
  > li:nth-child(2)
  > div
  > div.bd-list__rgt--row.infra-input-width
  > span:nth-child(2)
  > input[type='text'] {
  width: 100px;
}

.bd-list__radio {
  margin-right: 15px;
}

.bd-red-box-shadow {
  box-shadow: 0px 0px 4px rgb(175 12 12 / 48%);
  border-radius: 8px;
}

.bd-login {
  /* border: 1px solid #F2F4F5; */
  box-shadow: 0 0 4px rgb(175 12 12 / 48%);
  border-radius: 8px;
  padding: 40px;
}

.bd_login .bd-registration {
  padding: unset;
  border: unset;
}

.bd_registration_sub_grid.borderRight > div.bd-registration {
  border: unset;
}

.bd-container-fluid
  > div.bd_registration_sub_grid.borderRight
  > div.bd-registration {
  margin: auto;
  padding: 30px;
}

.bd-dashboard-counts {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  grid-gap: 1.5rem;
}

.bd-dashboard-counts > div {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  padding: 14px;
  height: 80px;
  border-radius: 4px;
}

.bd-count-circle {
  background-color: #af0c0c;
  border-radius: 100%;
  height: 45px;
  width: 45px;
  position: relative;
}

.bd-count-label {
  color: rgba(116, 120, 141, 1);
  margin-bottom: 10px;
}

.bd-count-value {
  color: rgba(73, 80, 87, 1);
  font-weight: 600;
  font-size: 20px;
}

.bd-tab__content .bd-request__share {
  margin-top: unset;
}

.bd-count-img {
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bd-dashboard-bottom-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
}

.bd-tab-new div.active {
  background-color: #af0c0c !important;
  color: #fff !important;
}

.bd-tab-new {
  width: max-content;
  background-color: #eff2f7;
  color: rgba(73, 80, 87, 1);
  padding: unset;
  border-radius: 4px;
}

.bd-tab-new div {
  border-radius: 4px;
}

.bd-tab__main {
  margin: 1.5rem 0;
  padding: 1rem;
  width: 100%;
  height: 31.7vh;
  /* height: max-content; */
}

.view-all {
  color: rgba(175, 12, 12, 1);
  font-weight: 400;
  float: right;
  cursor: pointer;
}

.bd-tbl__theme1-new {
  margin-top: 1.5rem 1rem 1.5rem 1rem;
}

.bd-mod {
  margin-bottom: unset;
}

.bd-mod__head {
  padding: 15px;
}

.recharts-wrapper {
  top: 5px;
}
.m-unset {
  margin: unset;
}
.bd-dashboard-bottom-grid
  > div:nth-child(2)
  > div
  > div.bd-mod__container
  > div {
  top: -5px;
}

div.bd-tab__content > div > div > div {
  margin: unset;
  /* margin-top: 1rem; */
  width: 100%;
  /* max-height: 313px; */
}

.legends-invetory {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
}

.bd-mod__container {
  position: relative;
}

.legends-invetory .divRow {
  display: flex;
  width: 25%;
  align-items: center;
  padding-left: 3%;
}

.divTable.donationIndexSpacing > div > div:nth-child(2) {
  width: max-content;
}

.a-plus {
  border-color: #ffc130;
}

.a-minus {
  border-color: #d3870a;
}

.b-plus {
  border-color: #66a3ff;
}

.b-minus {
  border-color: #1c53c7;
}

.ab-plus {
  border-color: #73af00;
}

.ab-minus {
  border-color: #1e7d2d;
}

.o-minus {
  border-color: #df5047;
}

.o-plus {
  border-color: #9e2c41;
}

.bd-black {
  background-color: #3d4557;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  padding-top: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.bd-green {
  background-color: #008948 !important;
}
.bd-green-clr {
  color: #008948;
}
.bd-red-clr {
  color: #fc2526;
}

.bd-request-otp {
  display: flex;
  height: 35px;
  cursor: pointer;
  min-width: 120px;
  max-width: 150px;
  align-items: center;
  margin: auto;
}

.bd-request-otp div:nth-child(1) {
  width: 25%;
}

.bd-request-otp div:nth-child(2) {
  width: 75%;
}

.bd-request-otp-stars {
  background-color: rgba(25, 42, 62, 0.8);
  color: #fff;
  height: 100%;
  /* border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; */
  padding-top: 8px;
  border-right: 1px solid rgba(255, 255, 255, 0.102);
  text-align: center;
}
.brder_Radius {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.bd-request-accept-blur {
  background-color: rgba(13, 152, 35, 0.95);
  padding-top: 10px;
}

.bd-request-accept {
  background-color: rgba(13, 152, 35, 1);
}
.bd-request-dul {
  background-color: rgb(100, 177, 112);
}
.bd-request-proceed-blur {
  background-color: #2a6bf4;
  padding-top: 10px;
}

.bd-request-proceed {
  background-color: #2a6bf4;
}

.bd-request-decline-blur {
  background-color: rgba(179, 49, 49, 0.95);
  padding-top: 10px;
}
.bd-decline-accept {
  background-color: rgba(179, 49, 49, 1);
}

.bd-request-decline-blur .bludclose {
  background-color: transparent;
  font-size: 16px !important;
}

.red-circle {
  background-color: rgba(179, 49, 49, 1);
  border: none;
  color: #fff;
  padding: 3px;
  text-align: center;
  text-decoration: none;
  float: right;
  min-width: 24px;
  border-radius: 16px;
  font-size: 12px;
  margin-bottom: 10px;
}

.nav-btn {
  height: 40px;
}

.nav-btn .bd-black {
  padding-top: 10px;
}

.bd-dashboard-bottom-grid
  > div:nth-child(1)
  > div
  > div.bd-mod__container
  > div.recharts-wrapper
  > svg {
  height: 350px;
  margin-top: 20px;
}

/* .bd-dashboard{
  background-color: #E5E5E5;;
} */

.bd-tbl__theme1 .bd-tbl__cell {
  color: rgba(73, 80, 87, 1);
}

.bd-main .bd-menu {
  width: 230px;
}

.bd-tbl__donation .bd-tbl__cell:first-child {
  width: 109px;
}

.bd-tab__content > div > div > div.bd-tbl__head > div > div:nth-child(2) {
  width: 112px;
}

.bd-req-tab .bd-tbl__theme1 .bd-tbl__cell:nth-child(3) {
  width: 44px;
}

div.bd-tab__content .bd-tbl.bd-req-tab .bd-tbl__row .bd-tbl__cell:nth-child(3) {
  width: 44px;
  cursor: pointer;
}

.bd-tab__content
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(3)
  div.bd-tab__main
  > div.bd-tab__content
  .bd-tbl__cell {
  padding: 5px;
}

.bd-tab__content > div > div > div.bd-tbl__head > div > div:nth-child(7) {
  width: 180px;
}

.name-cell {
  width: 200px;
}

.bd-req-tab .bd-request-otp div:nth-child(2) {
  width: 60%;
}

.bd-component {
  max-width: max-content;
}

.bd-camps > div > div > div.bd-tbl__body > div:nth-child(1) > div:nth-child(2),
.bd-camps > div > div > div.bd-tbl__body .bd-tbl__cell {
  font-weight: unset !important;
  max-width: 200px;
  text-align: unset;
}

.bd-camps > div > div > div.bd-tbl__body > div:nth-child(1) > div:nth-child(1) {
  width: 100px;
}

.bd-camps_header {
  font-weight: unset;
}

.bd-camps-title {
  font-weight: 700;
  font-size: 20px;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.mr-20 {
  margin-right: 20px;
}

.bd-dashboard-bottom-grid > div:nth-child(2) > div > div.bd-mod__head > div {
  height: 18px;
}

.bd-menu .bd-logo {
  height: 45px;
}

.bd-header-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 30px;
  justify-content: center;
  align-content: center;
}
.pb-10 {
  padding-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mTB {
  margin: 10px 0 10px 0;
}
.fs-15 {
  font-size: 15px;
}
.border-bottom {
  border-bottom: 0 !important;
}
.bd-camps {
  height: calc(100vh - 140px);
}

.bd-createcamp.bd-modal-right .bd-popup {
  position: absolute;
  margin: 10px 20px 0 10px;
}
.bd-campDonrForm.bd-modal-right .bd-popup {
  position: absolute;
  margin: 10px 20px 0 10px;
}
.bd-lab-criterai .bd-modal-right .bd-popup {
  position: absolute;
  margin: 10px 20px 0 10px;
}
.bd-popup_Lab_Criteria {
  max-width: 40vw !important;
}
.style-case {
  color: #222326;
  font-size: 18px;
  font-weight: 600;
}
.dd-min-w {
  min-width: 130px;
}

.inventory-tab {
  max-height: 250px !important;
  margin-top: 10px !important;
}

.bd-tbl__donation {
  /* max-height: 190px; */
  max-height: 240px;
}

.bd-main__container {
  width: calc(100vw - 230px);
}

@media only screen and (max-width: 1770px) {
  .inventory-chart {
    width: 400px !important;
    left: -58px !important;
  }

  .bloodgroup-chart {
    width: 400px !important;
    left: -67px !important;
  }

  .bd-dashboard-bottom-grid .donation-chart svg {
    width: 400px !important;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(2)
    > div
    > div.bd-mod__container
    > div {
    left: 45px !important;
  }
}

@media only screen and (max-width: 1665px) {
  .inventory-chart {
    width: 350 !important;
    left: -58px !important;
  }

  .bloodgroup-chart {
    width: 350 !important;
    left: -67px !important;
  }

  .bd-dashboard-bottom-grid .donation-chart svg,
  .bd-dashboard-bottom-grid .donation-chart {
    width: 350px !important;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(2)
    > div
    > div.bd-mod__container
    > div {
    left: 25px !important;
  }
}

@media only screen and (max-width: 1530px) {
  .bd-dashboard-bottom-grid
    > div:nth-child(1)
    > div
    > div.bd-mod__container
    > div.recharts-wrapper
    > svg {
    height: 300px;
  }

  .inventory-chart {
    width: 300px !important;
    left: -70px !important;
  }

  .bloodgroup-chart {
    width: 300px !important;
    left: -90px !important;
  }

  .bd-dashboard-bottom-grid .donation-chart svg,
  .bd-dashboard-bottom-grid .donation-chart {
    width: 350px !important;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(2)
    > div
    > div.bd-mod__container
    > div {
    left: 10px !important;
    height: 344px !important;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(3)
    > div
    > div.bd-mod__container
    > div.legends-invetory {
    left: 25px;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(1)
    > div
    > div.bd-mod__container
    > div.recharts-wrapper.inventory-chart
    > svg {
    height: 300px;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(3)
    > div
    > div.bd-mod__container
    > div.recharts-wrapper.bloodgroup-chart
    > svg {
    height: 350px;
  }
}

@media only screen and (max-width: 1440px) {
  .bd-dashboard-bottom-grid {
    grid-template-columns: 1fr 1fr;
  }

  .inventory-chart {
    left: -10px !important;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(2)
    > div
    > div.bd-mod__container
    > div {
    left: 40px !important;
  }

  .bloodgroup-chart {
    left: -22px !important;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(1)
    > div
    > div.bd-mod__container
    > div.recharts-wrapper.inventory-chart
    > svg {
    height: 350px;
  }

  .bd-dashboard-bottom-grid
    > div:nth-child(3)
    > div
    > div.bd-mod__container
    > div.recharts-wrapper.bloodgroup-chart
    > svg {
    height: 400px;
  }

  .bd-dashboard-bottom-grid .donation-chart svg,
  .bd-dashboard-bottom-grid .donation-chart {
    width: 400px !important;
  }
}

.bd-dashboard__rgt.bd-equalcolumn
  > div
  > section.bd-sec__10
  > div:nth-child(4) {
  margin-bottom: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.bd-request-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bd-tbl__body .bd-noLabdata {
  width: 100%;
  min-height: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noLabdata:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/LabResult.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}

.bd-tbl__body .bd-noSampledata {
  width: 100%;
  min-height: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noSampledata:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/blood-sample.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}

.bd-tbl__body .bd-noDonordata {
  width: 100%;
  min-height: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noDonordata:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/Donor.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}

.bd-tbl__body .bd-noInventoryData {
  width: 100%;
  min-height: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noInventoryData:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/heart.png) no-repeat center center;
  background-size: cover;
}

.bd-tbl__body .bd-noRequestData {
  width: 100%;
  min-height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noRequestData:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/RequestTab.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}

.bd-tbl__body .bd-noDonationData {
  width: 100%;
  min-height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noDonationData:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/DonationTab.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}
.bd-tbl__body .bd-noCampData {
  width: 100%;
  min-height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noCampData:before {
  content: '';
  width: 110px;
  height: 110px;
  background: url(../assets/images/camp.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}
.bd-tbl__body .bd-noVerifyData {
  width: 100%;
  min-height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noVerifyData:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/verifyTab.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}
.bd-tbl__body .bd-noChatData {
  width: 100%;
  min-height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  font-weight: 700;
  position: relative;
}

.bd-tbl__body .bd-noChatData:before {
  content: '';
  width: 100px;
  height: 100px;
  background: url(../assets/images/chat.png) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
}
.tileHover:hover {
  /* background: #EF9F9F; */
  transition: all 0.3s ease-in;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 24%);
}
.box-1 {
  text-align: center;
  border-right-style: solid;
  border-right-style: groove;
  /* border-right-color: #979797; */
  border-right-color: #eaeaea;
  border-right-width: 1px;
  padding: 10px;
}
.camp-popup-inside {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.Lab-criteria-inside {
  display: grid;
  grid-template-columns: 50px 4fr 1fr 1fr 1fr;
  margin: 10px 0;
  /* grid-column-gap: 10px; */
}

.camp-header {
  font-size: 24px;
  font-weight: 700;
}
.camp-header-child {
  font-size: 16px;
  font-weight: 600;
}
.text-left {
  text-align: left;
}
.clr-blud {
  color: #af0c0c;
}

.child-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
}

.bd-testArea {
  padding: 0 22px;
}

.bd-textArea-style {
  width: 100%;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
}

.bd-padding-Unset {
  padding: unset !important;
}

.grid-3-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid-col-gap-20 {
  grid-column-gap: 20px;
}

.pd-unset {
  padding: 0 !important;
}

.profile_detail {
  display: grid;
  grid-template-columns: 150px 1fr 1fr 1fr;
  grid-column-gap: 30px;
}
.lable {
  text-align: right;
  color: #979797;
}
.value {
  text-align: left;
}
.ml-5 {
  margin-left: 5px;
}

.col_22 > div {
  margin-bottom: 8px;
}
.instiName {
  font-size: 18px;
  font-weight: bold;
}
.element {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 10px;
}
.bb {
  border-bottom: 1px solid #eff2f7;
}
.profile_img {
  width: 40px;
  height: 40px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  margin-right: 12px;
}
.imgs {
  width: 120px;
  height: 120px;
  margin: auto;
}
.img-star {
  width: 16px;
  height: 18px;
  /* margin: auto; */
}

.bd_grid_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}
path[fill^='#81d4fa'] {
  display: none;
  stroke-width: 0px;
}
