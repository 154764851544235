.bd-dbd__form {
    .bd-radio{
       top: 2px;
    }
    input[type="text"] {
        border: 0;
        border-bottom: 1px solid $gray;
        padding: 5px 5px 1px 5px;
    }

    div[class^="bd-sec__"] {
        @include flexbox;

    }

    section[class^="bd-sec__"] {
        border-bottom: 1px solid $gray;
        padding: 15px 0;
    }
}

.bd-sec {
    &__1 {
        &--1 {
            @include flexbox;
            width: 100%;

            >span:first-child {
                flex: 1;

            }

            span:not(:first-child) {
                margin-left: 30px;
            }

            b {
                margin-left: 5px;
                color: $accept;
            }
        }

    }

    &__2 {
        &--2 {
            div {

                flex: 1;
                @include flexbox;
                padding-right: 20px;
                &:last-child {
                    padding-right: 0;
                }

                span {
                    white-space: nowrap;
                    margin-right: 5px;
                }

                input {
                    width: 100%;
                    margin-right: 5px;
                }
            }
        }

        >.bd-sec__2--2:first-child {
            justify-content: center;
            margin-bottom: 15px;

            div {
                flex: none;
                font-weight: $medium_font-weight;
            }
        }

        div[class^="bd-sec__2--"] {
            margin-bottom: 10px;
        }
    }

    &__3 {
        @include flexbox;
        width: 100%;

        &--1 {
            flex: 1; 
        }
        &--2{
            div{
                margin-left: 15px;
            }
        }
    }

    &__4 {
        &--1 {
            div {
                flex: 1;
                @include flexbox;
                padding-right: 20px;

                &:last-child {
                    padding-right: 0;
                }

                span {
                    white-space: nowrap;
                    margin-right: 5px;
                }

                input {
                    width: 100%;
                }
            }
        }

        &--2 {
            flex-wrap: wrap;

            div:not(.bd-sec__4--head) {
                flex: 1;

                @include flexbox;
                padding-right: 20px;

                &:last-child {
                    padding-right: 0;
                }

                span {
                    white-space: nowrap;
                    margin-right: 5px;
                }

                input {
                    width: 100%;
                }

            }
        }

        &--head {
            width: 100%;
            font-weight: $medium_font-weight;
            margin-top: 15px;

            +div {
                margin-right: 15px;
            }
        }

        div[class^="bd-sec__4--"] {
            margin-bottom: 10px;
        }
    }



    &__7 {
        div[class^="bd-sec__7--"] {
            margin-bottom: 10px;

            .bd-dorpdown {
                flex: 1;
                margin-left: 15px;
            }

            .bd-at {
                margin: 0 10px;
            }
        }

        &--2 {
            &-1 {
                flex: 2;
            }
            &-2 {
                flex: 0.5;
                input{
                    width: 100%;
                }
            }
        }

        &--3 {
            input {
                flex: 1;
                margin-left: 10px;
            }
        }
    }

    &__8 {
        @include flexbox;
        flex-direction: column;
        border: none !important;

        div[class^="bd-sec__8--"] {
            margin-bottom: 15px;

            div {
                flex: 1;
            }
        }

    }

    &__9 {
        @include flexbox;

        div {
            flex: 1;
        }
    }

    &__6 {
        div[class^="bd-sec__6--"] {
            margin-bottom: 10px;

            div {
                flex: 1;

                @include flexbox;
                padding-right: 20px;

                &:last-child {
                    padding-right: 0;
                }

                span {
                    white-space: nowrap;
                    margin-right: 5px;
                }

                input {
                    width: 100%;
                }
            }
        }

        &--2 {
            input {
                margin-right: 5px;
            }
        }

        &--head {
            font-weight: $medium_font-weight;
            font-size: 18px;
            margin-bottom: 15px !important;
        }
    }

    &__10 {
        ul {
            font-size: 15px;
            padding-left: 30px;

            .bd-checkbox {
                position: absolute;
                left: -25px;
                top: 2px;
            }

            li {
                position: relative;
                margin-bottom: 10px;
            }
        }

        border: none !important;
    }

    &__11 {
        border: none !important;

        ul {
            font-size: 15px;
            margin-left: 20px;
        }

        li {
            @include flexbox;
            margin-bottom: 15px;
        }

        label {
            flex: 1;
        }

        .bd-radio__wrap {
            @include flexbox;

            div {
                margin-left: 15px;
            }
        }

        textarea {
            width: 100%;
            min-height: 100px;
            padding: 10px;
            border-color: $gray;
        }
    }

    &__12 {
        border: none !important;
        &--1{
            padding-left: 30px;
            position: relative;
            .bd-checkbox{
                position: absolute;
                top: 2px;
                left: 0;
            }
        }
        &--2 {
            width: 100%;
        }

        .bd-btn__wrap {
            width: 100%;
        }
    }
}

.bd-equalcolumn {
    width: 50%;
    flex: 1;
}