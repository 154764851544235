ul {
    &.bd-primary__menu {
        font-size: 15px;

        li {
            padding-bottom: 30px;
            padding-left: 25px;
            position: relative;
            cursor: pointer;
            &.active{
            	color: $client-primary-color;
            	cursor: default;
            }
            i {
                position: absolute;
                left: 0;
                top: 2px;

            }
        }
    }

    &.bd-secondary__menu {
        font-size: 15px;
        padding-top: 20px;

        li {
            padding-bottom: 30px;
            padding-left: 25px;
            cursor: pointer;
            &:last-child{
            	padding-bottom: 0;
            }
            i {
                font-size: 14px;
                top: 4px;
                font-size: 13px;

                &.bludageing {
                    font-size: 22px;
                    top: 1px;
                }

            }
        }

    }
}

.bd-menu {
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.24);
	position: relative;
	z-index: 3;
    .bd-logo {
        width: 100%;
        height: 40px;
    }

}
.bd-adddonar{
	@include flexbox;
	flex-direction: column;
	width: 56px;
	height: 56px;
	justify-content: center;
	align-items: center;
	color: $client-primary-color;
	border-radius: 100%;
	border:1px solid $client-primary-color;
	margin: 30px auto 30px 20px;
	cursor: pointer;
	i{
		font-size: 20px;
		
	}
	span{
		font-size: 10px;
		margin-top: -2px;
	}
}