.bd-inventory {
    padding: 20px 40px;

    &__header {
        font-weight: $bold_font-weight;
        font-size: 24px;
        margin-bottom: 20px;
    }

    &__step {
        @include flexbox;
        margin-bottom: 50px;

        &--remove {
            color: $client-primary-color;
            cursor: pointer;
        }

        &--primary {
             @include flexbox;
             justify-content: space-between;
        }
        &--secondary {
            color: $light-gray;
            @include flexbox;
            justify-content: space-between;
            margin-top: 20px;

        }

        &--row {
            position: relative;
            margin-right: 60px;
            flex: 1;
            @include flexbox;
            justify-content: space-between;
            flex-direction: column;
            // justify-content: center;
            border: 1px solid $light-gray;
            border-radius: 5px;
            padding: 24px;

            &:after {
                content: $bludbig-arrow;
                font-family: $icomoon-font-family;
                position: absolute;
                top: 0;
                right: -60px;
                width: 60px;
                height: 100%;
                @include flexbox;
                justify-content: center;
                align-items: center;
                font-size: 50px;
                color: $light-gray;

            }

            &:last-child {
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

        }

        &--guide {
            color: $light-gray;

            ul {
                font-size: 15px;
                list-style-type: disc;
                padding-left: 50px;

                li {
                    margin-bottom: 10px;

                    .bd-file-upload {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                        font-size: 10px;
                        width: 120px;
                        padding: 8px;
                    }
                }
            }
        }
    }
}

.bd-excel__tbl {
    text-align: center;

    &--container {
        height: 80vh;
        overflow: auto;

    }

    .bd-emailid,
    .bd-address {
        max-width: 150px;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;

        }
    }

    .bd-tbl {
        box-shadow: none;
        border-radius: 0;
        border: 0;
    }

    .bd-tbl__head {
        background-color: transparent;
        color: $light-gray;
    }

    .bd-tbl__cell {
        &:first-child {
            border-left: 0;
        }

        &:last-child {
            border-left: 0;
        }
    }

    .bd-tbl__head {
        .bd-tbl__cell {
            border-top: 0;
        }
    }

    .bd-tbl__body {
        .bd-lab-result {
            color: #0D9823;
        }

        .bd-tbl__row {
            &:last-child {

                .bd-tbl__cell {
                    border-bottom: 0;
                }
            }

        }
    }

}


// Inventory table blood group css

.bd-tbl__addinvent2{
    text-align: center;
    .bd-blood__group--col{
        margin: auto;
    }
}