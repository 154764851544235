.bd-modal-wrap {
    background: rgba(196, 196, 196, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding: 20px;
    z-index: 99;
    overflow: auto;

    .bd-popup {
        padding: 24px;

        &__head {
            border: 0;

            >span {
                &:first-child {
                    font-size: 18px;
                }
            }

        }

        &__head {
            .bludclose {
                font-size: 13px;
                padding: 10px;
                cursor: pointer;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                top: -9px;
                position: relative;



            }
        }

    }

    &.bd-modal-right {
        position: fixed;

        .bd-popup {
            position: fixed;
            right: 0;
            bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .bludclose {
            font-size: 10px;
            line-height: 12px;
        }
    }
}

.bd-popup {
    position: relative;
    max-width: 540px;
    width: 100%;
    min-height: 300px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
    background-color: $white;
    z-index: 2;
    margin: 10% auto 0 auto;

    &__absolute {
        // width: 100%;
        // height: 100%;
        // top: 0;
        // left: 0;
        // position: absolute;
        overflow: hidden;
    }

    &__fix {
        // width: 100%;
        // height: 100%;
        // top: 0;
        // left: 0;
        overflow: hidden;
        // position: fixed;
    }

    &__head {
        @include flexbox;
        justify-content: space-between;
        flex-wrap: wrap;

        >span:first-child {
            font-size: 24px;
            font-weight: $bold_font-weight;
        }

        &--rgt {
            @include flexbox;
            justify-content: space-between;
            align-items: center;
        }

        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        margin-bottom: 5px;
        padding-bottom: 15px;
    }

    &__body {
        background-color: $white;
    }

    &__lightbox {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}


//Accept isuue

.bd-accept-issue {
    .bd-volume {
        width: 100%;
        @include flexbox;
        justify-content: space-between;
        margin-top: 15px;
        span{
            color: $light-gray;
        }
        div {
            &:first-child {
                span {
                    color: $client-primary-color;
                }
            }
        }

        label {
            font-weight: $bold_font-weight;
            margin-right: 5px;
        }

    }

    &__bot {
        margin-top: 20px;
        text-align: left;

        label {
            color: $light-gray;
            margin-bottom: 5px;
            display: block;
        }

        .bd-tbl__theme1 {
            .bd-tbl__cell {
                width: 100px !important;
            }
        }

        .bd-tbl__body {
            .bd-tbl__cell:not(:first-child) {
                font-size: 13px;
                color: $light-gray;
            }
        }
    }

    &__comment {
        color: $light-gray;

        span {
            display: block;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        }
    }

    &__radio {
        margin: 30px 0;
        @include flexbox;
        justify-content: space-around;

        label {
            margin-left: 10px;

        }

        .bd-radio {
            top: 3px;
        }
    }

    .bd-btn__wrap {
        margin: 16px 0;
        text-align: center;
    }

    .bd-tbl__body--scroll {
        height: 200px;
        overflow: auto;
    }

    .bd-btn {
        min-width: 144px;
        margin: 0 20px;
    }

    &__tbl {
        display: table;
        width: 100%;
    }

    .bd-tbl__head,
    .bd-tbl__body {
        display: table;
        width: 100%;
    }

    .bd-tbl__cell {
        .bd-checkbox {
            top: 3px;
        }

        &:nth-child(1) {
            width: 35px;
        }

        &:nth-child(2) {
            width: 115px;

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                ;
                display: block;
            }
        }

        &:nth-child(3) {
            width: 100px;
        }

        &:nth-child(4) {
            width: 90px;
        }

        &:nth-child(5) {
            width: 90px;
        }

        &:nth-child(6) {
            width: 90px;
        }

    }
}

.bd-blood-qauantity {
    @include flexbox;

    label {
        margin-right: 10px;
        padding-top: 4px;
    }
}

.bd-blood-type {
    margin-right: 30px;

    span {
        margin-right: 5px;
        vertical-align: middle;

        &:first-child {
            font-size: 24px;
            color: $client-primary-color;
            font-weight: $bold_font-weight;
        }

        &:last-child {
            font-size: 15px;
        }
    }
}

.bd-twocol {
    @include flexbox;
    justify-content: space-between;

    .bd-dorpdown {
        width: 45%;
    }
}

// Blood Group
.bd-request-blood {


    .bd-popup__body {
        padding-top: 30px;
    }

    .bd-popup {
        max-width: 485px;
    }

}


// profile

.bd-profile {
    &.bd-modal-right {
        .bd-btn__wrap {
            padding: 30px 10% 0 10%;
        }

        .bludclose {
            background-color: $client-primary-color;
            color: $white;


        }


    }

    &__tbl {
        .bd-pin {
            width: 50px;
            text-align: center;

            .bludpin {
                padding: 10px 20px;
                cursor: pointer;
            }
        }

        .bd-tab__content {
            margin-top: 20px;
            height: 255px;
            overflow: auto;
            // .bd-tbl{
            //     box-shadow: none;
            // }
        }

        .bd-graph {
            &__wrap {
                flex: 1;
                position: relative;
            }

            &__head {
                position: absolute;
                left: -135px;
                color: $client-primary-color;
                font-weight: $medium_font-weight;
                top: 40%;
                @include transform(translateY(-50%));

            }

        }

    }

    &.bd-modal-right {
        position: absolute;
        height: 100vh;
        @include flexbox;
        justify-content: flex-end;
        align-items: end;
        overflow: auto;
        padding-top: 30px;
        padding-bottom: 0;

        .bd-popup {
            position: relative;
            min-width: auto;
            max-width: 1200px;
            margin: auto 0 0 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            // align-self: flex-end;

        }
    }

    &__otp {
        margin-top: 30px;
    }

    .bd-popup {
        min-width: 485px;
    }

    &__detail {
        @include flexbox;
        align-items: center;
        margin-bottom: 20px;

        &--tbl {
            margin-bottom: 20px;
            @include flexbox;
        }

    }

    &__img {
        width: 96px;
        height: 96px;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-radius: 100%;
        margin-right: 12px;
    }

    &__group {
        @include flexbox;
        align-items: center;
        font-weight: $medium_font-weight;
        color: $client-primary-color;
        font-size: 40px;

        span {
            display: inline-block;
            vertical-align: middle;

        }

        b {

            font-size: 35px;
        }
    }


}

.bd-tbl__theme2 {
    width: auto;
    box-shadow: none;

    .bd-btn__wrap {

        .bd-btn {
            min-width: 160px;
        }
    }

    .bd-tbl {
        box-shadow: none;
        width: auto;
        margin-top: 15px;
    }

    .bd-tbl__cell {
        border: 0;
        padding: 4px 10px 4px 0;

        &:first-child {
            text-align: right;
            color: $light-gray;

        }

        b {
            font-weight: $medium_font-weight;
        }
    }
}

.bd-donation__form {
    border-top: 1px solid rgba(151, 151, 151, 0.2);
    margin-top: 20px;
    padding-top: 20px;

    .bd-twocol {
        .bd-form__row {
            width: 45%;
        }
    }

}

.bd-select-reason {
    .bd-popup__head {
        padding-bottom: 5px;
    }

    .bd-select-reason__row {
        margin-bottom: 10px;

        .bd-radio {
            vertical-align: middle;
        }

        label {
            margin-left: 5px;
            vertical-align: middle;
        }
    }
}

// success

.bd-addinventory {
    max-width: 311px;
    padding: 0 !important;

    .bd-red__color {
        color: $client-primary-color;
    }
}

.bd-addinvent {
    &__top {
        height: 197px;
        @include flexbox;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white;

        i {
            width: 83px;
            height: 83px;
            border-radius: 100%;
            border: 5px solid $white;
            @include flexbox;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            font-size: 35px;
            font-weight: $medium_font-weight;
        }

        span {
            font-weight: $medium_font-weight;
        }
    }

    &__cont {
        padding: 16px;
        color: #3B4559;
        font-size: 17px;
        line-height: 21px;
        font-weight: $medium_font-weight;
        text-align: center;

        .bd-btn {
            flex: 1;
            min-width: auto;
            margin: 0 10px;
        }



    }

    &__ind {
        font-size: 22px;
        line-height: 27px;
    }
}



// common 
.bd-result-icon {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 6px;
    border-radius: 100%;
    background-color: $green;
    display: block;
    color: $white;
    margin: auto;
    font-style: normal;

    &:before {
        content: $bludaccept;
        font-family: $icomoon-font-family;
    }

    &.bd-red__color {
        background-color: $red;
        color: $white;

        &:before {
            content: $bludclose;
        }
    }
}

.bd-tbl__head--main {
    display: block;
}

.bd-tbl__head--sub {
    display: block;
    font-size: 11px;
}

.bd-addinvent2 {
    &.bd-modal-right {
        position: absolute;
        height: 100vh;
        @include flexbox;
        justify-content: flex-end;
        align-items: end;
        overflow: auto;
        padding-top: 30px;
        padding-bottom: 0;

        .bd-popup {
            position: relative;
            max-width: 485px;
            margin: auto 0 0 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    // .bd-form__row{
    //     div{
    //         margin-bottom: 15px;
    //     }
    // }
    .bd-admoreunit {
        cursor: pointer;
        color: $client-primary-color;
        font-weight: $medium_font-weight;
    }

    input[type="text"] {
        padding: 3px 0;
    }
}


// create camp
.bd-createcamp {
    &.bd-modal-right {
        position: absolute;
        height: 100vh;
        @include flexbox;
        justify-content: flex-end;
        align-items: end;
        overflow: auto;
        padding-top: 30px;
        padding-bottom: 0;

        .bd-popup {
            position: relative;
            max-width: 485px;
            margin: auto 0 0 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    input[type="text"] {
        padding: 3px 0;
    }
}

.bd-addimages {
    overflow: auto;
    @include flexbox;
    white-space: nowrap;
    padding: 5px;

    .bd-add {
        width: 160px;
        height: 160px;
        border-radius: 8px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        @include flexbox;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        cursor: pointer;
        flex-shrink: 0;
        flex-direction: column;
        position: relative;

        span {
            margin-top: 5px;
        }

        &:first-child {
            margin-left: 0;
        }

        .bludclose {
            position: absolute;
            right: 5px;
            top: 5px;
            padding: 5px;
            background-color: $client-primary-color;
            border-radius: 100%;
            color: $white;
            text-align: center;
            font-size: 9px;
            line-height: 13px;
            width: 22px;
            height: 21px;
            cursor: pointer;
            display: none;
        }

        &.active {
            cursor: default;

            .bludclose {
                display: block;
            }

            .bludplus {
                display: none;
            }
        }
    }
}

// lab result popup
.bd-lbresult {
    .bd-popup__head {
        .bd-form__row {
            @include flexbox;
            white-space: nowrap;

            input {
                width: 100px;
                font-weight: $medium_font-weight;
                font-size: 18px;
            }
        }
    }

    .bd-popup__body {
        >.bd-form:first-child {
            border-bottom: 1px solid $client-primary-color;
            padding-bottom: 5px;
            margin-bottom: 5px;
        }
    }

    .bd-tbl__cell {
        text-align: center;

        input[type="text"] {
            width: 100px;
            border: 0;


        }

        &:first-child {
            text-align: left;
        }

    }

    .bd-form {
        &__row {
            @include flexbox;

            input {
                padding: 0 5px 5px 5px;
            }

            .bd-calender:after {
                top: 1px;
            }

            &--1 {
                div {
                    @include flexbox;
                    margin-left: 15px;
                    white-space: nowrap;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:nth-child(2) {
                        width: 150px;
                    }

                    &:nth-child(3) {
                        width: 100px;
                    }
                }
            }

            &--2 {
                >div:first-child {
                    @include flexbox;
                    white-space: nowrap;
                    margin-right: 15px;
                }

                .bd-dorpdown {
                    flex: 1;
                }
            }

            &--3 {
                div {
                    @include flexbox;
                    margin-left: 15px;
                    white-space: nowrap;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:nth-child(2) {
                        width: 150px;
                    }

                    &:nth-child(3) {
                        width: 100px;
                    }
                }
            }

            &--4 {
                div {
                    @include flexbox;
                    margin-left: 15px;
                    white-space: nowrap;

                    &:first-child {
                        margin-left: 0;
                        flex: 1;
                    }

                }

                .bd-calender {
                    width: 150px;
                }
            }
        }
    }

    &.bd-modal-right {
        position: absolute;
        height: 100vh;
        @include flexbox;
        justify-content: flex-end;
        align-items: end;
        overflow: auto;
        padding-top: 30px;
        padding-bottom: 0;

        .bd-popup {
            position: relative;
            min-width: auto;
            margin: auto 0 0 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .bd-print__result {
        @include flexbox;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;

        &--btn {
            margin: 0 5%;
            cursor: pointer;

            span {
                font-weight: $bold_font-weight;
                margin-right: 10px;
            }
        }

        .bd-dorpdown__selected {

            font-weight: $medium_font-weight;
        }

        .bd-dorpdown {
            margin: 0 5%;

            &__option {
                width: 172px;
                // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
                // border: 0;
            }

            &__list {
                // padding: 5px 0;
            }

            .bd-red__color {
                color: $red;
            }

            .bd-green__color {
                color: $green;
            }
        }
    }

    .bd-comment {
        padding-top: 10px;

        input {
            padding: 5px 0;
            border: 0;
            border-bottom: 1px solid $gray;
            width: 100%;
        }
    }
}

.bd-labresults {
    .bd-tbl__cell {
        &:last-child {
            text-align: center;
        }
    }

    .bd-remark {
        border: 0;
        width: 130px !important;

    }

    .bd-txtby {
        margin-right: 15px;

        span {
            margin-right: 5px;
        }

        &__wrp {
            @include flexbox;

            div {
                @include flexbox;
                white-space: nowrap;
            }

            input {
                padding: 0 0 5px 0;
            }

            .bd-calender:after {
                top: 2px;
            }
        }

    }

    &.bd-modal-right {
        position: absolute;
        height: 100vh;
        @include flexbox;
        justify-content: flex-end;
        align-items: end;
        overflow: auto;
        padding-top: 30px;
        padding-bottom: 0;

        .bd-popup {
            position: relative;
            min-width: auto;
            max-width: 1200px;
            margin: auto 0 0 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.bd-self-assessment {

    &.bd-modal-right {
        position: absolute;
        height: 100vh;
        @include flexbox;
        justify-content: flex-end;
        align-items: end;
        overflow: auto;
        padding-top: 30px;
        padding-bottom: 0;

        .bd-popup {
            position: relative;
            min-width: auto;
            max-width: 1200px;
            margin: auto 0 0 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            // align-self: flex-end;
            padding: 50px;

        }
    }
}

ul.bd-assessment {
    &__list {
        list-style-type: decimal;
        margin-left: 15px;
        font-size: 15px;

        span {
            display: inline-block;

            &:first-child {
                width: 60%;
            }
        }

        li {
            margin-bottom: 15px;
            position: relative;

            .bd-answer {
                margin-right: 0;
                margin-left: auto;
                padding-right: 15px;
                font-weight: $bold_font-weight;
                width: 39%;
            }
        }

        &--sub {
            list-style-type: lower-alpha;
            margin-left: 15px;
            margin-top: 15px;
            font-size: 15px;
        }
    }
}

.bd-assessment {
    &__top {
        @include flexbox;
        align-items: center;
        margin-bottom: 20px;

        &--lft {
            @include flexbox;

            flex-direction: column;

            div {
                @include flexbox;
                margin-bottom: 10px;
                font-weight: $medium_font-weight;

                span {
                    &:first-child {
                        color: $light-gray;
                        margin-right: 10px;
                    }
                }

            }

            flex: 1;
        }

        &--mid {
            font-size: 40px;
            font-weight: $bold_font-weight;
            color: $client-primary-color;
            width: 100px;

            b {
                margin-left: 2px;
                font-size: 30px;
                vertical-align: top;
            }
        }

        &--rgt {
            font-size: 40px;
            flex: 2;
            color: #3B4559;
            text-align: right;
        }
    }

    &__mid {
        height: calc(100vh - 200px);
        overflow: auto;
    }

    &__bot {
        text-align: center;

        .bd-btn__wrap {
            justify-content: space-evenly;
        }

        .bd-confirm__input {
            padding: 15px 0;

            .bd-checkbox {
                top: 3px;
                margin-right: 10px;
            }
        }
    }


}

// search feature
.bd-searchfeature {

    .bd-popup,
    .bd-popup__body,
    input {
        background-color: #FFFACA;
    }

    &__detail {
        padding-bottom: 15px;
    }

    .bd-form__row {
        flex-wrap: wrap;
    }

    .bd-form__row--hd {
        width: 100%;
    }

    .bd-btn {
        min-width: 130px;
        margin: 0 10px;

        &__wrap {
            justify-content: center;
        }

        i {
            margin-right: 5px;
            font-size: 9px;
        }
    }

}

// bd-requirement
.bd-requirement {
    .bd-popup {
        padding: 24px 50px;
    }

    .bd-popup__head {
        >span:first-child {
            width: 100%;
            text-align: center;
            padding-left: 30px;
        }
    }

    &__hd {
        padding-bottom: 30px;
        font-weight: $bold_font-weight;
        text-align: center;
    }

    p {
        margin-bottom: 15px
    }

    &__bot {
        margin: 70px 0 10px 0;

        span {
            display: block;
        }
    }

    .bd-btn {
        min-width: 100px;

        &__wrap {
            justify-content: space-between;
        }
    }
}

.bd-accept-issue {
    &.bd-modal-right {
        position: absolute;
        height: 100vh;
        @include flexbox;
        justify-content: flex-end;
        align-items: end;
        overflow: auto;
        padding-top: 30px;
        padding-bottom: 0;

        .bd-popup {
            position: relative;
            min-width: auto;
            margin: auto 0 0 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .bd-tbl__body--scroll {
            height: 350px;
        }

        .bd-form {
            padding-top: 15px;
        }

        .bd-form__row {
            div {
                @include flexbox;
                align-items: flex-end;

                input {
                    padding: 3px;
                    margin-left: 10px;
                }
            }
        }
    }

    .bd-components {
        width: 70% !important;
    }
}

// bd-components_drp
.bd-components {
    &__drp {
        @include flexbox;
        align-items: center;
        padding-left: 20px;

        label {
            color: $light-gray;
        }

        .bd-dorpdown {
            padding-left: 15px;
            color: #3B4559;

            &__selected i {
                color: #3B4559;
            }

            &__option {
                width: 325px;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
                border: 0;
                padding: 10px;
            }

            &__list {
                padding: 8px;
                border: 0;

                &.active {
                    color: $client-primary-color;
                    cursor: default;
                }
            }
        }


    }
}