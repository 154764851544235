.bd-profile {
    .bd-dashboard__header {
        .bludedit {
            padding: 10px;
            font-size: 22px;
            cursor: pointer;
        }
    }

    &__address {
        @include flexbox;
        flex-wrap: wrap;
        max-width: 50%;
        padding-top: 20px;
    }

    &__add {
        flex: 1;

        padding-right: 10%;

        &--hd {
            font-weight: $bold_font-weight;
            font-size: 18px;
            padding-bottom: 15px;
        }

        &--id {
            padding-top: 20px;
        }

        &--mob {
            padding-top: 5px;
        }

        &--tm {
            padding-top: 5px;
        }

        &--beds {
            padding-top: 5px;
        }
    }

    &__sign {
        width: 150px;

        &--hd {
            font-weight: $medium_font-weight;
            font-size: 16px;
        }

    }

    &__review {
        width: 100%;
        color: $client-primary-color;
        padding-top: 15px;
        cursor: pointer;
    }

    &__reviews{
    	// width: 100%;
    	margin-top: 20px;
    }
}

// profile form 
.bd-profile {
    .bd-addimages--hd {
        font-weight: $medium_font-weight;
        font-size: 16px;
        margin-bottom: 5px;
    }

    &__form {
        @include flexbox;
        margin-top: 20px;

        input[type="text"] {
            padding: 5px 0;
        }

        .bd-addmore {
            font-weight: $medium_font-weight;
            color: $client-primary-color;
            text-align: right;
            margin-top: 10px;
            cursor: pointer;
        }

        &--lft {
            width: 70%;
            padding-right: 30px;
            border-right: 1px solid $gray;
        }

        &--rgt {
            width: 400px;
            padding-left: 30px;

        }

        .bd-contact {
            div:not(class) {
                margin-bottom: 20px;
            }

            input {
                padding-left: 40px;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 30px auto;
            }
        }

        .bd-timing {

            div {
                position: relative;
            }

            i {
                position: absolute;
                right: 5px;
                bottom: 5px;
                padding: 5px;
                font-size: 9px;
                color: $light-gray;
                cursor: pointer;
            }

            input {
                padding-right: 30px;
            }
        }
    }

    .bd-form__security {
        margin-top: 20px;

        .bd-form__hd {
            font-weight: $medium_font-weight;
            font-size: 16px;
            margin-bottom: 15px;
        }
    }
}

.bd-authorised-sign {
    .bd-form__hd {

        margin-bottom: 15px;

        b {
            font-weight: $medium_font-weight;
            font-size: 16px;
        }

        span { color: $light-gray; }
    }
    .bd-replace{
    	color: $client-primary-color;
    	font-weight: $medium_font-weight;
    	margin-top: 15px;
    	cursor: pointer;

    }
    .bd-pdf{
    	&__img{
    		display: inline-block;
    		width: 20px;
    		height: 20px;
    		background: url(../images/pdf.png) no-repeat;
    		background-size: cover;
    		margin-right: 5px;
    	}
    	&__wrap{
    		@include flexbox;
    		align-items: center;
    		height: 40px;
    		border-radius: 5px;
    		background-color: #F9F9F9;
    		position: relative;
    		padding: 5px;
    		width: 120px;
    		i{
    			font-size: 8px;
    			padding: 8px;
    			position: absolute;
    			right: 0;
    			top:0;
    			cursor: pointer;
    			color: $light-gray;
    		}
    		div{
    			span{
    				display: block;
    				line-height: 12px;
    				&:first-child{
    					font-weight: $medium_font-weight;
    					margin-bottom: 5px;
    					font-size: 12px
    				}
    				&:last-child{
    					font-size: 10px;
    					color: $light-gray;
    				}
    			}
    		}
    	}
    }
}

// Review
.bd-reviews{
	&__hd{
		font-weight: $medium_font-weight;
		font-size: 18px;
		margin-bottom: 15px;
	}
	&__row{
		@include flexbox;
		width: 100%;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid $gray;
		&--lft{

			span{
				display: inline-block;
			width: 48px;
			height: 48px;
			border-radius: 100%;
			margin-right: 15px;
			background-size: cover;
			background-repeat: no-repeat;
			}
		}
		&--rgt{
			flex: 1;
			@include flexbox;
			flex-direction: column;

		}
	}
	&__top{
		@include flexbox;
		align-items: center;
	}
	&__bot{
		@include flexbox;
		padding-top: 5px;

	}
	&__comment{
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 20px;
	}
	&__readmore{
		color: $client-primary-color;
		font-weight: $medium_font-weight;
		cursor: pointer;
	}
	&__name{
		flex: 1;
	}
	&__star{
		margin-right: 15px;
		line-height: 8px;
		span{
			width: 16px;
			height: 16px;
			position: relative;
			display: inline-block;
			margin-left: 3px;
			line-height: 16px;
			cursor: pointer;
			&:before{
				content: $bludstar;
				font-family: $icomoon-font-family;
				position: absolute;
				left: 0;
				top:0;
			}
			&.active{
				&:before{
					content: $bludstart-fill;
					color: $yellow;
				}
				
			}
		}
	}
	&__date{
		color: $light-gray;
	}
}