.bd-hader{
    height: 72px;
    background-color: $white;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.24);
    z-index: 1;
    position: relative;
    @include flexbox;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px;
    .bd-add{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: $white; 
        background-color: $client-primary-color; 
            text-align: center;
    line-height: 33px;
    cursor: pointer;
    }
    .bd-notification{
        padding: 10px;
        margin:0 10px;
        i{
            font-size: 21px;
            color: $light-gray;
        }
    }
    .bd-search{
        padding: 10px;
        margin:0 10px;
        @include flexbox;
        justify-content: flex-end;
        align-items: center;
        &.active{
            input{
                width: 300px;
                visibility: visible;
                opacity: 1;
            }
        }
        input{
            visibility: hidden;
            width: 0;
            height: 25px;
            padding: 5px 40px 5px 5px;
            margin-right: -30px;
            opacity:0;
            @include transition(all, 0.3s, ease-in-out);

        }
    }
    .bd-acimg{
        width: 24px;
        height: 24px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 100%;
        margin:0 10px;
        position: relative;
        &__ac{
            position: absolute;
            width: 150px;
            background-color: $white;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            top: 35px;
            right: 0;
            padding: 16px;
            visibility: hidden;
            
            div{
                @include flexbox;
                align-items: center;
                cursor: pointer;
            }
            &--profile{
                padding-bottom: 16px;
            }
            &--logout{
                
            }
            i{
                margin-right: 5px;
            }
        }
        &.bd-active{
            .bd-acimg__ac{
                visibility: visible;
            }
        }
    }
}