$cache: 1;


@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$cache}');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$cache}') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$cache}') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$cache}') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$cache}##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="blud"], [class*=" blud"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bludissued {
  &:before {
    content: $bludissued; 
  }
}
.bluddonation {
  &:before {
    content: $bluddonation; 
  }
}

.bludstart-fill {
  &:before {
    content: $bludstart-fill; 
  }
}
.bludstar {
  &:before {
    content: $bludstar; 
  }
}

.bluddots__menu {
  &:before {
    content: $bluddots__menu; 
  }
}

.bludrefresh {
  &:before {
    content: $bludrefresh; 
  }
}

.bludquestionmark {
  &:before {
    content: $bludquestionmark; 
  }
}
.bludpin {
  &:before {
    content: $bludpin; 
  }
}
.bludfilter {
  &:before {
    content: $bludfilter; 
  }
}

.bludprint {
  &:before {
    content: $bludprint; 
  }
}
.bludcalender {
  &:before {
    content: $bludcalender; 
  }
}

.bludsort-arrow {
  &:before {
    content: $bludsort-arrow;     

  }
}
.bludpower-off {
  &:before {
    content: $bludpower-off; 
  }
}
.bluduser {
  &:before {
    content: $bluduser; 
  }
}
.bluddelete {
  &:before {
    content: $bluddelete; 
  }
}
.bludlabresults {
  &:before {
    content: $bludlabresults;     

  }
}
.bludedit {
  &:before {
    content: $bludedit; 
  }
}
.bludclose {
  &:before {
    content: $bludclose; 
  }
}
.bludcamps {
  &:before {
    content: $bludcamps; 
  }
}
.bludageing {
  &:before {
    content: $bludageing; 
  }
}
.bludtransfer {
  &:before {
    content: $bludtransfer; 
  }
}
.bluddonar {
  &:before {
    content: $bluddonar; 
  }
}
.bludaccept {
  &:before {
    content: $bludaccept; 
  }
}
.bludshare {
  &:before {
    content: $bludshare; 
  }
}
.bludplus {
  &:before {
    content: $bludplus; 
  }
}
.bluddownload {
  &:before {
    content: $bluddownload; 
  }
}
.bludbig-arrow {
  &:before {
    content: $bludbig-arrow; 
  }
}
.bludnotification {
  &:before {
    content: $bludnotification; 
  }
}
.bludsearch {
  &:before {
    content: $bludsearch; 
  }
}
.bludtickmark {
  &:before {
    content: $bludtickmark; 
  }
}
.bludsetting {
  &:before {
    content: $bludsetting; 
  }
}
.bludchat {
  &:before {
    content: $bludchat; 
  }
}
.bludblood-bag {
  &:before {
    content: $bludblood-bag; 
  }
}
.bludpeople {
  &:before {
    content: $bludpeople; 
  }
}
.bluddashboard {
  &:before {
    content: $bluddashboard; 
  }
}
.bludclose-eye {
  &:before {
    content: $bludclose-eye; 
  }
}
.bluddot {
  &:before {
    content: $bluddot; 
  }
}
.bludeye {
  &:before {
    content: $bludeye; 
  }
}

