.bd-adddonor{
    &-row{
        input[type=text]{
            padding: 10px 0;
            width: 100%;
        }
        margin-bottom: 20px;
        @include flexbox;
    }
    &__3{
        justify-content: flex-start;
        input{
            &:first-child{
                max-width: 150px;
                width: auto;
                margin-right: 50px;
            }
            &:nth-child(2){
                max-width: 150px;
                width: auto;
                margin-right: 50px;
            }
            &:nth-child(3){
                max-width: 50px;
             
                margin-right: 10%;
            }
        }

    }
    &__4{
        justify-content: flex-start;
        input{
            &:first-child{
                max-width: 150px;
                width: auto;
                margin-right: 50px;
            }
            &:nth-child(2){
                max-width: 150px;
                width: auto;
               margin-right: 50px;
            }
            &:nth-child(3){
                max-width: 80px;
                margin-right: 10%;
            }
        }

    }
    &__5{
        input{
            &:first-child{
                width: 60%;
                margin-right: 20%;
            }
            &:nth-child(2){
                width: 40%;

            }
        }
    }
    &__save{
        width: 100%;
        .bd-btn{
            min-width: 160px;
            margin: 0 5%;
            &__wrap{
                justify-content: center;
            }
            
        }
    }
}