.bd-chat {
    background-color: $white;

    &__container {
        @include flexbox;
    }

    &__head {
        padding: 24px;
        font-weight: $bold_font-weight;
        font-size: 24px;


    }


    &__side {
        width: 360px;
        height: calc(100vh - 70px);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);



    }

    &__main {
        flex: 1;
        height: calc(100vh - 80px);
        overflow: hidden;

    }
}

.bd-side {
    &__container {
        height: calc(100vh - 140px);
        overflow: auto;
    }

    &__row {
        @include flexbox;
        justify-content: space-between;
        align-items: center;
        padding: 10px 24px;
        position: relative;

        &:first-child {
            &:after {
                display: none;
            }
        }

        &:after {
            content: "";
            position: absolute;
            height: 1px;
            background-color: $gray;
            bottom: 0;
            left: 15px;
            right: 15px;
        }

        &:not(.bd-active) {

            .bd-user__short,
            .bd-user__tm {
                color: $light-gray;
            }
        }

        &--rgt {
            margin-left: 10px;
            @include flexbox;
            flex-direction: column;
            align-items: center;

            .bd-user__total {

                background-color: $client-primary-color;
                margin-top: 10px;
                border-radius: 100%;
                width: 20px;
                height: 20px;
                color: $white;
                font-size: 11px;
                text-align: center;
                line-height: 20px;
            }
        }

        &--cont {
            @include flexbox;
            align-items: center;
            flex: 1;
            overflow: hidden;

            div {
                overflow: hidden;

                span {
                    display: block;
                }
            }

            .bd-user__short {
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;


            }
        }

        &.bd-active {
            background-color: rgba($client-primary-color, 0.5);
        }
    }
}


.bd-user {
    &__img {
        display: inline-block;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: middle;
        flex-shrink: 0;
    }

    &__name {
        font-weight: $medium_font-weight;
        margin-left: 10px;
    }

    &__conversation {
        padding: 8px 16px;
        color: $white;
        position: relative;
        .bd__conversation{
            &:after{
                content:"";
                display: inline-block;
                width: 50px;
                height: 20px;
                float: right;
            }
        }
        .bd-user__time{
    position: absolute;
    right: 10px;
    bottom: 10px;
        }
        span{
            display: block;
        }
        &.bd-disabled {
            background-color: rgba($client-primary-color, 0.5) !important;
        }

        &.bd-user__me {
            background-color: $client-primary-color;
            border-radius: 6px 0px 6px 6px;
            margin-right: 0;
            max-width: 80%;
            margin-top: 6px;
            margin-bottom: 6px;
            margin-right: 10px;
            margin-left: auto;
            

            &.bd-disabled {
                margin-right: 50px;

            }

            i {
                position: absolute;
                top: 50%;
                @include transform(translateY(-50%));

                right: -40px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                text-align: center;
                line-height: 29px;
                color: $client-primary-color;
            }

        }

        &.bd-user__inverse {
            background-color: #3B4559;
            border-radius: 0px 6px 6px 6px;
            max-width: 80%;
            margin-top: 9px;
            margin-bottom: 9px;
            margin-right: auto;
            margin-left: 0;


        }
    }


    &__day {
        text-align: center;
        padding: 5px 0;
    }

    &__head {
        height: 60px;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.24);
        padding: 0 40px;
        @include flexbox;
        align-items: center;

        &--rgt {
            margin-right: 0;
            margin-left: auto;

            i {
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                display: inline-block;
                cursor: pointer;
            }
        }

    }

    &__chat {
        padding: 0 30px 40px 40px;
        height: calc(100vh - 132px);
        @include flexbox;
        flex-direction: column;

        &--container {
            @include flexbox;
            flex-direction: column;
            flex: 1;
            width: 100%;
            overflow: auto;
        }

        &--type {
            margin-bottom: 0;
            margin-top: 10px;
            padding-right: 60px;
            position: relative;

            input {
                height: 40px;
                width: 100%;
                padding: 10px 15px;
                border: 1px solid $gray;
                border-radius: 5px;
            }

            i {
                position: absolute;
                right: 0;
                width: 40px;
                height: 40px;
                border: 1px solid $gray;
                text-align: center;
                border-radius: 5px;
                padding-top: 10px;
                cursor: pointer;
            }
        }
    }

    &__status {
        height: 30px;
        position: relative;
        padding: 5px 0;
        text-align: center;

        span {
            display: inline-block;
            background-color: $white;
            z-index: 1;
            position: relative;
            padding: 2px 10px;
            top: -1px;
        }

        &:before {
            content: "";
            height: 1px;
            background-color: $light-gray;
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
        }
    }
}